import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './Dropzone.scss';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { APP_DEFAULTS } from '../../constants';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#000000';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${props => getColor(props)};
  border-style: solid;
  transition: border .24s ease-in-out;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Dropzone({ onAddImage, title, t }) {
  const errorMessages = {
    'file-too-large': t("dropzoneLargerThan", {
      value: Math.floor(APP_DEFAULTS.MAX_IMAGESIZE / 1024),
    }),
    'file-invalid-type': t("dropzoneInvalidType"),
  };
  const [image, setImage] = useState('');
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result
        // console.log('file loaded')
      };
      reader.readAsArrayBuffer(file);
      setImage(URL.createObjectURL(file));
      onAddImage(file);
    });

  }, [onAddImage]);


  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({
      onDrop,
      accept: 'image/jpeg, image/png',
      multiple: false,
      maxSize: APP_DEFAULTS.MAX_IMAGESIZE * 1024, // 1MB KB
    });



  return (
    <div className="dropzone" >
      {image.length === 0 && <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>{title}</p>

        {fileRejections[0] && fileRejections[0].errors.map(error => {
          let message = `${error.message} ( ${error.code} )`;
          if (errorMessages[error.code]) {
            message = errorMessages[error.code];
          }
          return <p className="error">
            {'Fehler: ' + message}
          </p>;
        })}

      </Container>}
      {image.length > 0 && <div className="image "> <div className="image__container">       
        <img alt="" className="tmp-image" src={image} />
        </div></div>}
    </div>
  );
}
Dropzone.propTypes = {
  onAddImage:PropTypes.func,
  title:PropTypes.string,
};

export default withTranslation()(Dropzone);