import React from 'react';
import { AntTag } from './styled';
import PropTypes from 'prop-types';

function Tag (props) {
  const { children } = props;

  return (
    <AntTag {...props}>
      { children }
    </AntTag>
  );
}


export default Tag;