import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { v4 as uuid } from 'uuid';
import { Button, Input, InputUnit } from '../../../../components';
import './styles.scss';
import { icons } from '../../../../resources';
import { useCallback, useEffect, useState } from "react";
import { isCorrectAnswerGiven, isQuestionValid } from '../../../../helper/quiz';
import { withTranslation } from 'react-i18next';

export default withTranslation()(function Multiple({
  slide,
  onUpdateSlide,
  t,
}) {

  const updateAnswer = (id, key, value) => {
  	const updatedSlide = { ...slide };
    const answerIndex = updatedSlide.answers.findIndex(answer => answer.id === id);
	
	  updatedSlide.answers[answerIndex][key] = value;

    onUpdateSlide({
      ...updatedSlide,
    });
  };

  const deleteAnswer = id => {
    onUpdateSlide({
      ...slide,
      answers: slide.answers.filter(i => i.id !== id)
    });
  };

  return (
    <>
      <Row>
        {slide.answers.map((answer, ind) => {
          const even = ind % 2 === 1;
          return <Col
            key={answer.id}
            span={11}
            offset={even && 2}
            className={`c-quiz-item c-quiz-item__answer ${(!answer?.title?.length || !isCorrectAnswerGiven(slide)) && 'has-error'}`}
			
          >
            <div>
              <img
                className='c-quiz-item-icon c-quiz-item-icon__close'
                src={icons.iconCloseBlack} alt=""
                onClick={() => deleteAnswer(answer.id)}
              />
              {/* <CloseCircleOutlined
                className='c-quiz-item-icon c-quiz-item-icon__close'
                style={{ color: '#ff0000' }}
                onClick={() => deleteAnswer(answer.id)}
              /> */}
              {answer.right
                ? <CheckCircleFilled
                  className='c-quiz-item-icon c-quiz-item-icon__selected multiple'
                  onClick={() => updateAnswer(answer.id, 'right', !answer.right)}
                />
                : <span
                  className={`c-quiz-item-icon multiple c-quiz-item-icon__unselected ${!isCorrectAnswerGiven(slide) && 'has-error'}`}
                  onClick={() => updateAnswer(answer.id, 'right', !answer.right)}
                />
              }
            </div>
            <Input
              className={`c-quiz-item-input`}
              placeholder={`${t("createQuizAnswer")} ${ind + 1}*`}
              value={answer.title}
              onChange={e => updateAnswer(answer.id, 'title', e.target.value)}
              maxLength={50}
            />
          </Col>;
        })}
      </Row>
      {slide.answers.length < 6 && <Row justify={'center'} style={{ marginTop: '44px' }}>
        <Col>
          <Button
            className='c-createQuiz-sider-buttons-item'
            onClick={() => onUpdateSlide({
              ...slide,
              answers: [
                ...slide.answers,
                { id: uuid(), title: '' }
              ]
            })}
          >
            {t("createQuizAddAnotherAnswer")} {slide.answers.length ? `(max.${6 - slide.answers.length})` : ''}
          </Button>
        </Col>
      </Row>}
    </>
  );
});
