import React, { useState, useRef } from 'react';
import './Image.scss';
import noImage from '../../resources/images/no_image.png';
import PropTypes from "prop-types";
import { Copyright } from './Copyright';

function Image (props) {
  const { image, size, noFallback } = props;
  let hasImage = image && image?.[size] && image[size].length > 0;

  const [ratio, setRatio] = useState('');
  const targetRef = useRef();

  const onImageLoad = () => {
    if (targetRef.current) {
      setRatio(targetRef.current.offsetWidth > targetRef.current.offsetHeight ? 'landscape' : 'portrait');
    }
  };

  return <div className={`image-container ${ratio}`} ref={targetRef}>
    {hasImage && <img alt="example" src={image?.[size]} onLoad={onImageLoad}/>}
    {(hasImage==false && noFallback==false) && <img src={noImage} alt=""/>}
    {hasImage && <Copyright image={image} />}
  </div>;
}

Image.propTypes = {
  image:PropTypes.object,
  size:PropTypes.number,
  noFallback:PropTypes.bool,
};
export default Image;