import React, { useEffect, useState } from 'react';
import { IMAGE_AVAILABLE_SIZES } from '../../../../constants';
import { withTranslation } from 'react-i18next';
import Image  from '../../../../components/Image';
import party from "party-js";
import { PointCup } from '../../../../assets/svg';

export default withTranslation()(function PreFinalResult ({quiz, t}) {
  
  const [confettiEmitterRef, setConfettiEmitter] = useState(null);
  
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (confettiEmitterRef) {
        party.confetti(confettiEmitterRef, {
          count: party.variation.range(120, 150),
          size: party.variation.range(1, 1.5),
        });
      }
    }, 1000);
    
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [confettiEmitterRef]);
  
  return (
    <div className='quiz quiz_without-answers'>

      <div className='quiz__content'>
        <div className='quiz__title'>{t("quizMadeWellDone")} </div>

        <div className='quiz__description'>{t("quizLookAtResult")}</div>
        
        <div className="point-cup-container">
          <div className="confetti-emitter" ref={(ref) => setConfettiEmitter(ref)}/>
          <PointCup />
        </div>

        {quiz?.image &&
        <div className='quiz__image'>
          <Image image={quiz.image} size={IMAGE_AVAILABLE_SIZES.PRENSENTATION} noFallback></Image>
        </div>}
      </div>
    </div>
  );
});
