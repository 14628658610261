import { Modal as AntModal, } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { t } from "i18next";
import PropTypes from "prop-types";
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from '../../../../components';
import { isQuestionValid, isQuizMetaValid } from '../../../../helper/quiz';
import { iconCloseWhite } from '../../../../resources/icons';
import './Sidebar.scss';
const { confirm } = AntModal;

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


Sidebar.propTypes = {
    quiz: PropTypes.object,
    slides: PropTypes.array,
    finishBuild: PropTypes.func,
    handleOpenPreview: PropTypes.func,
    openModal: PropTypes.func,
    builderStep: PropTypes.number,
    deleteSlide: PropTypes.func,
    handleSelectSlide: PropTypes.func,
    setSlides: PropTypes.func,
    saveQuiz: PropTypes.func,
};

export default function Sidebar(props) {
    const {
        slides,
        quiz,
        openModal,
        handleOpenPreview,
        finishBuild,
        builderStep,
        deleteSlide,
        handleSelectSlide,
    } = props;

    const onDragEnd = result => {
        const { setSlides, slides, saveQuiz } = props;
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const newSlides = reorder(slides, source.index, destination.index);
        setSlides(newSlides);
        saveQuiz({});
    };

    return <Sider theme='light' className='c-createQuiz-sider'>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className='c-createQuiz-sider-slides'
                    >
                        {slides.map((slide, ind) => (
                            <SidebarSlide
                                slide={slide}
                                ind={ind}
                                slides={slides}
                                builderStep={builderStep}
                                deleteSlide={deleteSlide}
                                handleSelectSlide={handleSelectSlide}
                                key={slide.id + ind}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        <div className='c-createQuiz-sider-buttons'>
            <Button className='c-createQuiz-sider-buttons-item' onClick={openModal}>{t("createQuizAddQuestion")}</Button>
            <Button className='c-createQuiz-sider-buttons-item' onClick={handleOpenPreview}>{t("createQuizPreview")}</Button>
            <Button className={`c-createQuiz-sider-buttons-item ${!isQuizMetaValid(quiz) && 'has-error'}`} onClick={finishBuild}>{t("createQuizReady")}</Button>
        </div>
    </Sider>;
}


SidebarSlide.propTypes = {
    builderStep: PropTypes.number,
    deleteSlide: PropTypes.func,
    handleSelectSlide: PropTypes.func,
    ind: PropTypes.number,
    slide: PropTypes.object,
    slides: PropTypes.array
};

function SidebarSlide(props) {
    const {
        slide,
        ind,
        slides,
        builderStep,
        deleteSlide,
        handleSelectSlide
    } = props;
    const activeSlide = slides[builderStep - 1];
    const hasErrors = !isQuestionValid(slide);

    const showRemoveQuestionConfirm = (slideId) => {
        const onOk = () => deleteSlide(slideId);
        confirm({
            title: t("createQuizDeleteConfirmation"),
            icon: false,
            cancelText: t("createQuizDeleteCancel"),
            okText: t("createQuizDeleteAccept"),
            onOk
        });
    };


    return <Draggable key={slide.id} draggableId={slide.id} index={ind}>
        {(provided) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`c-createQuiz-sider-slides-item ${hasErrors && 'has-error'} ${(activeSlide && slide.id === activeSlide.id) && 'c-createQuiz-sider-slides-item__active'}`}
                key={slide.id}
                onClick={() => handleSelectSlide(slide.id)}
            >
                <img
                    className='c-createQuiz-sider-slides-item-icon'
                    src={iconCloseWhite} alt=""
                    onClick={() => showRemoveQuestionConfirm(slide.id)} />
                {/* </Popconfirm>} */}
                <div className='c-createQuiz-sider-slides-item-title'>{ind + 1}. {slide.title}</div>
            </div>
        )}
    </Draggable>;
}

