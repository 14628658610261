import React, { Component } from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import history from './history';
import { ProtectedRoute, PublicRoute } from './routes/Auth';
import { EditorRoute, AdminRoute, MobileRoute, PresentationRoute } from './routes';
import { initUser } from './store/actions/user';
import { initConfig } from './store/actions/config';
import {
  Home,
  CreateQuiz,
  NotFound,
  Discover,
  MyQuizzes,
  Tags,
  Categories,
  Users,
  WelcomeQuiz,
  MobileQuiz,
  Quiz,
  InviteCode,
  DeletedUser,
  MobileCode
} from './pages';

import { connect } from "react-redux";
import { ROUTES } from './constants';
import  PropTypes  from "prop-types";
import ScrollToTop from './components/ScrollToTop';
import { SpinnerOverlay } from './components/SpinnerOverlay';


class App extends Component {

  static propTypes = {
    initUser:PropTypes.func,
    initConfig:PropTypes.func,
  }
  componentDidMount() {
    this.props.initUser();
    this.props.initConfig();
  }
  render() {
    return (
      <>
        <Router history={history}>
          <React.Fragment>
            <ScrollToTop />
          <Switch>

            <PublicRoute path='/login' component={Home} exact />
            <PublicRoute path='/invitecode' component={InviteCode} exact />
            <PublicRoute path={ROUTES.DELETED_USER} component={DeletedUser} exact />

            <ProtectedRoute path={ROUTES.EDITOR.DEFAULT} wrapper={EditorRoute} component={() => <Redirect
    to={ROUTES.EDITOR.DISCOVER}/>} exact />
            <ProtectedRoute path={ROUTES.EDITOR.DISCOVER} wrapper={EditorRoute} component={Discover} exact />
            <ProtectedRoute path={ROUTES.EDITOR.MY_QUIZZES} wrapper={EditorRoute} component={MyQuizzes} exact />
            <ProtectedRoute path={ROUTES.EDITOR.CREATE_QUIZ} wrapper={EditorRoute} component={CreateQuiz} exact />
            <ProtectedRoute path={ROUTES.EDITOR.EDIT_QUIZ} wrapper={EditorRoute} component={CreateQuiz} exact />

            <ProtectedRoute admin wrapper={AdminRoute} path={ROUTES.ADMIN.DEFAULT} exact />
            <ProtectedRoute admin wrapper={AdminRoute} path={ROUTES.ADMIN.TAGS} component={Tags} exact />
            <ProtectedRoute admin wrapper={AdminRoute} path={ROUTES.ADMIN.CATEGORIES} component={Categories} exact />
            <ProtectedRoute admin wrapper={AdminRoute} path={ROUTES.ADMIN.USERS} component={Users} exact />

            <MobileRoute path={ROUTES.QUIZ.MOBILE} component={MobileCode} exact />
            <MobileRoute path={ROUTES.QUIZ.MOBILE_LINK_REGEX} component={MobileQuiz} exact />

            <ProtectedRoute wrapper={PresentationRoute} path={ROUTES.QUIZ.WELCOME} component={WelcomeQuiz} exact />
            <ProtectedRoute wrapper={PresentationRoute} path={ROUTES.QUIZ.PLAY} component={Quiz} exact />

            <Route path='*' component={NotFound} />

          </Switch>
          <SpinnerOverlay/>
          </React.Fragment>
        </Router>
      </>
    );
  }
}
const mapDispatchToProps = {
  initUser,
  initConfig
};
export default connect(null, mapDispatchToProps)(App);
