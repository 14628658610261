import React, { Component, useEffect } from 'react';
import { Form, Table, Popconfirm, Button, Modal, Input, Checkbox, InputNumber, Select, Row, Col } from "antd";
import * as dayjs from 'dayjs';

import './Users.scss';
import PropTypes from "prop-types";
const { Option } = Select;

export const InviteCodeModal = (props) => {
  const [form] = Form.useForm();
  let { isInviteCodeModalVisible, onCreateInviteCodes, onCancel, t } = props;

  return (
    <Modal
      title={t("usersGenerateCode")}
      visible={isInviteCodeModalVisible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      cancelText={t("generalCancel")}
      okText={t("generalOk")}
    >
      <Form form={form} onFinish={onCreateInviteCodes} name="control-hooks">
        <Form.Item
          name="count"
          label={t("usersQuantity")}
          initialValue="20"
        >
          <InputNumber min={1} max={100} />
        </Form.Item>
        <Form.Item
          name="daysValid"
          label={t("usersValidity")}
          initialValue="30"
        >
          <Select>
            <Option value="30">30</Option>
            <Option value="60">60</Option>
            <Option value="90">90</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

InviteCodeModal.propTypes = {
  isInviteCodeModalVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreateInviteCodes: PropTypes.func,
  t: PropTypes.func,
};
