import PropTypes from "prop-types";
import React from 'react';
import { isQuizValid } from '../../../../helper/quiz';
import { Col, Row } from 'antd';
import { Button, Input, Switch } from '../../../../components';
import { ROUTES } from '../../../../constants';
import { t } from "i18next";
import ImageDropzone from '../../../../components/Image/ImageDropzone';
import { fetchTags } from "../../../../store/actions/tag";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchFolders } from "../../../../store/actions/folder";
import { fetchCategories } from "../../../../store/actions/category";
import confirm from 'antd/lib/modal/confirm';


const levels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

export default function MetaData(props) {
    const { removeQuizImage, updateImage, storeQuizImage, onUpdateQuiz, onSubmitQuiz } = props;

    let dispatch = useDispatch();
    let { tags, categories, folders, quiz, slides } = useSelector(state => state);
    let [search, setSearch] = React.useState('');
    let [mounted, setMounted] = React.useState(false);

    useEffect(() => {
        setMounted(true);
        dispatch(fetchTags());
        dispatch(fetchFolders());
        dispatch(fetchCategories());
    }, [mounted]);


    const totalSeconds = slides.reduce((acc, slide) => acc + (+slide.seconds || 0), 0);
    const totalPoints = slides.reduce((acc, slide) => acc + (+slide.points || 0), 0);
    const globalTags = tags.map(tag => tag.title);
    const totalTags = [
        ...tags,
        ...quiz.tags
            .filter(tag => !globalTags.includes(tag))
            .map(tag => ({ title: tag }))
    ];

    const handleChange = (key, value) => {
        onUpdateQuiz({ [key]: value });
    };

    const handleChangeTag = tag => {
        const { tags } = quiz;

        if (tags.includes(tag.title)) {
            onUpdateQuiz({ tags: tags.filter(i => i !== tag.title) });
        } else {
            onUpdateQuiz({ tags: [...tags, tag.title] });
        }
    };

    const handleChangeFolder = folderId => {
        const { folderIds } = quiz;

        if (folderIds.includes(folderId)) {
            onUpdateQuiz({ folderIds: folderIds.filter(i => i !== folderId) });
        } else {
            onUpdateQuiz({ folderIds: [...folderIds, folderId] });
        }
    };

    const onCategoryPress = (categoryId) => {
        let newCategoryIds = [];
        const { categoryIds } = quiz;

        const categoryIdIndex = Array.isArray(categoryIds) ? categoryIds.indexOf(categoryId) : -1;
        if (categoryIdIndex > -1) {
            newCategoryIds = [...categoryIds.slice(0, categoryIds.length - 1)];
        } else {
            newCategoryIds = [...categoryIds, categoryId];
        }

        handleChange('categoryIds', newCategoryIds);
    };

    const handleChangeSearch = term => {
        setSearch(term);

        dispatch(fetchTags({ title:term }));
    };

    const showSaveQuizConfirm = (needToCreateSession = false, redirect = null) => {
        if (!isQuizValid({ ...quiz, questions: slides })) {
          const onOk = () => onSubmitQuiz(needToCreateSession, redirect);
          confirm({
            title: t("createQuizCaution"),
            content: t("createQuizYourQuizIncomplete"),
            cancelText: t("createQuizCheckQuiz"),
            okText: t("createQuizSaveAnyway"),
            icon: false,
            onOk
          });
        } else {
          onSubmitQuiz(needToCreateSession, redirect);
        }
      };

    return <>
        <Row>
            <div className='error-label'>
                {!isQuizValid({ ...quiz, questions: slides }) && t("createQuizIncomplete2")}
            </div>
            <Col span={24} className='c-createQuiz-content-item c-createQuiz-content-item__title'>
                {t("createQuizLastStep")}
            </Col>
            <Col span={8}>
                <Input
                    className={`c-createQuiz-content-input ${(!quiz?.title?.length || quiz?.title === t("createQuizUntitled")) && 'has-error'}`}
                    placeholder={t("createQuizAddTitle")}
                    style={{ fontSize: '18px' }}
                    value={quiz.title}
                    onChange={e => handleChange('title', e.target.value)} />
            </Col>
            <Col span={10} offset={6} className='c-createQuiz-content-item c-createQuiz-content-item__total'>
                <div>{t("createQuizTotalDuration", {
                    value: Math.round(totalSeconds / 60)
                })}</div>
                <div>{t("createQuizTotalPoints", {
                    value: totalPoints
                })}</div>
            </Col>
            <Col span={12} className='c-createQuiz-content-item c-createQuiz-content-item__image'>
                <ImageDropzone
                    image={quiz.image}
                    removeImage={() => removeQuizImage()}
                    storeImage={storeQuizImage}
                    title={t("createQuizAddThumbnail")}
                    updateImage={updateImage}
                />
                {/* <div className='c-createQuiz-content-item-title'>Vorschaubild hinzufügen</div> */}
            </Col>
            <Col span={24} className={`c-createQuiz-content-item c-createQuiz-content-item__subtitle ${!quiz.categoryIds?.length && 'has-error'}`}>
                {t("createQuizCategoryBelongsTo")}
            </Col>
        </Row>
        <Row>
            {categories.map(category => <Col
                className={`c-createQuiz-content-item c-createQuiz-content-item__tag
                        ${quiz.categoryIds?.indexOf(category._id) > -1 ? 'c-createQuiz-content-item__tag__active' : ''}  ${!quiz.categoryIds?.length && 'has-error'}`}
                key={category._id}
                onClick={() => onCategoryPress(category._id)}
            >
                <div className={`c-createQuiz-content-item-title `}>{category.title}</div>
            </Col>
            )}
        </Row>
        <Row>
            <Col span={24} className='c-createQuiz-content-item c-createQuiz-content-item__subtitle'>
                {t("createQuizTopicsCover")}
            </Col>
        </Row>
        <Row>
            {totalTags.map(tag => <Col
                className={`c-createQuiz-content-item c-createQuiz-content-item__tag
                        ${quiz.tags.includes(tag.title) ? 'c-createQuiz-content-item__tag__active' : ''}`}
                key={tag._id}
                onClick={() => handleChangeTag(tag)}
            >
                <div className='c-createQuiz-content-item-title'>{tag.title}</div>
            </Col>
            )}
        </Row>
        <Row>
            <Col style={{ marginTop: '20px' }}>
                <Input
                    placeholder={t("generalSearch")}
                    className='c-createQuiz-content-input'
                    onChange={e => handleChangeSearch(e.target.value)}
                    value={search} />
            </Col>
        </Row>

        <Row>
            <Col span={24} className='c-createQuiz-content-item c-createQuiz-content-item__subtitle'>
                {t("createQuizSaveInFile")}
            </Col>
            {!folders.length && <Col
                span={24}
                className='c-createQuiz-content-item c-createQuiz-content-item__secondSubtitle'
            >
                {t("createQuizNoFolders")}
            </Col>}
        </Row>
        <Row>
            {folders.map(folder => <Col
                className={`c-createQuiz-content-item c-createQuiz-content-item__tag
                        ${quiz.folderIds.includes(folder._id) ? 'c-createQuiz-content-item__tag__active' : ''}`}
                key={folder._id}
                onClick={() => handleChangeFolder(folder._id)}
            >
                <div className='c-createQuiz-content-item-title'>{folder.title}</div>
            </Col>
            )}
        </Row>
        <Row>
            <Col span={24} className='c-createQuiz-content-item c-createQuiz-content-item__subtitle'>
                {t("createQuizEmpfholene")}
            </Col>
        </Row>
        <Row>
            {levels.map(level => <Col
                key={level}
                className={`c-createQuiz-content-item c-createQuiz-content-item__tag
                        ${level === quiz.level ? 'c-createQuiz-content-item__tag__active' : ''}`}
                onClick={() => handleChange('level', level)}
            >
                <div className='c-createQuiz-content-item-title'>{level}</div>
            </Col>
            )}
        </Row>
        <Row>
            <Col span={24} className='c-createQuiz-content-item c-createQuiz-content-item__subtitle'>
                {t("createQuizMakePublic")}
                <Switch
                    style={{ marginLeft: '20px' }}
                    disabled={!isQuizValid({ ...quiz, questions: slides })}
                    onChange={value => {
                        handleChange('isPublic', value);
                    }}
                    checked={quiz.isPublic} />
                <div className='error-label'>
                    {!isQuizValid({ ...quiz, questions: slides }) && t("createQuizCannotMakePublic")}
                </div>
            </Col>
            <Col className='c-createQuiz-content-buttons'>
                <Button
                    className='c-createQuiz-sider-buttons-item'
                    onClick={() => showSaveQuizConfirm(false, ROUTES.EDITOR.MY_QUIZZES)}
                >{t("generalSave")}</Button>
                <Button
                    className='c-createQuiz-sider-buttons-item'
                    onClick={() => showSaveQuizConfirm(true)}
                >{t("createQuizSaveAndPlay")}</Button>
            </Col>
        </Row>
    </>;
}

MetaData.propTypes = {
  onSubmitQuiz: PropTypes.func,
  onUpdateQuiz: PropTypes.func,
  removeQuizImage: PropTypes.func,
  storeQuizImage: PropTypes.any,
  updateImage: PropTypes.any
};

