import {
  ADD_SLIDE,
  UPDATE_SLIDE,
  DELETE_SLIDE,
  UPDATE_QUIZ,
  CREATE_QUIZ,
  CLEAN_BUILDER,
  FETCH_QUIZ_FOR_BUILDER,
  SAVE_QUIZ,
  SET_BUILDER_STEP,
  SET_BUILDER_TYPE,
  SET_SLIDES,
  STORE_QUIZ_IMAGE,
  STORE_SLIDE_IMAGE,
  REMOVE_SLIDE_IMAGE,
  REMOVE_QUIZ_IMAGE
} from '../types';

export function addSlide(payload) {
  return {
    type: ADD_SLIDE,
    payload,
  };
}

export function updateSlide(payload) {
  return {
    type: UPDATE_SLIDE,
    payload,
  };
}

export function storeSlideImage(payload) {
  return {
    type: STORE_SLIDE_IMAGE,
    payload,
  };
}

export function storeQuizImage(payload) {
  return {
    type: STORE_QUIZ_IMAGE,
    payload,
  };
}
export function removeSlideImage(payload) {
  return {
    type: REMOVE_SLIDE_IMAGE,
    payload
  };
}
export function removeQuizImage() {
  return {
    type: REMOVE_QUIZ_IMAGE
  };
}
export function deleteSlide(payload) {
  return {
    type: DELETE_SLIDE,
    payload,
  };
}

export function setSlides(payload) {
  return {
    type: SET_SLIDES,
    payload,
  };
}

export function updateQuiz(payload) {
  return {
    type: UPDATE_QUIZ,
    payload
  };
}

export function createQuiz(payload) {
  return {
    type: CREATE_QUIZ,
    payload
  };
}

export function saveQuiz(payload) {
  return {
    type: SAVE_QUIZ,
    payload
  };
}

export function cleanBuilder() {
  return {
    type: CLEAN_BUILDER
  };
}

export function fetchQuizForBuilder(payload) {
  return {
    type: FETCH_QUIZ_FOR_BUILDER,
    payload,
  };
}

export function setBuilderType(payload) {
  return {
    type: SET_BUILDER_TYPE,
    payload
  };
}

export function setBuilderStep(payload) {
  return {
    type: SET_BUILDER_STEP,
    payload
  };
}
