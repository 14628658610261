import React from "react";
import  PropTypes  from "prop-types";

export function Cup() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210"
      height="210"
      viewBox="0 0 210 210"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#A0C814"
          strokeWidth="8"
          d="M188.608 19c4.748 0 9.013 2.256 12.113 5.807C204.005 28.567 206 33.77 206 39.5c0 5.729-1.995 10.933-5.279 14.693-3.1 3.55-7.365 5.807-12.113 5.807h0-7.604V19zM21.392 19c-4.748 0-9.013 2.256-12.113 5.807C5.995 28.567 4 33.77 4 39.5c0 5.729 1.995 10.933 5.279 14.693 3.1 3.55 7.365 5.807 12.113 5.807h7.604V19z"
        />
        <path
          fill="#A0C814"
          d="M85 94.39c5.249-12.467 34.707-12.573 40 0-6.667 16.271-6.667 48.475 0 96.61H85c6.667-48.135 6.667-80.339 0-96.61z"
        />
        <path
          fill="#A0C814"
          d="M71 209.923h68a3 3 0 003-3v-14a3 3 0 00-3-3H71a3 3 0 00-3 3v14a3 3 0 003 3z"
        />
        <path
          fill="#A0C814"
          stroke="#A0C814"
          strokeWidth="10"
          d="M181 5v59.5c0 11.736-4.757 22.361-12.448 30.052C160.862 102.243 150.236 107 138.5 107h0-67c-11.736 0-22.361-4.757-30.052-12.448C33.757 86.862 29 76.236 29 64.5h0V5h152z"
        />
      </g>
    </svg>
  );
}

export function Timer({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210"
      height="210"
      viewBox="0 0 210 210"
      {...rest}
    >
      <g fill="none" fillRule="evenodd" stroke="#A0C814">
        <rect
          width="202"
          height="202"
          x="4"
          y="4"
          strokeWidth="8"
          rx="101"
        />
        <path
          strokeLinecap="square"
          strokeWidth="6"
          d="M105 191v15M191 105h15"
        />
        <path
          strokeLinecap="square"
          strokeWidth="4"
          d="M148.5 183.67l5 8.66M183.67 148.5l8.66 5M17.67 56.5l8.66 5M17.67 153.5l8.66-5M56.5 192.33l5-8.66M148.5 27.33l5-8.66M182.67 61.5l8.66-5M56.5 17.67l5 8.66"
        />
        <path
          strokeLinecap="square"
          strokeWidth="6"
          d="M4 105h15M105 4v15"
        />
      </g>
    </svg>
  );
}

export function CloseMark({ size = 'default' }) {
  return (
    <>
      {size === 'default'
        ? <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5.9vh"
          height="5.9vh"
          viewBox="0 0 45 45"
        >
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M45 6.43L38.571 0 22.5 16.071 6.429 0 0 6.43l16.071 16.071L0 38.572 6.429 45 22.5 28.929 38.571 45 45 38.572 28.929 22.501z"
          />
        </svg>
        : <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11vh"
          height="11vh"
          viewBox="0 0 84 84"
        >
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M84 12.002L71.999 0 42 30 12.002 0 0 12.002l29.998 30L0 72.002 12.002 84 42 54l29.999 30L84 72.002l-29.998-30z"
          />
        </svg>
      }
    </>
  );
}
CloseMark.propTypes = {
  size: PropTypes.string,
};

export function CheckMark({ size = 'default' }) {
  return (
    <>
      {size === 'default'
        ? <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5.9vh"
          height="5.64vh"
          viewBox="0 0 45 43"
        >
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M22.261 43L45 4.386 37.58 0 19.125 31.016 4.284 22.243 0 29.841z"
          />
        </svg>
        : <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.52vh"
          height="11vh"
          viewBox="0 0 88 84"
        >
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M43.414 84L87.761 8.569 73.29 0 37.298 60.59 8.355 43.452 0 58.294z"
          />
        </svg>
      }
    </>
  );
}
CheckMark.propTypes = {
  size: PropTypes.string,
};
export function Figure() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.76vh"
      height="9.7vh"
      viewBox="0 0 44 74"
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="4"
        transform="translate(2 2)"
      >
        <path
          strokeLinejoin="round"
          d="M14.444 23h12.223L40 70H0l14.444-47z"
        />
        <circle cx="20" cy="15" r="15" />
      </g>
    </svg>
  );
}

export function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="144"
      viewBox="0 0 144 144"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="72" cy="72" r="72" fill="#FFF" />
        <path
          fill="#A0C814"
          fillRule="nonzero"
          d="M71.479 119L121 33.691 104.84 24 64.65 92.524 32.33 73.142 23 89.927z"
        />
      </g>
    </svg>
  );
}

export function FailureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="144"
      viewBox="0 0 144 144"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="72" cy="72" r="72" fill="#FFF" />
        <path
          fill="#EB6400"
          fillRule="nonzero"
          d="M119 38.431L105.57 25 72 58.571 38.43 25 25 38.431l33.57 33.571L25 105.573 38.43 119 72 85.429 105.57 119 119 105.573 85.43 72.002z"
        />
      </g>
    </svg>
  );
}

export function Crown({ color = '#a0c814', top = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.25vh"
      height="5.64vh"
      viewBox="0 0 63 42"
    >
      <g fill={color} fillRule="evenodd">
        <path d="M4.246 10.584l10.758 8.567c.73.58 1.763.58 2.492 0L30.254 8.992c.73-.58 1.763-.58 2.492 0l12.758 10.159c.73.58 1.763.58 2.492 0l10.758-8.567A1.999 1.999 0 0162 12.149V40a2 2 0 01-2 2H3a2 2 0 01-2-2V12.15a2 2 0 013.246-1.566z" />
        {top &&
          <>
            <circle cx="31.5" cy="2.5" r="2.5" />
            <circle cx="2.5" cy="3.5" r="2.5" />
            <circle cx="60.5" cy="3.5" r="2.5" />
          </>}
      </g>
    </svg>
  );
}
Crown.propTypes = {
  color: PropTypes.string,
  top:PropTypes.bool,
};

export function PlaceholderImage(props) {
  const colors = [
    "#82055F",
    "#5AC8F5",
    "#a0c814"
  ];
  const getIndex = ()=>{
    return props.number % 3;
  };
  
  return (
    <svg
    width="408"
    height="229"
    viewBox="0 0 408 229"
    xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill={colors[getIndex()]} d="M0 0h408v229H0z" />
        <g fill="#FFF" fillRule="nonzero">
          <path d="M181.973 102.837c8.972 0 16.275-7.336 16.275-16.419 0-9.05-7.272-16.418-16.275-16.418-9.004 0-16.276 7.336-16.276 16.418 0 9.083 7.272 16.419 16.276 16.419zm0-29.185c6.989 0 12.655 5.716 12.655 12.766s-5.666 12.767-12.655 12.767c-6.989 0-12.656-5.716-12.656-12.767 0-7.05 5.667-12.766 12.656-12.766zM141.174 115.032c0-6.923 5.572-12.544 12.466-12.544v-3.589c-8.846 0-16.024 7.209-16.024 16.133s7.178 16.164 16.024 16.164 15.992-7.24 15.992-16.164h-3.526c0 6.923-5.603 12.576-12.466 12.576-6.894-.032-12.466-5.653-12.466-12.576z" />
          <path d="M130.501 115.032c0 12.893 10.358 23.31 23.108 23.31 12.78 0 23.138-10.449 23.138-23.31h-3.526c0 10.892-8.783 19.753-19.612 19.753-10.798 0-19.582-8.829-19.582-19.753 0-10.893 8.752-19.721 19.582-19.721v-3.59c-12.75-.03-23.108 10.417-23.108 23.31z" />
          <path d="M123.324 115.032c0 16.863 13.568 30.55 30.285 30.55 16.716 0 30.253-13.687 30.253-30.55h-3.558c0 14.862-11.962 26.898-26.695 26.898-14.734 0-26.665-12.036-26.665-26.898 0-14.863 11.931-26.899 26.665-26.899v-3.62c-16.717-.032-30.285 13.624-30.285 30.519z" />
          <path d="M153.609 77.209c-20.683 0-37.494 16.926-37.494 37.791 0 20.896 16.779 37.823 37.494 37.823 20.682 0 37.462-16.927 37.462-37.791h-3.558c0 18.864-15.173 34.17-33.873 34.17-18.7 0-33.874-15.306-33.874-34.17 0-18.864 15.174-34.171 33.874-34.171v-3.652h-.031z" />
          <path d="M153.64 156.443c-22.666 0-41.051-18.546-41.051-41.411 0-22.865 18.385-41.412 41.051-41.412v-3.588C128.99 70 109 90.166 109 115c0 24.866 19.99 45 44.609 45 24.65 0 44.608-20.134 44.608-45h-3.526c.032 22.897-18.385 41.443-41.051 41.443z" />
          <g>
            <path d="M219.503 109.632c1.795 0 3.967-.418 5.163-.964v-9.86h-6.076v3.115h2.424v4.433c-.409.064-1.07.096-1.605.096-2.708 0-4.785-1.445-4.785-6.103 0-4.593 2.109-6.038 5.257-6.038 1.322 0 3.054.289 4.124.738l.378-3.115c-1.228-.514-3.211-.803-4.722-.803-5.415 0-8.91 2.89-8.91 9.282.032 6.2 3.243 9.219 8.752 9.219zM280.828 109.279h3.746V91.356h-3.746v7.066h-5.635v-7.066h-3.778v17.923h3.778v-7.645h5.635zM265.465 109.279V94.568h3.81v-3.212h-11.428v3.212h3.81v14.71zM248.97 101.634h6.201v-3.148h-6.202v-3.918h7.304v-3.212h-11.05v17.923h11.302v-3.212h-7.556zM234.866 109.793c4.974 0 7.398-2.73 7.398-9.508 0-6.617-2.393-9.475-7.398-9.475-4.974 0-7.398 2.858-7.398 9.475 0 6.649 2.518 9.508 7.398 9.508zm0-15.771c2.392 0 3.526 1.541 3.526 6.295 0 4.722-1.134 6.328-3.526 6.328-2.393 0-3.557-1.574-3.557-6.328.031-4.754 1.196-6.295 3.557-6.295zM299.622 106.067h-7.524v-4.433h6.202v-3.148h-6.202v-3.918h7.304v-3.212H288.32v17.923h11.301zM244.688 118.53h3.494v14.164h3.684V118.53h3.494v-3.115h-10.672zM212.042 115.414h3.715v17.28h-3.715zM283.976 126.688c0 1.991-.66 3.115-2.518 3.115-1.826 0-2.519-1.124-2.519-3.115v-11.274h-3.683v11.402c0 4.144 2.078 6.167 6.202 6.167 4.06 0 6.17-2.023 6.17-6.167v-11.402h-3.652v11.274zM237.605 120.039c0-.9.44-1.767 2.298-1.767 1.165 0 2.172.321 3.21.771l.41-3.02c-1.165-.545-2.392-.866-3.935-.866-3.904 0-5.604 2.312-5.604 5.235 0 5.557 6.737 4.497 6.737 7.58 0 1.189-.724 1.992-2.266 1.992-1.511 0-2.802-.514-4.061-1.253l-.473 3.084c1.323.77 2.928 1.22 4.691 1.22 3.652 0 5.73-2.087 5.73-5.46 0-5.75-6.737-4.85-6.737-7.516zM228.035 125.5l-5.226-10.086h-3.369v17.28h3.369v-10.086l5.226 10.086h3.368v-17.28h-3.368zM289.36 115.414v3.115h3.462v14.165h3.684V118.53H300v-3.115zM257.249 115.414h3.715v17.28h-3.715zM262.852 118.53h3.495v14.164h3.683V118.53h3.463v-3.115h-10.64z" />
          </g>
        </g>
      </g>
    </svg>

  );
}

export function PointCup() {
  return (
    <svg width="299" height="299" viewBox="0 0 299 299" xmlns="http://www.w3.org/2000/svg">
      <path d="M260.829 0a4 4 0 0 1 4 4v17.357h3.712C285.363 21.357 299 36.975 299 56.24c0 19.073-13.365 34.571-29.955 34.88l-.504.004-3.713-.001v8.344c0 33.137-26.862 60-60 60l-32.625-.002c-2.93 25.549-1.076 62.531 5.562 110.947l22.416.001a8 8 0 0 1 7.996 7.751l.004.25v12.475a8 8 0 0 1-7.75 7.997l-.25.003h-99.362a8 8 0 0 1-7.996-7.75l-.004-.25v-12.476a8 8 0 0 1 7.75-7.996l.25-.004h20.415l.072-.515c6.58-48.164 8.41-84.975 5.49-110.433l-32.625.002c-33.137 0-60-26.863-60-60v-8.344h-3.707l-.504-.004C13.37 90.811.005 75.313.005 56.24c0-19.265 13.637-34.883 30.459-34.883h3.707V4a4 4 0 0 1 4-4H260.83zm7.712 33.357h-3.713v45.766h3.713c9.805 0 18.459-9.91 18.459-22.883 0-12.841-8.48-22.682-18.162-22.88l-.297-.003zm-234.37 0h-3.707l-.297.003c-9.682.198-18.162 10.039-18.162 22.88 0 12.973 8.654 22.884 18.459 22.884l3.707-.001V33.357z" fill="#A0C814" fillRule="nonzero"/>
    </svg>
  );
}

export function PointCupWhite() {
  return (
    <svg width="210" height="210" viewBox="0 0 210 210" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M188.608 19c4.748 0 9.013 2.256 12.113 5.807C204.005 28.567 206 33.77 206 39.5c0 5.729-1.995 10.933-5.279 14.693-3.1 3.55-7.365 5.807-12.113 5.807h-7.604V19zM21.392 19c-4.748 0-9.013 2.256-12.113 5.807C5.995 28.567 4 33.77 4 39.5c0 5.729 1.995 10.933 5.279 14.693 3.1 3.55 7.365 5.807 12.113 5.807h7.604V19z" stroke="#FFF" strokeWidth="8" fill="#A0C814"/>
        <path d="M85 94.39c5.249-12.467 34.707-12.573 40 0-6.667 16.271-6.667 48.475 0 96.61H85c6.667-48.135 6.667-80.339 0-96.61z" fill="#FFF"/>
        <path d="M71 209.922h68a3 3 0 0 0 3-3v-14a3 3 0 0 0-3-3H71a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3z" fill="#FFF"/>
        <path d="M181 5v59.5c0 11.736-4.757 22.361-12.448 30.052C160.862 102.243 150.236 107 138.5 107h-67c-11.736 0-22.361-4.757-30.052-12.448C33.757 86.862 29 76.236 29 64.5V5h152z" stroke="#FFF" strokeWidth="10" fill="#FFF"/>
      </g>
    </svg>
  );
}
