import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { unfavoriteQuiz, favoriteQuiz, copyQuiz } from '../../store/actions/quiz';
import {  createQuizSession } from '../../store/actions/quiz_session';
import Card from './Card';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unfavoriteQuiz,
      favoriteQuiz,
      copyQuiz,
      createQuizSession,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Card);
