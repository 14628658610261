import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { Form } from 'antd';
import { Button } from '../../../components';

import './Home.scss';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constants';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const authBase = `${baseUrl}/auth`;
export default function Home(props) {
  let user = useSelector(state => state.user);
  let history = useHistory();
  useEffect(() => {
    // console.log("user is", user);
    if(user?._id){
      history.push(ROUTES.EDITOR.DEFAULT);
    }
  }, [user]);
  const login = () => {
    window.location = authBase + '/casauth?login=1';
  };
  const register = () => {
    window.location = authBase + '/casauth?register=1';
  };
  const { t } = props;

  return (
    <div className='c-home'>
      <Form.Item>
        <Button block onClick={() => login()}>{t("homeLogin")}</Button>
      </Form.Item>
      <Form.Item>
        <Button block onClick={() => register()}>{t("homeRegister")}</Button>
      </Form.Item>
    </div>
  );
}

Home.propTypes = {
  t: PropTypes.func
};
