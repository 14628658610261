import { notification } from 'antd';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import AuthService from '../../services/auth';
import UserService from '../../services/user';
import * as types from '../types';
import { waitFor } from './general';
import * as dayjs from 'dayjs';
import history from '../../history';
import { ROUTES } from '../../constants';


function* initUser(action) {
  yield call(initToken);
  yield call(initAuthenticatedUser);
}

function* initAuthenticatedUser() {
  let { token } = yield select((state) => state);

  if (token) {
    const authService = yield call(() => new AuthService(token));
    let user = yield call([authService, authService.getAuthenticatedUser]);

    if (user) {
      yield put({
        type: types.SET_USER,
        payload: user
      });
    }
  }
  yield put({
    type: types.SET_LOGING_IN,
    payload: false
  });
}

function* initToken() {
  const authService = yield call(() => new AuthService());
  let tokenResponse = null;
  try {
    tokenResponse = yield call([authService, authService.getToken]);
  } catch (error) {
    console.error('error fetching token: ', error);
  }
  if (tokenResponse) {
    yield put({
      type: types.SET_TOKEN,
      payload: tokenResponse.access_token
    });
  }
}


function* logout(action) {
  try {
    let { user } = yield select((state) => state);
    yield put({
      type: types.SET_USER,
      payload: null
    });

    if (user) {
      const authService = yield call(() => new AuthService());
      yield call([authService, authService.logout]);
    }
  } catch (error) {
    console.error('login error', error);
  }
}

function* fetchUsers(action) {
  yield call(waitFor, state => state.token != null);
  const { token } = yield select((state) => state);
  const userService = yield call(() => new UserService(token));
  try {
    const users = yield call([
      userService,
      userService.getUsers,
    ],action.payload);

    yield put({
      type: types.SET_USERS,
      payload: users.result,
    });

  } catch (error) {
    notification.error({
      message: 'Fehler beim Laden der Benutzer',
      description: error.message,
      maxCount: 1
    });
    console.error('fetchUsers error', error);
  }

}

function* updateUser(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    const result = yield call([
      userService,
      userService.updateUser,
    ], action.payload);
    if (result?.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield call(fetchUsers, {});
    }
  } catch (error) {
    notification.error({message:error.message});
    console.error('updateUser error', error);
  }
}

function* deleteUser(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    yield call([
      userService,
      userService.deleteUser,
    ], action.payload._id);
    // console.log(result);
    notification.success({ message: `Nutzer ${action.payload.username} wurde gelöscht.`, duration: 60000 });

    yield put({
      type: types.FETCH_USERS,
    });
  } catch (error) {
    notification.error({message:error.message});
    console.error('deleteUser error', error);
  }
}

function* createInviteCodes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    const result = yield call([
      userService,
      userService.createInviteCodes,
    ], action.payload);
    // console.log("createInviteCodes", result);
    if (result?.statusCode === 400) {
      throw new Error(result.message);
    } else {
      let csvContent = 'code;gültig bis\r\n';
      result.forEach((inviteCode) => {
        let row = `${inviteCode.code};${dayjs(inviteCode.validUntil).format('DD.MM.YYYY')}`;
        csvContent += row + "\r\n";
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement('a');
      link.setAttribute('download', 'invite_codes.csv');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }

  } catch (error) {
    notification.error({message:error.message});
    console.error('create Invite Code error', error);
  }
}

function* checkInviteCode(action){
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    yield call([
      userService,
      userService.checkInviteCode,
    ], action.payload);
    // console.log(result);
    yield call(initAuthenticatedUser);
    history.push(ROUTES.EDITOR.DEFAULT);
    notification.success({ message: `Einladungscode akzeptiert!`, duration: 60000 });
  } catch (error) {
    
    notification.error({message:error.message});
    console.error('check Invite Code error', error, error.code, error.message);
  }
}

function* recoverUser(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    const user = yield call([
      userService,
      userService.recoverUser,
    ], action.payload.userId);

    if (user) {
      yield put({
        type: types.SET_USER,
        payload: user
      });
    }

    action.payload.cb(user);

  } catch (error) {
    notification.error({ message: error.message });
    console.error('recoverUser error', error);
  }
}

export function* userSagas() {
  yield takeEvery(types.INIT_USER, initUser);
  yield takeEvery(types.LOGOUT, logout);
  yield takeEvery(types.FETCH_USERS, fetchUsers);
  yield takeEvery(types.UPDATE_USER, updateUser);
  yield takeEvery(types.DELETE_USER, deleteUser);
  yield takeEvery(types.CREATE_INVITE_CODES, createInviteCodes);
  yield takeEvery(types.CHECK_INVITE_CODE, checkInviteCode);
  yield takeEvery(types.RECOVER_USER, recoverUser);
}

export default userSagas;