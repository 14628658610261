import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import WelcomeQuiz from './WelcomeQuiz';
import { initPresentation } from '../../../store/actions/quiz_presentation';
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initPresentation,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    quizSession: state.quizSession,
    participants: state.participants,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(WelcomeQuiz));
