import React from 'react';
import PropTypes from 'prop-types';
import { Confetti } from './Confetti';
import { withTranslation } from "react-i18next";

function MobileFinalResult({ t, totalParticipantResult, showStats }) {
  return (
    <div className='mobLogin'>
      <div className='mobLogin__content'>
        <div className='mobLogin__input'>
          {t("mobileQuizMade")}<br />
          {t("mobileQuizWellDone")}<br /><br />
          {showStats && <>
            <Confetti points={totalParticipantResult?.totalPoints || "0"} />
            <div style={{ fontSize: '0.65em', lineHeight: '1.5em' }}>{t("mobileQuizGotPoints", {
              value: totalParticipantResult?.totalPoints || "0",
            })}</div>
          </>}
        </div>
      </div>
    </div>
  );
}

MobileFinalResult.propTypes = {
  t: PropTypes.func,
  totalParticipantResult: PropTypes.object,
  showStats: PropTypes.bool,
};

export default withTranslation()(MobileFinalResult);
