const colors = [
  '#a0c814',
  '#eb6400',
  '#5ac8f5',
  '#82055f',
  '#788287',
  '#003969'
];

const ROUTES = {
  LOGIN:'/login',
  DELETED_USER:'/deleteduser',
  EDITOR:{
    DEFAULT:'/quizzes',
    DISCOVER:'/quizzes/discover',
    MY_QUIZZES:'/quizzes/my_quizzes',
    EDIT_QUIZ:'/quizzes/edit/:id',
    EDIT_QUIZ_BASE:'/quizzes/edit/',
    CREATE_QUIZ:'/quizzes/create',
  },
  ADMIN:{
    DEFAULT:'/admin',
    TAGS:'/admin/tags',
    CATEGORIES:'/admin/categories',
    USERS:'/admin/users',
  },
  QUIZ:{
    MOBILE:'/',
    MOBILE_LINK_REGEX:'/:code([0-9]{1,10}?)',
    MOBILE_LINK:'/:code',
    WELCOME:'/quiz/:code/welcome',
    PLAY:'/quiz/:code/play',
  },
};

const IMAGE_SIZES={
  'orig':{maxHeight:0,maxWidth:0},
  480:{maxHeight:320,maxWidth:480},
  640:{maxHeight:480,maxWidth:640},
  1280:{maxHeight:960,maxWidth:1280},
};
const IMAGE_AVAILABLE_SIZES = {
  ORIG:'orig',
  THUMBNAIL:480,
  EDITOR:640,
  PRENSENTATION:1280,
};

const APP_DEFAULTS = {
  MAX_IMAGESIZE : 4096, // in kb
};


const QUIZ_SESSION_STATES = {
  QUESTION:'QUESTION',
  QUESTION_REVIEW:'QUESTION_REVIEW',
  QUESTION_RESULTS:'QUESTION_RESULTS',
  QUIZ_FINISHED:'QUIZ_FINISHED',
  FINAL_RESULTS:'FINAL_RESULTS',
  FINISHED_QUESTION_REVIEW:'FINISHED_QUESTION_REVIEW',
  FINISHED_QUESTION_RESULTS:'FINISHED_QUESTION_RESULTS',
};

const MOBILE_VIEW = {
  CODE: 'CODE',
  NAME: 'NAME',
  WAITING: 'WAITING',
  QUESTION: QUIZ_SESSION_STATES.QUESTION,
  QUESTION_RESULTS: QUIZ_SESSION_STATES.QUESTION_RESULTS,
  QUIZ_FINISHED: QUIZ_SESSION_STATES.QUIZ_FINISHED,
  FINAL_RESULTS: QUIZ_SESSION_STATES.FINAL_RESULTS
};

const QUESTION_TYPE = {
  MULTIPLE:'multiple',
  QUESTIONAIRE:'questionnaire',
  TRUE_FALSE:'true_or_false',
  OPEN_QUESTION:'open_question',
  INFO:'info',
};


const MAX_ANSWER_CHARS_LENGTH = 50;

export {
  APP_DEFAULTS,
  colors,
  ROUTES,
  IMAGE_SIZES,
  IMAGE_AVAILABLE_SIZES,
  QUIZ_SESSION_STATES,
  MOBILE_VIEW,
  QUESTION_TYPE,
  MAX_ANSWER_CHARS_LENGTH,
};
