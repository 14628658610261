import cn from "classnames";
import React from "react";
import Image from "../../../../components/Image";
import { IMAGE_AVAILABLE_SIZES } from "../../../../constants";
import Countdown from "./Countdown";

export default function OpenQuestion ({ question,seconds }) {
  
  return (
    <div className='quiz'>

      <div className={cn({
        'quiz__content': true,
        'quiz__content_withoutImage': !question.image
      })}>
        <div className={cn({
          'quiz__title': true,
          'quiz__title_halfWidth': !question.image
        })}>{question.title}</div>
        
        {question.image &&
        <div className='quiz__image'>
          <Image image={question.image} size={IMAGE_AVAILABLE_SIZES.PRENSENTATION} noFallback></Image>
        </div>}
        {(question.points || question.seconds) &&
          <div className='quiz__settings'>
            <ul className='quiz__settingsList'>
              {question.seconds && <Countdown
                seconds={seconds}
                secondsTotal = {question.seconds}
              />
              }
            </ul>
          </div>}
      </div>
    </div>
  );
}
