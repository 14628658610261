const baseUrl = `${window.location.protocol}//${window.location.host}`;
const apiUrl = `${baseUrl}/api`;
const HTTP_HEADER_TOTAL_COUNT = 'X-Total-Count';

export default class ApiService {

  constructor(token = null) {
    this.token = token;
  }

  errorMessages = {
    '401':'Keine Authentifizierung! Bitte anmelden.',
    '403':'Zugriff nicht gestattet',
    '404':'Ressource nicht gefunden',
  }

  async get(url, params) {
    try {
      let options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }

      const fullUrl = new URL(apiUrl + url);
      fullUrl.search = new URLSearchParams(params).toString();

      const response = await fetch(fullUrl, options);
      const resultJson = await response.json();
      let result = {totalCount:0,result:resultJson};
      if (response.headers.has(HTTP_HEADER_TOTAL_COUNT) && typeof resultJson === 'object') {        
        result.totalCount = response.headers.get(HTTP_HEADER_TOTAL_COUNT);
      }
      
      if (response.status !== 200) {
        throw new Error(`${this.errorMessages[response.status]} (${resultJson.message})`);
      }
      return result;
    } catch (err) {
      throw err;
    }
  }

  async post(url, data) {
    try {
      let options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };

      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }

      const response = await fetch(apiUrl + url, options);
      const resultJson = await response.json();

      if (![200, 201].includes(response.status)) {
        throw new Error(`${this.errorMessages[response.status]} (${resultJson.message})`);
      }

      return resultJson;
    } catch (err) {
      throw err;
    }
  }

  async put(url, data) {
    try {
      let options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const response = await fetch(apiUrl + url, options);
      const resultJson = await response.json();

      if(response.status!==200){
        console.error('~/projects/di.cl.goethe-quiz/frontend/src/services api.js', 'response', resultJson);
        throw new Error(`${this.errorMessages[response.status]} (${resultJson.message})`);
      }
      return resultJson;
    } catch (err) {
      throw err;
    }
  }

  async delete(url) {
    try {
      let options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const response = await fetch(apiUrl + url, options);
      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  
}
