import React, { Component } from 'react';
import { Table, Popconfirm, Button, Modal, Input, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './Categories.scss';

export default class Categories extends Component {

  static propTypes = {
    categories:PropTypes.array,
    fetchCategories:PropTypes.func,
    createCategory:PropTypes.func,
    deleteCategory:PropTypes.func,
  }
  state = {
    isModalVisible: false,
    modalTitle: '',
    filter: {
      title: ''
    }
  }

  componentDidMount() {
    this.props.fetchCategories(this.state.filter);
  }

  openModal = () => {
    this.setState({ isModalVisible: true });
  }

  handleCancel = () => {
    this.setState({ modalTitle: '', isModalVisible: false });
  }

  handleOk = () => {
    const { modalTitle } = this.state;
    const { createCategory } = this.props;

    createCategory({ title: modalTitle });
    this.handleCancel();
  }

  handleChange = value => this.setState({ modalTitle: value })
  handleFilterChange = (filter) => {
    this.setState(
      { filter: Object.assign(this.state.filter, filter) },
      () => this.props.fetchCategories(this.state.filter)
    );
  }

  render() {
    const { categories, deleteCategory, t } = this.props;
    const { isModalVisible, modalTitle } = this.state;

    const columns = [
      {
        title: t("categoriesCategory"),
        dataIndex: 'title',
        key: 'title',
        width: '60%'
      },
      {
        title: t("categoriesAction"),
        key: 'action',
        width: '40%',
        render: (text, record) => (
          <Popconfirm
            title={t("categoriesDeleteConfirmation")}
            onConfirm={() => deleteCategory(record._id)}
            okText={t("generalYes")}
            cancelText={t("generalNo")}
          >
            <Button type='link'>{t("categoriesDelete")}</Button>
          </Popconfirm>
        ),
      }
    ];

    return <div className='c-categories'>
      <Modal
        title={t("categoriesNewCategory")}
        visible={isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        cancelText={t("generalCancel")}
        okText={t("generalOk")}
        >
        <Input value={modalTitle} onChange={e => this.handleChange(e.target.value)} />
      </Modal>
      <Row>
        <Col md={12}>
          <Input placeholder={t("categoriesSearchCategory")} value={this.state.filter.title} onChange={e => this.handleFilterChange({ title: e.target.value })} />
        </Col>
        <Col md={2}></Col>
        <Col md={10}>
        <Button type='primary' onClick={this.openModal}>{t("categoriesNew")}</Button>
        </Col>
      </Row>
      
      <Table columns={columns} dataSource={categories} rowKey={'_id'} />
    </div>;
  }
}
