import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
} from '../types';

export function fetchCategories(payload) {
  return {
    type: FETCH_CATEGORIES,
    payload,
  };
}

export function deleteCategory(payload) {
  return {
    type: DELETE_CATEGORY,
    payload,
  };
}

export function createCategory(payload) {
  return {
    type: CREATE_CATEGORY,
    payload,
  };
}
