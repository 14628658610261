import * as types from '../types';


export function initMobileQuiz(payload) {
  return {
    type: types.INIT_MOBILE_QUIZ,
    payload,
  };
}

export function addParticipant(payload) {
  return {
    type: types.ADD_PARTICIPANT,
    payload,
  };
}

export function setParticipant(payload){
  return {
    type: types.SET_PARTICIPANT,
    payload,
  };
}
export function commitAnswer(payload) {
  return {
    type: types.MOBILE_COMMIT_ANSWER,
    payload,
  };
};
