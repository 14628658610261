import React from 'react';
import { Switch } from 'antd';

import './Switch.scss';

export default function SwitchComponent (props) {
  return (
    <Switch className='c-switch' {...props} />
  );
}
