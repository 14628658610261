
import * as types from '../types';
export function login(id) {
  return  {
    type:types.LOGIN,
    payload:{ _id:id } 
  };
}
export function initUser() {
  return  {
    type:types.INIT_USER
  };
}
export function fetchUsers(payload) {
  return  {
    type:types.FETCH_USERS,
    payload
  };
}
export function updateUser(user) {
  return  {
    type:types.UPDATE_USER,
    payload:user
  };
}
export function deleteUser(user) {
  return  {
    type:types.DELETE_USER,
    payload:user
  };
}

export function createInviteCodes(createInviteCodesParams){
  return  {
    type:types.CREATE_INVITE_CODES,
    payload:createInviteCodesParams
  };
}
export function checkInviteCode(inviteCode){
  return  {
    type:types.CHECK_INVITE_CODE,
    payload:inviteCode
  };
}
export function logout() {
  return  {
    type:types.LOGOUT,
    payload:{} 
  };
}

export function recoverUser(userId, cb) {
  return  {
    type: types.RECOVER_USER,
    payload: { userId, cb },
  };
}
