import { fork } from 'redux-saga/effects';
import categorySagas from './category';
import quizSagas from './quiz';
import quizBuilderSagas from './quiz_builder';
import folderSagas from './folder';
import tagSagas from './tag';
import quizPresentationSagas from './quiz_presentation';
import quizSessionSagas from './quiz_session';
import quizMobileSagas from './quiz_mobile';
import userSagas from './user';
import configSagas from './config';
import mobileWebsocketSaga from './websocket_mobile';
import presentationWebsocketSaga from './websocket_presentation';

export default function* root() {
  yield fork(configSagas);
  yield fork(quizSagas);
  yield fork(quizBuilderSagas);
  yield fork(categorySagas);
  yield fork(folderSagas);
  yield fork(tagSagas);
  yield fork(userSagas);
  yield fork(quizPresentationSagas);
  yield fork(quizSessionSagas);
  yield fork(quizMobileSagas);
  yield fork(mobileWebsocketSaga);
  yield fork(presentationWebsocketSaga);
}
