import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import * as types from '../types';
import { QuizSessionService, ExportService } from '../../services';
import { QUIZ_SESSION_STATES } from "../../constants/index";

function* fetchQuizForPresentation(action) {
  try {
    // yield call(waitFor, state => state.token != null);
    // console.log('fetchQuizForPresentation', action);
    const quizSessionService = yield call(() => new QuizSessionService());

    const response = yield call([
      quizSessionService,
      quizSessionService.getQuizSessionByCode,
    ], action.payload);

    const {
      quiz
    } = response.result;

    yield put({
      type: types.SET_QUIZ_FOR_PRESENTATION,
      payload: quiz
    });

  } catch (error) {
    console.error('fetchQuizForPresentation error', error);
  }
}

function* finishQuestion(action) {
  try {
    // yield call(waitFor, state => state.token != null);
    const { presentationStep, presentationQuiz } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService());
    const { questionId, sessionId } = action.payload;

    yield call([
      quizSessionService,
      quizSessionService.finishQuestion,
    ], sessionId, questionId);

    if (presentationStep === presentationQuiz.questions.length) {
      yield put({
        type: types.SET_PRESENTATION_TYPE,
        payload: QUIZ_SESSION_STATES.QUIZ_FINISHED
      });
    } else {
      yield put({
        type: types.SET_PRESENTATION_TYPE,
        payload: QUIZ_SESSION_STATES.QUESTION
      });
      yield put({
        type: types.SET_PRESENTATION_STEP,
        payload: presentationStep + 1
      });
    }
  } catch (error) {
    console.error('finishQuestion error', error);
  }
}

function* downloadQuizResults(action) {
  try {
    const { presentationQuiz } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService());
    const { presentationCode } = action.payload;

    const response = yield call([
      quizSessionService,
      quizSessionService.getQuizSessionByCode,
    ], presentationCode);

    const quizSession = response?.result;

    if (!quizSession) {
      throw new Error('Cannot fetch quiz session');
    }

    const exportService = yield call(() => new ExportService());
    yield call([
      exportService,
      exportService.exportQuizResults,
    ], presentationQuiz, quizSession);

  } catch (error) {
    console.error('downloadQuizResults error', error);
  }
}

function* handleFinishQuestion(action) {
  // console.log('handleFinishQuestion', action);
  yield put({ type: types.PRESENTATION_SET_QUESTION_SECONDS, payload: 0 });
  yield put({ type: types.PRESENTATION_GET_QUESTION_RESULT, payload: { questionId: action.payload, updateView: true } });
  yield put({ type: types.SET_PRESENTATION_TYPE, payload: QUIZ_SESSION_STATES.QUESTION_RESULTS });
}

function* handleFetchResult(action) {
  // console.log('handleFetchResult', action);
  const { quizSession } = yield select((state) => state);
  const code = parseInt(action.payload?.code);
  if (quizSession?.code === code) {
    yield put({ type: types.PRESENTATION_SET_PRESENTATION_RESULT, payload: action.payload });
  } 

}

function* handleTickSeconds(action) {
  // console.log('handleTickSeconds');
  yield put({ type: types.PRESENTATION_SET_QUESTION_SECONDS, payload: action.payload });
}

function* handleReceivedAnswer(action) {
  // console.log('handleReceivedAnswer', action);
  const { quizSession } = yield select((state) => state);
  const code = parseInt(action.payload?.code);

  if (quizSession?.code === code) {
    yield put({ type: types.PRESENTATION_SET_TOTAL_ANSWERED_COUNT, payload: action.payload.receivedAnswersCount });
  }

}

export function* quizPresentationSagas() {
  yield takeEvery(types.FETCH_QUIZ_FOR_PRESENTATION, fetchQuizForPresentation);
  yield takeEvery(types.FINISH_QUESTION, finishQuestion);
  yield takeEvery(types.DOWNLOAD_QUIZ_RESULTS, downloadQuizResults);
  yield takeEvery(types.PRESENTATION_WEB_SOCKET_HANDLE_FINISH_QUESTION, handleFinishQuestion);
  yield takeEvery(types.PRESENTATION_WEB_SOCKET_HANDLE_FETCH_RESULT, handleFetchResult);
  yield takeEvery(types.PRESENTATION_WEB_SOCKET_HANDLE_TICK_SECONDS, handleTickSeconds);
  yield takeEvery(types.PRESENTATION_WEB_SOCKET_HANDLE_RECEIVED_ANSWER, handleReceivedAnswer);
}

export default quizPresentationSagas;
