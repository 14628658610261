import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Header } from '../../components';
import { QUESTION_TYPE, ROUTES } from '../../constants';
import { QUIZ_SESSION_STATES } from "../../constants/index";
import history from '../../history';
import logo from '../../resources/images/logo_gray.png';
import { withTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const PresentationHeader = ({ 
    path, 
    quizSession, 
    presentationStep, 
    presentationType, 
    quizSessionRedirect,
    setPresentationType,
    setPresentationStep, 
    startQuizSession,
    finishQuestion, 
    downloadQuizResults,
    getQuestionResult,
    setFinalResult,
    setTotalAnsweredCount,
    totalAnsweredCount,
    t,
}) => {
    const { code, startedAt, _id, quiz, participants } = quizSession;
    const { questions = [] } = quiz || {};

    const onStartClick = () => {
        if (!startedAt) {
            setPresentationType(QUIZ_SESSION_STATES.QUESTION);
            setPresentationStep(1);
            startQuizSession({ cb: () => history.push(`/quiz/${code}/play`), _id });
        } else {
            history.push(`/quiz/${code}/play`);
        }
    };

    const onLogoClick = () => {
        history.push(ROUTES.EDITOR.DEFAULT);
    };

    const showFinishedQuestionNavigation = () => {
        return [QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW, QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS].includes(presentationType);
    };

    const isPrevQuestionReviewDisabled = () => {
        return presentationStep === 1 && presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW;
    };

    const handleQuestionReviewBackPress = () => {
        if (!isPrevQuestionReviewDisabled()) {
          if (presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW) {
            const prevQuestion = questions[presentationStep - 2];
            if (prevQuestion.type !== QUESTION_TYPE.INFO) {
                getQuestionResult({ questionId: prevQuestion.id, updateView: false });
                setPresentationType(QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS);
            }
            setPresentationStep(presentationStep - 1);
          } else {
            setPresentationType(QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW);
          }
        }
    };

    const isNextQuestionReviewDisabled = () => {
        const curQuestion = questions[presentationStep - 1];
        return presentationStep === questions.length && (presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS || curQuestion.type === QUESTION_TYPE.INFO);
    };

    const showNext = () => {
        if( presentationType === QUIZ_SESSION_STATES.QUESTION_RESULTS ) return true;
        if( presentationType === QUIZ_SESSION_STATES.QUIZ_FINISHED ) return true;
        if( presentationType === QUIZ_SESSION_STATES.FINAL_RESULTS ) return false;
        if( presentationType === QUIZ_SESSION_STATES.QUESTION_REVIEW ) return false;
        if( presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW ) return false;
        if( presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS ) return false;
        return (([QUIZ_SESSION_STATES.QUESTION_RESULTS, QUIZ_SESSION_STATES.QUIZ_FINISHED].includes(presentationType)) || !(questions[presentationStep - 1]?.seconds));    
    };
      
    const showBack = () => {
        return [QUIZ_SESSION_STATES.QUESTION_REVIEW, QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW, QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS].includes(presentationType);
    };
      
    const showEndQuiz = () => {
        return presentationType === QUIZ_SESSION_STATES.FINAL_RESULTS;
    };
    
    const showAnsweredCount = () => {
        return [QUIZ_SESSION_STATES.QUESTION, QUIZ_SESSION_STATES.QUESTION_RESULTS].includes(presentationType) && questions[presentationStep - 1]?.type !== QUESTION_TYPE.INFO;
    };

    const showStats = () => {
        return [
          QUIZ_SESSION_STATES.QUESTION,
          QUIZ_SESSION_STATES.QUESTION_REVIEW,
          QUIZ_SESSION_STATES.QUESTION_RESULTS,
          QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW,
          QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS,
        ].includes(presentationType);
    };    

    const handleQuestionReviewNextPress = () => {
        if (!isNextQuestionReviewDisabled()) {
          const curQuestion = questions[presentationStep - 1];
          if (presentationType === QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW && curQuestion.type !== QUESTION_TYPE.INFO) {
            getQuestionResult({ questionId: curQuestion.id, updateView: false });
            setPresentationType(QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS);
          } else {
            setPresentationType(QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW);
            setPresentationStep(presentationStep + 1);
          }
        }
    };

    const getCurrentQuestionAnswersAmount = (quizSession, presentationStep) => {
        const { quiz, participants } = quizSession;
        const questionId = quiz?.questions[presentationStep - 1]?.id;
        
        let answersCounter = 0;
      
        if (Array.isArray(participants)) {
            for (const participant of participants) {
                if (Array.isArray(participant.answers)) {
                    for (const answer of participant.answers) {
                        if (answer.questionId === questionId && answer.isAnswerGiven) {
                            answersCounter++;
                            break;
                        }
                    }
                }
            }
        }
        
        return answersCounter;
    };

    const handleClick = () => {
        if ([QUIZ_SESSION_STATES.QUIZ_FINISHED].includes(presentationType)) {
            setFinalResult({});
            setPresentationType(QUIZ_SESSION_STATES.FINAL_RESULTS);
        } else if ([QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW, QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS].includes(presentationType)) {
            setPresentationType(QUIZ_SESSION_STATES.FINAL_RESULTS);
        } else if ([QUIZ_SESSION_STATES.QUESTION_REVIEW].includes(presentationType)) {
            setPresentationType(QUIZ_SESSION_STATES.FINAL_RESULTS);
        } else if (presentationType === QUIZ_SESSION_STATES.FINAL_RESULTS) {
            history.push(quizSessionRedirect);
        } else {
            finishQuestion({ sessionId: _id, questionId: questions[presentationStep - 1]?.id });
            setTotalAnsweredCount(0);
        }
    };

    const onDownloadClick = () => {
        const presentationCode = quizSession && quizSession.code;
    
        if (presentationCode) {
            downloadQuizResults({ presentationCode });
        } 
    };

    const menu = [
        {
          component: <img src={logo} alt="Logo" className="logo" />,
          cb: onLogoClick,
        }
    ];
    
    if (path === ROUTES.QUIZ.WELCOME) {
        menu.push({
            title: 'Start', cb: onStartClick,
            right: true,
        });
    } else if (path === ROUTES.QUIZ.PLAY) {
        menu.push(
            showFinishedQuestionNavigation() && {
                component: <LeftOutlined className={`preview-arrow ${isPrevQuestionReviewDisabled() && 'disabled-arrow'}`} />,
                prefixRight: true,
                cb: handleQuestionReviewBackPress,
            },
            showFinishedQuestionNavigation() && {
                component: <RightOutlined className={`preview-arrow ${isNextQuestionReviewDisabled() && 'disabled-arrow'}`} />,
                prefixRight: true,
                cb: handleQuestionReviewNextPress,
            },
            showAnsweredCount() && {
                title: t("presentationAnsweredCount", {
                    current: totalAnsweredCount || 0,
                    total: participants?.length || 0,
                }),
                prefixRight: true,
                autoCursor: true,
            },
            showStats() && {
                title: t("presentationQuestionNumber", {
                    current: presentationStep,
                    total: questions.length,
                }),
                prefixRight: true,
                autoCursor: true,
            },
            showNext() && {
                title: t("generalNext"),
                right: true,
                cb: handleClick
            },
            showBack() && {
                title: t("generalBack"),
                right: true,
                cb: handleClick
            },
            showEndQuiz() && {
                title: t("reportDownload"),
                right: true,
                cb: onDownloadClick,
            },
            showEndQuiz() && {
                title: t("presentationEndQuiz"),
                right: true,
                cb: handleClick
            },
        );
    }

    menu.filter(i => !!i);
    
    useEffect(() => {
        setTotalAnsweredCount(getCurrentQuestionAnswersAmount(quizSession, presentationStep));
    }, [quizSession, presentationStep]);

    return (
        <Header menu={menu} isWhite={path === ROUTES.QUIZ.WELCOME} />
    );
};

PresentationHeader.propTypes = {
    path: PropTypes.string,
    quizSession: PropTypes.object,
    presentationStep: PropTypes.number,
    presentationType: PropTypes.string,
    quizSessionRedirect: PropTypes.string,
    setPresentationType: PropTypes.func,
    setPresentationStep: PropTypes.func,
    startQuizSession: PropTypes.func,
    finishQuestion: PropTypes.func,
    downloadQuizResults: PropTypes.func,
    getQuestionResult: PropTypes.func,
    setFinalResult: PropTypes.func,
    setTotalAnsweredCount: PropTypes.func,
    totalAnsweredCount: PropTypes.number,
    t: PropTypes.func,
};

export default withTranslation()(PresentationHeader);