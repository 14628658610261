import * as types from '../types';
import { QUIZ_SESSION_STATES } from "../../constants";

const moduleReducers = {
  presentationQuiz: setQuiz,
  presentationStep: setStep,
  presentationType: setType,
  participants: setParticipants,
  questionSeconds: setQuestionSeconds,
  presentationResult: setPresentationResult,
  totalAnsweredCount: setTotalAnsweredCount,
};

function setQuiz(state = {}, action) {
  switch (action.type) {
    case types.SET_QUIZ_FOR_PRESENTATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function setStep(state = 1, action) {
  switch (action.type) {
    case types.SET_PRESENTATION_STEP:
      return action.payload;
    default:
      return state;
  }
}
function setType(state = QUIZ_SESSION_STATES.QUESTION, action) {
  switch (action.type) {
    case types.SET_PRESENTATION_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function setParticipants(state = [], action) {
  switch (action.type) {
    case types.PRESENTATION_WEB_SOCKET_HANDLE_ADD_PARTICIPANT:
      return [...state.filter(p => p.name !== action.payload.name), action.payload];
    case types.PRESENTATION_SET_PARTICIPANTS:
      return [...action.payload];
    default:
      return state;
  }
}

function setQuestionSeconds(state = 0, action) {
  switch (action.type) {
    case types.PRESENTATION_SET_QUESTION_SECONDS:
      return action.payload;
    default:
      return state;
  }
}

function setPresentationResult(state = { answerStats: [], participantsCount: 0, totalResult: [] }, action) {
  switch (action.type) {
    case types.PRESENTATION_SET_PRESENTATION_RESULT:
      const { answerStats, participants, totalResult } = action.payload;
      return { answerStats: answerStats || [], participantsCount: participants || 0, totalResult: totalResult || [] };
    default:
      return state;
  }
}

function setTotalAnsweredCount(state = 0, action) {
  switch (action.type) {
    case types.PRESENTATION_SET_TOTAL_ANSWERED_COUNT:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
