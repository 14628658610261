import {
  CREATE_QUIZ_SESSION,
  FETCH_QUIZ_SESSION,
  FETCH_QUIZ_SESSION_BY_CODE,
  ADD_PARTICIPANT,
  START_QUIZ_SESSION,
  SET_PARTICIPANT,
} from '../types';


export function createQuizSession(payload) {
  return {
    type: CREATE_QUIZ_SESSION,
    payload,
  };
}

export function fetchQuizSession(payload) {
  return {
    type: FETCH_QUIZ_SESSION,
    payload,
  };
}

export function fetchQuizSessionByCode(payload) {
  return {
    type: FETCH_QUIZ_SESSION_BY_CODE,
    payload,
  };
}


export function startQuizSession(payload) {
  return {
    type: START_QUIZ_SESSION,
    payload,
  };
}
