import { bindActionCreators, compose } from "redux";
import InviteCode from './InviteCode';
import { checkInviteCode } from "../../../store/actions/user";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        checkInviteCode
      },
      dispatch
    );
  }


function mapStateToProps(state) {
  return {
    user:state.user
  };
}
  
  export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(withTranslation()(InviteCode));
  

