import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Categories from './Categories';
import { fetchCategories, createCategory, deleteCategory } from '../../../store/actions/category';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCategories,
      createCategory,
      deleteCategory
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Categories));
