import {
  SyncOutlined,
	LeftOutlined,
	RightOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { Header } from '../../components';
import { ROUTES } from '../../constants';
import history from '../../history';
import logo from '../../resources/images/logo.png';
import './EditorRoute.scss';
import { withTranslation } from 'react-i18next';


const { Content } = Layout;

function EditorRoute({ component: Component, ...rest }) {
  const { builderStep, builderType, slides, setBuilderType, setBuilderStep, saving, user, t } = rest;
  const menu = [
    {
      component: <img src={logo} alt="Logo" className="logo" />,
      cb: () => history.push(ROUTES.EDITOR.DEFAULT),
      noVerticalMargin: true,
    },
    {
      title: t("headerDiscover"),
      cb: () => history.push(ROUTES.EDITOR.DISCOVER),
      path: ROUTES.EDITOR.DISCOVER
    },
    {
      title: t("headerMyQuiz"),
      cb: () => history.push(ROUTES.EDITOR.MY_QUIZZES),
      isActive: history.location.pathname === ROUTES.EDITOR.MY_QUIZZES || history.location.pathname.indexOf(ROUTES.EDITOR.EDIT_QUIZ_BASE) === 0
    },
    {
      title: t("headerCreate"),
      cb: () => history.push(ROUTES.EDITOR.CREATE_QUIZ),
      path: ROUTES.EDITOR.CREATE_QUIZ
    },
    {
      title: t("headerAdmin"), hidden: !user.isAdmin,
      cb: () => history.push(ROUTES.ADMIN.TAGS),
      isActive:  history.location.pathname.indexOf(ROUTES.ADMIN.DEFAULT) === 0
    },
    {
      component: <React.Fragment>{saving && <div className="header-saving">{t("headerSavingPrefix")}<i>{t("headerSavingPostfix")}</i> <SyncOutlined spin /></div>}</React.Fragment>,
      noVerticalMargin: true,
    },
	  builderType === 'preview' && !!slides.length && {
		  component: <LeftOutlined className={`preview-arrow ${builderStep === 1 && 'disabled-arrow'}`} />,
		  prefixRight: true,
		  cb: () => { builderStep !== 1 && setBuilderStep(builderStep - 1); }
	  },
    builderType === 'preview' && !!slides.length && {
	    component: <RightOutlined className={`preview-arrow ${slides.length === builderStep && 'disabled-arrow'}`} />,
	    prefixRight: true,
      cb: () => { slides.length !== builderStep && setBuilderStep(builderStep + 1); }
    },
    builderType === 'preview' && {
      title: t("createQuizEditQuestion"),
      right: true,
      cb: () => { setBuilderType('builder'); setBuilderStep(builderStep); }
    }
  ];

  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='wrapper'>
          <Header menu={menu} saving={saving} />
          <Content className='c-privateContent'>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
}

EditorRoute.propTypes = {
  component: PropTypes.func || PropTypes.object
};
export default withTranslation()(EditorRoute);
