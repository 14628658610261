import React, {Component} from 'react';
import { withRouter } from 'react-router';

import { Info, Multiple, TrueFalse, Result, PreFinalResult, FinalResult, OpenQuestion} from './components';
import { QUESTION_TYPE, QUIZ_SESSION_STATES } from "../../../constants";

class Quiz extends Component {

  componentDidMount() {
    const {
      match,
      getQuestionResult,
      fetchPresenterResult,
      initPresentation,
    } = this.props;

    if (match && match.params.code) {
      initPresentation({ code: match.params.code, cb: (quizSession) => {
        if(quizSession.view == QUIZ_SESSION_STATES.QUESTION_RESULTS) {          
            const question = quizSession.quiz?.questions?.[quizSession.step -1];
            if (question){
              getQuestionResult({ questionId: question.id, updateView: true });
            }
        }
        if([QUIZ_SESSION_STATES.QUIZ_FINISHED,QUIZ_SESSION_STATES.FINAL_RESULTS].includes(quizSession.view)){
          fetchPresenterResult({});
        }
      }});
    }
  }

  renderQuestion = question => {
    if (!question) {
      return null;
    }

    const { questionSeconds } = this.props;

    const props = { question, seconds: questionSeconds };

    switch (question.type) {
      case QUESTION_TYPE.MULTIPLE:
      case QUESTION_TYPE.QUESTIONAIRE:
        return <Multiple {...props} />;
      case QUESTION_TYPE.TRUE_FALSE:
        return <TrueFalse {...props} />;
      case QUESTION_TYPE.OPEN_QUESTION:
        return <OpenQuestion {...props}/>;
      case QUESTION_TYPE.INFO:
        return <Info {...props}/>;
      default:
        return null;
    }
  }

  handleQuestionClick = (id, presentationType = QUIZ_SESSION_STATES.QUESTION_REVIEW) => {
    const { presentationQuiz: { questions = [] }, setPresentationStep, setPresentationType } = this.props;

    const questionInd = questions.findIndex(question => question.id === id);

    setPresentationStep(questionInd + 1);
    setPresentationType(presentationType);
  }

  render () {
    const { presentationStep, presentationQuiz: { questions = [] }, presentationType, redirect, t } = this.props;
    const { answerStats, participantsCount, totalResult } = this.props.presentationResult;
    // return this.renderQuestion(questions[presentationStep - 1])

    if ([QUIZ_SESSION_STATES.QUESTION_RESULTS, QUIZ_SESSION_STATES.FINISHED_QUESTION_RESULTS].includes(presentationType)) {
      return <Result
        answerStats={answerStats}
        participantsCount={participantsCount}
        question={questions[presentationStep - 1]}
        totalResult={totalResult}
      />;
    }

    if (presentationType === QUIZ_SESSION_STATES.QUIZ_FINISHED) {
      return <PreFinalResult />;
    }

    if (presentationType === QUIZ_SESSION_STATES.FINAL_RESULTS) {
      return <FinalResult
        questions={questions}
        totalResult={totalResult}
        handleQuestionClick={this.handleQuestionClick}
        redirect={redirect}     
      />;
    }

    return this.renderQuestion(questions[presentationStep - 1]);
  }
}

export default withRouter(Quiz);
