import {
  FETCH_LAST_QUIZZES,
  FETCH_QUIZZES,
  FAVORITE_QUIZ,
  UNFAVORITE_QUIZ,
  FETCH_QUIZ_BY_ID,
  COPY_QUIZ,
  FETCH_MY_QUIZZES,
  FETCH_FAVORITE_QUIZZES,
  DELETE_QUIZ
} from '../types';

export function fetchQuizzes(payload) {
  return {
    type: FETCH_QUIZZES,
    payload,
  };
}

export function fetchLastQuizzes(payload) {
  return {
    type: FETCH_LAST_QUIZZES,
    payload,
  };
}

export function fetchMyQuizzes(payload) {
  return {
    type: FETCH_MY_QUIZZES,
    payload,
  };
}

export function fetchFavoriteQuizzes(payload) {
  return {
    type: FETCH_FAVORITE_QUIZZES,
    payload,
  };
}

export function copyQuiz(payload) {
  return {
    type: COPY_QUIZ,
    payload,
  };
}

export function favoriteQuiz(payload) {
  return {
    type: FAVORITE_QUIZ,
    payload,
  };
}

export function unfavoriteQuiz(payload) {
  return {
    type: UNFAVORITE_QUIZ,
    payload,
  };
}

export function fetchQuizById(payload) {
  return {
    type: FETCH_QUIZ_BY_ID,
    payload,
  };
}

export function deleteQuiz(payload) {
  return {
    type: DELETE_QUIZ,
    payload,
  };
}
