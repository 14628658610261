import * as types from '../types';

const moduleReducers = {
  folders: setFolders,
};

function setFolders(state = [], action) {
  switch (action.type) {
    case types.SET_FOLDERS:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
