import React from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import history from '../../history';
import './Header.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { logout } from '../../store/actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HamburgerMenu from './HamburgerMenu';
import { createInviteCodes } from '../../store/actions/user';

const { Header } = Layout;

function HeaderComponent (props) {

  const isActive = item => history.location.pathname === item.path;

  const onClick = item => {
    if (item.cb) {
      item.cb();
    }
  };
  
  const onLogoutPress = () => {
    props.logout();
  };

  const onCreateInviteCodes = (params) => {
    props.createInviteCodes(params);
  };
  
  const renderItems = (items) => {
    return (
      <div className="items-container">
      {items.map((item, index) => (
          !item.hidden &&
          <div
            key={`${item.title || ""}${index}`}
            onClick={() => onClick(item)}
            className={cn({
              'c-header-menu-item': true,
              'c-header-menu-item__active': isActive(item) || item.isActive,
              'c-header-menu-item__black': props.isWhite,
              'c-header-menu-item__right': item.right,
              'no-vertical-margin': !!item.noVerticalMargin,
              'auto-cursor': !!item.autoCursor,
            })}
          >
            {item.hidden}
            {item.title && item.title }
            {item.component && item.component}
          </div>
      ))}
      </div>
    );
  };
  
  const leftItems = (Array.isArray(props.menu) && props.menu.filter((item) => (!item.right && !item.prefixRight))) || [];
  const rightItems = (Array.isArray(props.menu) && props.menu.filter((item) => (item.right && !item.prefixRight))) || [];
  const prefixRightItems = (Array.isArray(props.menu) && props.menu.filter((item) => (!item.right && item.prefixRight))) || [];
  
  return (
    <Header className={cn({
      'c-header': true,
      'c-header__white': props.isWhite
    })}>
      {props.menu && <div className='c-header-menu'>
        {renderItems(leftItems)}
        <div className="prefix-right-items-container">
          {renderItems(prefixRightItems)}
        </div>
        {renderItems(rightItems)}
        <HamburgerMenu
	        onLogoutPress={onLogoutPress}
	        isWhite={!props.isWhite}
          onCreateInviteCodes={onCreateInviteCodes}
        />
      </div>
      }
    </Header>
  );
}

HeaderComponent.propTypes = {
  menu:PropTypes.array,
  isWhite:PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { logout, createInviteCodes },
    dispatch,
  );
}

function mapStateToProps(state) {
  return {  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HeaderComponent));
