import { InputNumber } from "antd";
import { useRef } from "react";
import PropTypes from 'prop-types';

function InputUnit(props) {
    const numberInput = useRef(null);
    let { unit, onChange, value, placeholder, max } = props;
    // let onBlur = (val) => { console.log(val)}
    return <InputNumber
      className='c-quiz-item-input'
      placeholder={placeholder}
      value={value}
      formatter={(value) => {
        return numberInput?.current?.state?.focused ? parseInt(value) : `${value} ${unit}`;}
      }
      parser={(value) => {
        if(isNaN(value) || isNaN(parseInt(value))){
          value = 0;
        }
        return !isNaN(value)?parseInt(value):0;
      }}
      max={max}
      onChange={(value) => onChange(value)}
      ref={numberInput}
    />;
  }

  InputUnit.propTypes ={
    unit:PropTypes.string,
    onChange:PropTypes.func,
    value:PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder:PropTypes.string,
    max:PropTypes.number,
  };

  export default InputUnit;
