import {
  FETCH_QUIZ_FOR_PRESENTATION,
  FINISH_QUESTION,
  SET_PRESENTATION_TYPE,
  SET_PRESENTATION_STEP,
  DOWNLOAD_QUIZ_RESULTS,
  INIT_PRESENTATION_WEB_SOCKETS_CHANNEL,
  PRESENTATION_GET_QUESTION_RESULT,
  PRESENTATION_FETCH_PRESENTER_RESULT,
  PRESENTATION_SET_FINAL_RESULT,
  PRESENTATION_SET_TOTAL_ANSWERED_COUNT,
} from '../types';

export function fetchQuizForPresentation(payload) {
  return {
    type: FETCH_QUIZ_FOR_PRESENTATION,
    payload,
  };
}

export function finishQuestion(payload) {
  return {
    type: FINISH_QUESTION,
    payload,
  };
}

export function setPresentationType(payload) {
  return {
    type: SET_PRESENTATION_TYPE,
    payload,
  };
}

export function setPresentationStep(payload) {
  return {
    type: SET_PRESENTATION_STEP,
    payload,
  };
}

export function downloadQuizResults(payload) {
  return {
    type: DOWNLOAD_QUIZ_RESULTS,
    payload,
  };
}

export function initPresentation(payload) {
  return {
    type: INIT_PRESENTATION_WEB_SOCKETS_CHANNEL,
    payload,
  };
}

export function getQuestionResult(payload) {
  return {
    type: PRESENTATION_GET_QUESTION_RESULT,
    payload,
  };
}

export function fetchPresenterResult(payload) {
  return {
    type: PRESENTATION_FETCH_PRESENTER_RESULT,
    payload,
  };
}

export function setFinalResult(payload) {
  return {
    type: PRESENTATION_SET_FINAL_RESULT,
    payload,
  };
}

export function setTotalAnsweredCount(payload) {
  return {
    type: PRESENTATION_SET_TOTAL_ANSWERED_COUNT,
    payload,
  };
}
