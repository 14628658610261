import { BlobServiceClient } from '@azure/storage-blob';


export default class AzureStorage {
    constructor(config){
        this.storageToken = config.azureStorageToken;
        this.containerName = config.azureContainerName;
        this.storageAccountName = config.azureStorageAccount;
    }

    async storeFile(file, type, filename) {
        const blobService = new BlobServiceClient(
            `https://${this.storageAccountName}.blob.core.windows.net/?${this.storageToken}`
        );
        const containerClient = blobService.getContainerClient(this.containerName);
        const blobClient = containerClient.getBlockBlobClient(filename);
        const options = { blobHTTPHeaders: { blobContentType: type } };
        return blobClient.uploadData(file, options);
    }

    async removeFile( fileUrl) {
        let urlParts = fileUrl.split('/');
        if(urlParts.length>0){
            let [filename] = urlParts[urlParts.length-1].split('?');
            const blobService = new BlobServiceClient(
                `https://${this.storageAccountName}.blob.core.windows.net/?${this.storageToken}`
            );
            const containerClient = blobService.getContainerClient(this.containerName);
            const blobClient = containerClient.getBlockBlobClient(filename);
            return blobClient.deleteIfExists();
        }
        return;
    }

    getUrl(filename){
        let ts = (new Date()).getTime();
        return `https://${this.storageAccountName}.blob.core.windows.net/${this.containerName}/${filename}?${ts}`;
    }
    
}