import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import './PresentationRoute.scss';
import { withTranslation } from 'react-i18next';
import PresentationHeader from '../../components/PresentationHeader';

const { Content } = Layout;

function PresentationRoute({ component: Component, path, t, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='wrapper'>
          <PresentationHeader path={path} />
          <Content className='c-presentationContent'>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
}
PresentationRoute.propTypes ={
  component: PropTypes.func,
  path: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(PresentationRoute);
