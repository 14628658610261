import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Quiz from './Quiz';

import {
  fetchQuizForPresentation, finishQuestion, setPresentationType, setPresentationStep, initPresentation, getQuestionResult, fetchPresenterResult,
} from '../../../store/actions/quiz_presentation';
import {fetchQuizSessionByCode} from '../../../store/actions/quiz_session';
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQuizForPresentation,
      fetchQuizSessionByCode,
      finishQuestion,
      setPresentationType,
      setPresentationStep,
      initPresentation,
      getQuestionResult,
      fetchPresenterResult,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    presentationQuiz: state.presentationQuiz,
    presentationStep: state.presentationStep,
    presentationType: state.presentationType,
    questionSeconds: state.questionSeconds,
    presentationResult: state.presentationResult,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Quiz));
