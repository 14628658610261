import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { isCorrectAnswerGiven } from '../../../../helper/quiz';
import './styles.scss';
import { Input } from '../../../../components';

export default withTranslation()(function TrueFalse({
  slide,
  onUpdateSlide,
  t,
}) {

  const updateAnswer = value => {
    if (value) {
      onUpdateSlide({
        ...slide,
        answers: [
          { ...slide.answers[0], right: true },
          { ...slide.answers[1], right: false }
        ]
      });
    } else {
      onUpdateSlide({
        ...slide,
        answers: [
          { ...slide.answers[0], right: false },
          { ...slide.answers[1], right: true }
        ]
      });
    }
  };
  
  const updateAnswerTitle = (index, value) => {
  	const answers = [...slide.answers];
	  answers[index].title = value;
	  
	  onUpdateSlide({
		  ...slide,
		  answers,
	  });
  };

  return (
    <>
      <Row>
        {slide.answers.map((answer, idx) =>
          <Col
            key={answer.id}
            span={11}
            offset={idx && 2}
            className={`c-quiz-item c-quiz-item__answer ${(!isCorrectAnswerGiven(slide)) && 'has-error'}`}
            onClick={() => updateAnswer(!idx)}
          >
            {answer.right
              ? <CheckCircleFilled className='c-quiz-item-icon c-quiz-item-icon__selected true-false' />
              : <span className={`c-quiz-item-icon c-quiz-item-icon__unselected true-false ${!isCorrectAnswerGiven(slide) && 'has-error'}`} />
            }
            <div className='c-quiz-item-title'>
              <Input
                className={`c-quiz-item-input true-false`}
                placeholder={!idx ? t("generalTrue") : t("generalFalse")}
                value={answer.title}
                onClick={e => e.stopPropagation()}
                onChange={e => updateAnswerTitle(idx, e.target.value)}
                maxLength={50}
	            />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
});
