import { eventChannel } from 'redux-saga';
import { put, call, take, takeEvery, fork } from "redux-saga/effects";
import * as types from '../types';
import io from 'socket.io-client';

function connect(code) {
    // let code = '208639';
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const socket = io(`${baseUrl}?code=${code}`, { transports: ['websocket'] });

    // const socket = io('http://localhost:4123/');
    return new Promise(resolve => {
        socket.on('connect', () => {
            resolve(socket);
            // console.log("Socket connected");
        });
    });
}

function subscribe(socket) {
    return new eventChannel(emit => {
        // console.log("socket listening on any event");
        // emit({ type: 'SOCKET_CONNECTED' });
        // socket.on('tickSeconds', payload => emit({ type: 'TICK_SECONDS', payload }));
        socket.on('fetchQuestion', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FETCH_QUESTION, payload })); // triggered when a new question is available
        socket.on('finishQuestion', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FINISH_QUESTION, payload })); // triggered when a question answered or time is over
        socket.on('fetchAnswer', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FETCH_ANSWER, payload })); // triggered when a participant answered a question
        socket.on('fetchResult', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FETCH_RESULT, payload })); // triggered when new total scores are available
        socket.on('finishQuiz', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FINISH_QUIZ, payload })); // triggerd when last question is answered
        socket.on('gotoFinalResult', payload => emit({ type: types.MOBILE_WEB_SOCKET_HANDLE_FINAL_RESULT, payload })); // triggerd when presentor goes to final result page
        return () => { };
    });
}

function* read(socket) {
    const channel = yield call(subscribe, socket);
    // console.log("first action", channel);
    yield put({ type: 'SET_SOCKET_CONNECTED', payload: true});
    while (true) {
        let action = yield take(channel);
        // console.log("action", action);
        yield put(action);
    }
}

function* answerQuestion(socket) {
    while (true) {
        const { payload } = yield take(types.MOBILE_SEND_ANSWER);
        // console.log("send answer", payload);
        socket.emit('answerQuestion', payload);
    }
}
function* restartParticipantSession(socket) {
    while (true) {
        const { payload } = yield take(types.MOBILE_RESTART_PARTICIPANT_SESSION);
        // console.log("restartParticipantSession", payload);
        socket.emit('restartParticipantSession', payload);
    }
}
function* fetchParticipantResult(socket) {
    while (true) {
        const { payload } = yield take(types.MOBILE_FETCH_PARTICIPANT_RESULT);
        // console.log("fetchParticipantResult", payload);
        socket.emit('fetchParticipantResult', payload);
    }
}

function* flow(action) {
    // yield take(GET_TODOS)
    const socket = yield call(connect, action.payload);
    yield fork(read, socket);
    yield fork(answerQuestion, socket);
    yield fork(restartParticipantSession, socket);
    yield fork(fetchParticipantResult, socket);
}
export default function* mobileWebsocketSaga() {
    yield takeEvery(types.INIT_MOBILE_WEB_SOCKETS_CHANNEL, flow);
}