import React, { Component } from 'react';
import { SearchOutlined, PlusOutlined, StarFilled, CheckOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Col, Row, Select as AntdSelect } from 'antd';
import {Button, Card, Input, Modal, Select} from '../../../components';
import PropTypes from 'prop-types';
import history from '../../../history';
import './MyQuizzes.scss';
import { ROUTES } from '../../../constants';

const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

const sortingOptions = [
  { key: 'title', title: 'sortingTitle', sortKey: 'title' },
  { key: 'favCount', title: 'sortingFavCount', sortKey: 'favCount'  },
  { key: 'level', title: 'sortingLevel', sortKey: 'level' },
  { key: 'createdAt', title: 'sortingCreatedAt', sortKey: 'createdAt' },
];

const QUIZZES_OFFSET_STEP = 3;
const QUIZ_PLACEHOLDER_IMG_RATIO = 229 / 408;

export default class MyQuizzes extends Component {
  state = {
    search: '',
    sorting: sortingOptions[3].key,
    sortDir: SORT_DIR.DESC,
    favSorting: sortingOptions[3].key,
    favSortDir: SORT_DIR.DESC,
    myQuizzesOffset: 0,
    favoriteQuizzesOffset: 0,
    isOpenModal: false,
    isOpenFolderCreationModal: false,
    folderTitle: '',
    activeFolderId: null,
    createQuizHeght: 0,
  }

  constructor(props){
    super(props);

    this.createQuizRef = React.createRef();
  }

  componentDidMount () {
    const { fetchFolders } = this.props;

    this.fetchMyQuizzes(QUIZZES_OFFSET_STEP, 0, false);
    this.fetchFavoriteQuizzes(QUIZZES_OFFSET_STEP, 0, false);
    fetchFolders();

    window.addEventListener('resize', this.updateCreateQuizHeight);
    this.updateCreateQuizHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCreateQuizHeight);
  }

  updateCreateQuizHeight = () => {
    this.setState({
      createQuizHeight: (this.createQuizRef.current?.offsetWidth || 0) * QUIZ_PLACEHOLDER_IMG_RATIO,
    });
  };

  createQuizzesFetchParams = (limit, sortDir, sortKey, offset, loadMore) => {
    const { activeFolderId, search } = this.state;

    const params = {
      limit,
      sortDir,
      sortKey,
      offset,
      loadMore,
    };

    if (search) {
      params.search = search;
    }

    if (activeFolderId) {
      params.folderId = activeFolderId;
    }

    return params;
  }

  fetchMyQuizzes = (amount, offset, loadMore) => {
    const { sorting, sortDir } = this.state;
    const { fetchMyQuizzes } = this.props;
    let { sortKey } = sortingOptions.find(option => option.key === sorting);

    const params = this.createQuizzesFetchParams(amount, sortDir, sortKey, offset, loadMore);

    fetchMyQuizzes(params);
  };

  refreshMyQuizzes = () => {
    const { myQuizzesOffset } = this.state;
    this.fetchMyQuizzes(myQuizzesOffset + QUIZZES_OFFSET_STEP, 0, false);
  };

  loadMoreMyQuizzes = () => {
    this.setState(prevState => ({
      myQuizzesOffset: prevState.myQuizzesOffset + QUIZZES_OFFSET_STEP,
    }), () => {
      const { myQuizzesOffset } = this.state;
      this.fetchMyQuizzes(QUIZZES_OFFSET_STEP, myQuizzesOffset, true);
    });
  }

  fetchFavoriteQuizzes = (amount, offset, loadMore) => {
    const { favSorting, favSortDir } = this.state;
    const { fetchFavoriteQuizzes } = this.props;
    const { sortKey } = sortingOptions.find(option => option.key === favSorting);

    const params = this.createQuizzesFetchParams(amount, favSortDir, sortKey, offset, loadMore);

    fetchFavoriteQuizzes(params);
  };

  loadMoreFavoriteQuizzes = () => {
    this.setState(prevState => ({
      favoriteQuizzesOffset: prevState.favoriteQuizzesOffset + QUIZZES_OFFSET_STEP,
    }), () => {
      const { favoriteQuizzesOffset } = this.state;
      this.fetchFavoriteQuizzes(QUIZZES_OFFSET_STEP, favoriteQuizzesOffset, true);
    });
  }

  refreshFavoriteQuizzes = () => {
    const { favoriteQuizzesOffset } = this.state;
    this.fetchFavoriteQuizzes(favoriteQuizzesOffset + QUIZZES_OFFSET_STEP, 0, false);
  };

  handleChange = (key, value, cb = () => {}) => {
    this.setState({ [key]: value }, cb);
  };

  openQuizModal = quiz => {
    this.setState({
      isOpenModal: true,
    });
    this.props.fetchQuizById(quiz._id);
  }

  closeQuizModal = () => {
    this.setState({
      isOpenModal: false
    });
    this.props.fetchQuizById(null);
  }

  openFolderCreationModal = () => {
    this.setState({
      folderTitle: '',
      isOpenFolderCreationModal: true
    });
  }

  closeFolderCreationModal = () => {
    this.setState({
      folderTitle: '',
      isOpenFolderCreationModal: false
    });
  }

  createFolder = () => {
    const { folderTitle } = this.state;

    this.props.createFolder({ title: folderTitle });

    this.closeFolderCreationModal();
  }

  handleChangeFolderTitle = e => this.setState({ folderTitle: e.target.value })
  
  renderSortDirBtn = (onClick, key, currentSortDir) => {
      return (
        <>
          {currentSortDir === SORT_DIR.ASC
            ?
            <SortAscendingOutlined
              className='sort-direction-btn'
              onClick={() => onClick(key, SORT_DIR.DESC)}
            />
            :
            <SortDescendingOutlined
              className='sort-direction-btn'
              onClick={() => onClick(key, SORT_DIR.ASC)}
            />
          }
        </>
      );
  };

  onDeleteQuiz = (id) => {
    this.props.deleteQuiz({ id, cb: () => {
      this.refreshAllQuizzes();
      this.closeQuizModal();
    }});
  };

  onFolderPress = (folderId) => {
    this.setState((prevState) => ({
      activeFolderId: prevState.activeFolderId === folderId ? null : folderId,
    }), () => {
      this.refreshAllQuizzes();
    });
  };

  refreshAllQuizzes = () => {
    this.refreshMyQuizzes();
    this.refreshFavoriteQuizzes();
  };

  render () {
    const {
      myQuizzes,
      myQuizzesCount,
      favoriteQuizzes,
      canLoadMoreMyQuizzes,
      canLoadMoreFavoriteQuizzes,
      currentQuiz,
      folders,
      t,
    } = this.props;

    const { sorting, sortDir, favSorting, favSortDir, isOpenModal, isOpenFolderCreationModal, folderTitle, activeFolderId, createQuizHeight } = this.state;

    return <div className='c-myQuizzes'>
      {isOpenModal && <Modal visible footer={null} width={1250} onCancel={this.closeQuizModal}>
        <Row>
          <Col span={10}>
            <Card
              quiz={currentQuiz}
              type='singleItem'
              onDeleteQuiz={this.onDeleteQuiz}
            />
          </Col>
          <Col span={12} offset={2}>
            {currentQuiz && Array.isArray(currentQuiz.questions) && currentQuiz.questions.map(question => <div className='c-myQuizzes-modal-question' key={question.title}>
              <p>{question.title}</p>
              <p>{question.description}</p>
            </div>)}
          </Col>
        </Row>
      </Modal>
      }

      {isOpenFolderCreationModal && <Modal visible footer={null} width={1250} onCancel={this.closeFolderCreationModal}>
        <Row>
          <Col span={24} className='c-myQuizzes-modal-folder c-myQuizzes-modal-folder-title'>{t("myQuizzesNewFolder")}</Col>
          <Col span={24} className='c-myQuizzes-modal-folder c-myQuizzes-modal-folder-input'>
            <Input value={folderTitle} onChange={this.handleChangeFolderTitle} />
          </Col>
          <Col span={24} className='c-myQuizzes-modal-folder c-myQuizzes-modal-folder-button'>
            <Button
              className='c-myQuizzes-cards-more-button'
              style={{ width: 'inherit' }}
              onClick={this.createFolder}
            >
              {t("generalSave")}
            </Button>
          </Col>
        </Row>
      </Modal>
      }

      <div className='c-myQuizzes-search'>
        <Input suffix={<SearchOutlined/>} placeholder={t("generalSearch")} onChange={e => this.handleChange('search', e.target.value, this.refreshAllQuizzes) }/>
      </div>

      <div className='c-myQuizzes-folders'>
        <Row justify='space-between'>
          <Col span={24} className='c-myQuizzes-folders-header'>{t("myQuizzesMyFolders")}</Col>
        </Row>

        <Row className='c-myQuizzes-folders-list' gutter={[55, 55]}>
          {folders.map(folder =>
            <Col xs={12} lg={8} key={folder._id}>
              <div
                className='c-myQuizzes-folders-list-item'
                onClick={() => this.onFolderPress(folder._id) }
              >
                {activeFolderId === folder._id && <CheckOutlined className='c-myQuizzes-folders-list-item__active'/>}
                <div className='c-myQuizzes-folders-list-item-title'>{folder.title}</div>
              </div>
            </Col>
          )}
          <Col xs={12} lg={8}>
            <div
              className='c-myQuizzes-folders-list-item c-myQuizzes-folders-list-item__add'
              onClick={this.openFolderCreationModal}
            >
              <div className='c-myQuizzes-folders-list-item__add-icon'><PlusOutlined /></div>
              <div className='c-myQuizzes-folders-list-item__add-title'>{t("myQuizzesCreateNewFolder")}</div>
            </div>
          </Col>
        </Row>
      </div>

      <div className='c-myQuizzes-cards'>
        <Row justify='space-between'>
          <Col xs={12} className='c-myQuizzes-cards-header'>{t("myQuizzesMyQuizzes")}</Col>
          <Col xs={12} className='c-myQuizzes-cards-header c-myQuizzes-cards-header__right'>
            <span>{t("discoverSortBy")}</span>
            <Select value={sorting} onChange={value => this.handleChange('sorting', value, this.refreshMyQuizzes)}>
              {sortingOptions.map(option => <AntdSelect.Option key={option.key}>{t(option.title)}</AntdSelect.Option>)}
            </Select>
            {this.renderSortDirBtn((key, value) => { this.handleChange(key, value, this.refreshMyQuizzes); }, 'sortDir', sortDir)}
          </Col>
        </Row>
	
	      <Row className='total-count'>
		      {t('generalTotalCount', {
		      	value: myQuizzesCount
		      })}
	      </Row>

        <Row className='c-myQuizzes-cards-list' gutter={[54, 54]}>
          {myQuizzes.map(quiz =>
            <Col xs={12} lg={8} key={quiz._id}>
              <Card
                quiz={quiz}
                type='listItem'
                openModal={this.openQuizModal}
                onDeleteQuiz={this.onDeleteQuiz}
              />
            </Col>
          )}
          <Col xs={12} lg={8}>
            <div
              ref={this.createQuizRef}
              className='c-myQuizzes-folders-list-item c-myQuizzes-folders-list-item__add'
              style={{ height: `${createQuizHeight}px` }}
              onClick={() => history.push(ROUTES.EDITOR.CREATE_QUIZ)}
            >
              <div className='c-myQuizzes-folders-list-item__add-icon'><PlusOutlined /></div>
              <div className='c-myQuizzes-folders-list-item__add-title'>{t("myQuizzesCreateNewQuiz")}</div>
            </div>
          </Col>
        </Row>

        <Row justify='space-between'>
          <Col span={7}/>
          <Col span={7} className='c-myQuizzes-cards-more'>
            <Button
              className='c-myQuizzes-cards-more-button'
              onClick={this.loadMoreMyQuizzes}
              disabled={!canLoadMoreMyQuizzes}
            >
              {t("discoverShowMore")}
            </Button>
          </Col>
          <Col span={7}/>
        </Row>
      </div>

      <div className='c-myQuizzes-cards'>
        <Row justify='space-between'>
          <Col span={12} className='c-myQuizzes-cards-header'>{t("myQuizzesMarkedQuizzes")} <StarFilled /></Col>
          <Col span={12} className='c-myQuizzes-cards-header c-myQuizzes-cards-header__right'>
            <span>{t("discoverSortBy")}</span>
            <Select value={favSorting} onChange={value => this.handleChange('favSorting', value, this.refreshFavoriteQuizzes)}>
              {sortingOptions.map(option => <AntdSelect.Option key={option.key}>{t(option.title)}</AntdSelect.Option>)}
            </Select>
            {this.renderSortDirBtn((key, value) => { this.handleChange(key, value, this.refreshFavoriteQuizzes); }, 'favSortDir', favSortDir)}
          </Col>
        </Row>

        <Row className='c-myQuizzes-cards-list' gutter={[54, 54]}>
          {favoriteQuizzes.map(quiz =>
            <Col xs={12} lg={8} key={quiz._id}>
              <Card
                quiz={quiz}
                type='listItem'
                openModal={this.openQuizModal}
                onDeleteQuiz={this.onDeleteQuiz}
              />
            </Col>
          )}
        </Row>

        <Row justify='space-between'>
          <Col span={7}/>
          <Col span={7} className='c-myQuizzes-cards-more'>
            <Button
              className='c-myQuizzes-cards-more-button'
              onClick={this.loadMoreFavoriteQuizzes}
              disabled={!canLoadMoreFavoriteQuizzes}
            >
              {t("discoverShowMore")}
            </Button>
          </Col>
          <Col span={7}/>
        </Row>
      </div>
    </div>;
  }
}

MyQuizzes.propTypes = {
  myQuizzes: PropTypes.array,
  myQuizzesCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  favoriteQuizzes: PropTypes.array,
  canLoadMoreMyQuizzes: PropTypes.bool,
  canLoadMoreFavoriteQuizzes: PropTypes.bool,
  currentQuiz: PropTypes.object,
  folders: PropTypes.array,
  t: PropTypes.func,
  fetchFavoriteQuizzes: PropTypes.func,
  deleteQuiz: PropTypes.func,
  fetchMyQuizzes: PropTypes.func,
  createFolder: PropTypes.func,
  fetchQuizById: PropTypes.func,
  fetchFolders: PropTypes.func,
};
