import * as types from '../types';

const moduleReducers = {
  quizzes: setQuizzes,
  lastQuizzes: setLastQuizzes,
  myQuizzes: setMyQuizzes,
  favoriteQuizzes: setFavoriteQuizzes,
  quizzesCount: setQuizzesCount,
  lastQuizzesCount: setLastQuizzesCount,
  myQuizzesCount: setMyQuizzesCount,
  favoriteQuizzesCount: setFavoriteQuizzesCount,
  currentQuiz: setCurrentQuiz,
  canLoadMoreQuizzes: setCanLoadMoreQuizzes,
  canLoadMoreLastQuizzes: setCanLoadMoreLastQuizzes,
  canLoadMoreMyQuizzes: setCanLoadMoreMyQuizzes,
  canLoadMoreFavoriteQuizzes: setCanLoadMoreFavoriteQuizzes,
};

const patchQuizById = (state, payload) => {
  const index = state.findIndex(quiz => payload._id === quiz._id);
  if (index < 0) {
    return state;
  } else {
    return [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1)
    ];
  }
};

function setQuizzes(state = [], action) {
  switch (action.type) {
    case types.SET_QUIZZES:
      return action.payload;
    case types.PATCH_QUIZ_BY_ID:
      return patchQuizById(state, action.payload);
    case types.SET_MORE_QUIZZES:
      return [...state, ...action.payload];
    default:
      return state;
  }
}

function setQuizzesCount(state = 0, action) {
  switch (action.type) {
    case types.SET_QUIZZES_COUNT:
      return action.payload;
    default:
      return state;
  }
}

function setLastQuizzes(state = [], action) {
  switch (action.type) {
    case types.SET_LAST_QUIZZES:
      return action.payload;
    case types.PATCH_QUIZ_BY_ID:
      return patchQuizById(state, action.payload);
    case types.SET_MORE_LAST_QUIZZES:
      return [...state, ...action.payload];
    default:
      return state;
  }
}

function setLastQuizzesCount(state = 0, action) {
  switch (action.type) {
    case types.SET_LAST_QUIZZES_COUNT:
      return action.payload;
    default:
      return state;
  }
}

function setMyQuizzes(state = [], action) {
  switch (action.type) {
    case types.SET_MY_QUIZZES:
    // console.log(action.payload);
      return action.payload;
    case types.PATCH_QUIZ_BY_ID:
      return patchQuizById(state, action.payload);
    case types.SET_MORE_MY_QUIZZES:
      return  [...state, ...action.payload];
    default:
      return state;
  }
}


function setMyQuizzesCount(state = 0, action) {
  switch (action.type) {
    case types.SET_MY_QUIZZES_COUNT:
      return action.payload;
    default:
      return state;
  }
}


function setFavoriteQuizzes(state = [], action) {
  switch (action.type) {
    case types.SET_FAVORITE_QUIZZES:
      return action.payload;
    case types.PATCH_QUIZ_BY_ID:
      const exist = state.find(i => i._id === action.payload._id);
      return exist
        ? state.filter(i => i._id !== action.payload._id)
        : [...state, action.payload];
    case types.SET_MORE_FAVORITE_QUIZZES:
      return [...state, ...action.payload];
    default:
      return state;
  }
}


function setFavoriteQuizzesCount(state = 0, action) {
  switch (action.type) {
    case types.SET_FAVORITE_QUIZZES_COUNT:
      return action.payload;
    default:
      return state;
  }
}


function setCurrentQuiz(state = null, action) {
  switch (action.type) {
    case types.SET_QUIZ_BY_ID:
    case types.PATCH_QUIZ_BY_ID:
      return { ...(state || {}), ...action.payload };
    default:
      return state;
  }
}

function setCanLoadMoreQuizzes(state = true, action) {
  switch (action.type) {
    case types.SET_CAN_LOAD_MORE_QUIZZES:
      return action.payload;
    default:
      return state;
  }
}

function setCanLoadMoreLastQuizzes(state = true, action) {
  switch (action.type) {
    case types.SET_CAN_LOAD_MORE_LAST_QUIZZES:
      return action.payload;
    default:
      return state;
  }
}

function setCanLoadMoreMyQuizzes(state = true, action) {
  switch (action.type) {
    case types.SET_CAN_LOAD_MORE_MY_QUIZZES:
      return action.payload;
    default:
      return state;
  }
}

function setCanLoadMoreFavoriteQuizzes(state = true, action) {
  switch (action.type) {
    case types.SET_CAN_LOAD_MORE_FAVORITE_QUIZZES:
      return action.payload;
    default:
      return state;
  }
}


export default moduleReducers;
