import PropTypes from "prop-types";
import { Info, OpenQuestion, Questionnaire, TrueFalse } from ".";
import { APP_DEFAULTS, QUESTION_TYPE } from "../../../../constants";
import Multiple from "./Multiple";
import { useEffect, useState } from "react";
import { Col, Row} from "antd";
import { Input, InputUnit } from '../../../../components';
import { isQuestionValid } from '../../../../helper/quiz';
import ImageDropzone from "../../../../components/Image/ImageDropzone";
import { withTranslation } from "react-i18next";

function Slide(props) {
    let { slide, updateSlide, storeSlideImage, removeSlideImage, t } = props;

    const [slideState, setSlideState] = useState(slide);

    useEffect(() => {
        if (slide.id !== slideState.id) {
            setSlideState(slide);
        }
    },[slide]);

    const onUpdateSlide = (slide) => {
        setSlideState(slide);
        updateSlide(slide);
    };

    const onStoreImage = ({ imageUpload, cb }) => {
        storeSlideImage({ imageUpload, slide: slideState, cb: (image) => {
            setSlideState({ ...slideState, image });
            cb();
        }});
    };


    const updateImage = (image) =>{
        onUpdateSlide({ ...slideState, image });
    };
    

    return (
        <div className='c-quiz'>
            <div className='error-label'>
            {!isQuestionValid(slide) && t("createQuizIncomplete")}
            </div>
          
            <Row>
                <Col span={24}>
                    <Input
                        className={`c-quiz-item-input ${(!slideState?.title?.length ) && 'has-error'}`}
                        placeholder={t("createQuizAskQuestion")}
                        value={slideState.title}
                        onChange={e => onUpdateSlide({ ...slideState, title: e.target.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={16} className='c-quiz-item c-quiz-item__image'>
                    <ImageDropzone
                        image={slideState.image}
                        removeImage={() => removeSlideImage(slideState)}
                        storeImage={onStoreImage}
                        updateImage={updateImage}
                        title={t("createQuizAddPicture", {
                            value: Math.floor(APP_DEFAULTS.MAX_IMAGESIZE / 1024),
                        })}
                    />
                </Col>
                <Col span={8} className='c-quiz-item c-quiz-item__points'>
                    {![QUESTION_TYPE.INFO].includes(slide.type) && <InputUnit
                        unit={t("createQuizSeconds")}
                        value={slideState.seconds}
                        placeholder={t("createQuiz20Seconds")}
                        max={120}
                        onChange={(value) => onUpdateSlide({ ...slideState, seconds: parseInt(value || 20) })}
                    />}
                    {[QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.TRUE_FALSE].includes(slide.type) && <InputUnit
                        unit={t("createQuizPoints")}
                        value={slideState.points}
                        max={1000}
                        placeholder={t("createQuiz10Points")}
                        onChange={(value) => onUpdateSlide({ ...slideState, points: parseInt(value || 10) })}
                    />}
                </Col>
            </Row>
            {slide.type === QUESTION_TYPE.MULTIPLE && <Multiple slide={slideState} onUpdateSlide={onUpdateSlide} {...props} />}
            {slide.type === QUESTION_TYPE.QUESTIONAIRE && <Questionnaire slide={slideState} onUpdateSlide={onUpdateSlide} {...props} />}
            {slide.type === QUESTION_TYPE.TRUE_FALSE && <TrueFalse slide={slideState} onUpdateSlide={onUpdateSlide} {...props} />}
            {slide.type === QUESTION_TYPE.OPEN_QUESTION && <OpenQuestion slide={slideState} onUpdateSlide={onUpdateSlide} {...props} />}
            {slide.type === QUESTION_TYPE.INFO && <Info slide={slideState} onUpdateSlide={onUpdateSlide} {...props} />}
        </div>
    );
}

Slide.propTypes = {
  removeSlideImage: PropTypes.func,
  slide: PropTypes.shape({
    id: PropTypes.any,
    type: PropTypes.any
  }),
  storeSlideImage: PropTypes.func,
  t: PropTypes.func,
  updateSlide: PropTypes.func
};

export default withTranslation()(Slide);
