import React, { Component, useEffect } from 'react';
import { Form, Table, Popconfirm, Button, Modal, Input, Checkbox, Row, Col } from "antd";
import * as dayjs from 'dayjs';
import { InviteCodeModal } from './InviteCodeModal';

import './Users.scss';
import PropTypes from "prop-types";

export default class Users extends Component {
  state = {
    isUserModalVisible: false,
    isInviteCodeModalVisible: false,
    user: null,
    currentPage: 1,
    filter: {
      email: ''
    }
  }
  static propTypes = {
    users: PropTypes.array,
    user: PropTypes.object,
    fetchUsers: PropTypes.func,
    updateUser: PropTypes.func,
    deleteUser: PropTypes.func,
    createInviteCodes: PropTypes.func,
    t: PropTypes.func
  }

  componentDidMount() {
    this.props.fetchUsers(this.state.filter);
  }
  handleFilterChange = (filter) => {
    this.setState(
      { filter: Object.assign(this.state.filter, filter) },
      () => this.props.fetchUsers(this.state.filter)
    );
  }

  openUserModal = (user) => {
    // console.log(user);
    this.setState({ isUserModalVisible: true, user });
  }

  handleCancelUserModal = () => {
    this.setState({ user: null, isUserModalVisible: false });
  }

  handleCancelInviteCodeModal = () => {
    this.setState({ isInviteCodeModalVisible: false });
  }

  handleSaveUser = (values) => {
    const { user } = this.state;
    const { updateUser } = this.props;
    let updatedUser = Object.assign(user, values);
    // console.log(updatedUser);
    updateUser(updatedUser);
    this.handleCancelUserModal();
  }
  handleCreateInviteCodes = (values) => {
    const { createInviteCodes } = this.props;
    let params = { count: values.count };
    params.validUntil = dayjs().add(values.daysValid, 'd').toDate();
    createInviteCodes(params);
    this.handleCancelInviteCodeModal();
  }

  render() {
    const { users, deleteUser, t } = this.props;
    const authenticatedUser = this.props.user;

    const columns = [
      {
        title: t("usersUser"),
        dataIndex: 'email',
        key: 'email',
        width: '60%'
      },
      {
        title: t("usersAction"),
        key: 'action',
        width: '40%',
        render: (text, user) => (
          <>
            <Popconfirm
              title={t("usersDeleteConfirmation")}
              onConfirm={() => deleteUser(user)}
              okText={t("generalYes")}
              cancelText={t("generalNo")}
              disabled={user?.gid === authenticatedUser?.gid}
            >
              <Button type='link' disabled={user?.gid === authenticatedUser?.gid}>{t("usersDelete")}</Button>
            </Popconfirm> &nbsp;
            <Button type='link' onClick={() => this.openUserModal(user)}>{t("usersEdit")}</Button>
          </>
        ),
      }
    ];

    return <div className='c-categories'>
      <UserModal
        onCancel={this.handleCancelUserModal}
        onSaveUser={this.handleSaveUser}
        authenticatedUser={authenticatedUser}
        user={this.state.user}
        isUserModalVisible={this.state.isUserModalVisible}
        t={t}
      />
      <InviteCodeModal
        onCreateInviteCodes={this.handleCreateInviteCodes}
        onCancel={this.handleCancelInviteCodeModal}
        isInviteCodeModalVisible={this.state.isInviteCodeModalVisible}
        t={t}
      />
      <Row>
        <Col md={12}>
          <Input placeholder={t("usersSearchUser")} value={this.state.filter.email} onChange={e => this.handleFilterChange({ email: e.target.value })} />
        </Col>
        <Col md={2}></Col>
        <Col md={10}>
          <Button onClick={() => this.setState({ isInviteCodeModalVisible: true })}>{t("usersGenerateCode")}</Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={users}
        rowKey={'_id'}
        pagination={{
          onChange: (page, size) => {
            this.setState({ loading: true });
            this.setState({
              loading: false,
              currentPage: page,
            });
          },
          showTotal: (total, range) => `Total: ${total}`,
          current: this.state.currentPage,
          total: users.length,
          pageSize: 10
        }} />
    </div>;
  }
}

function UserModal(props) {
  const [form] = Form.useForm();
  let { isUserModalVisible, user, authenticatedUser, onSaveUser, onCancel, t } = props;

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  return <Modal
    title={t("usersEditUser")}
    visible={isUserModalVisible}
    onOk={() => form.submit()}
    onCancel={onCancel}
    cancelText={t("generalCancel")}
    okText={t("generalSave")}
    okButtonProps={{ disabled: user?.gid === authenticatedUser?.gid }}
  >
    <Form initialValues={user} form={form} onFinish={onSaveUser} name="control-hooks">
      <Form.Item
        name="firstName"
        label={t("usersFirstname")}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t("usersLastname")}

      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="username"
        label={t("usersUsername")}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("generalEmail")}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="isActivated"
        valuePropName="checked"
      >
        <Checkbox disabled={user?.gid === authenticatedUser?.gid}>{t("usersIsActivated")}</Checkbox>
      </Form.Item>
      <Form.Item
        name="isAdmin"
        valuePropName="checked"
      >
        <Checkbox disabled={user?.gid === authenticatedUser?.gid}>{t("usersIsAdmin")}</Checkbox>
      </Form.Item>
      <Form.Item
        name="isModerator"
        valuePropName="checked"
      >
        <Checkbox disabled={user?.gid === authenticatedUser?.gid}>{t("usersIsModerator")}</Checkbox>
      </Form.Item>
    </Form>
  </Modal>;
}

UserModal.propTypes = {
  authenticatedUser: PropTypes.object,
  isUserModalVisible: PropTypes.bool,
  user: PropTypes.object,
  onSaveUser: PropTypes.func,
  onCancel: PropTypes.func,
  t: PropTypes.func
};
