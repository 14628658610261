import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { fetchFavoriteQuizzes, fetchMyQuizzes, fetchQuizById, deleteQuiz } from '../../../store/actions/quiz';
import { fetchFolders, createFolder } from '../../../store/actions/folder';
import MyQuizzes from './MyQuizzes';
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMyQuizzes,
      fetchFavoriteQuizzes,
      fetchQuizById,
      fetchFolders,
      createFolder,
      deleteQuiz,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    myQuizzes: state.myQuizzes,
    favoriteQuizzes: state.favoriteQuizzes,
    myQuizzesCount: state.myQuizzesCount,
    favoriteQuizzesCount: state.favoriteQuizzesCount,
    currentQuiz: state.currentQuiz,
    canLoadMoreMyQuizzes: state.canLoadMoreMyQuizzes,
    canLoadMoreFavoriteQuizzes: state.canLoadMoreFavoriteQuizzes,
    folders: state.folders
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(MyQuizzes));
