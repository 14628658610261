import PropTypes from "prop-types";
import { QuestionCircleFilled } from '@ant-design/icons';
import { Col, Layout,  Row, Tooltip } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { v4 as uuid } from 'uuid';
import {  Modal } from '../../../components';
import { QUESTION_TYPE, ROUTES } from '../../../constants';
import { isQuizValid } from '../../../helper/quiz';
import {
  Info as InfoPreview, Multiple as MultiplePreview,
  TrueFalse as TrueFalsePreview
} from '../../Presentation/Quiz/components';
import Slide from './components/Slide';
import './CreateQuiz.scss';
import Sidebar from "./components/Sidebar";
import MetaData from "./components/MetaData";



const { Content } = Layout;

class CreateQuizPage extends Component {

  constructor(props) {
    super(props);

    const { path } = this.props.match;
    this.state = {
      isOpenModal: path === ROUTES.EDITOR.CREATE_QUIZ,
      isBuilder: true,
    };
    this.deleteSlide.bind(this);
  }

  componentDidMount() {
    const {
      cleanBuilder,
      fetchQuizForBuilder,
      match: { params }
    } = this.props;

    cleanBuilder();
    this.props.setBuilderType('builder');

    if (params && params.id) {
      fetchQuizForBuilder(params.id);
    }
  }
  canEdit() {
    const { user, quiz } = this.props;
    return !quiz.creatorId || quiz.creatorId === user._id;
  }
  handleSelectType = type => {
    const cb = (id) => {
      this.closeModal();

      setTimeout(() => {
        this.props.setBuilderStep(this.props.slides.length);
      }, 200);
    };

    const data = { type };
    switch (type) {
      case QUESTION_TYPE.TRUE_FALSE:
        data.seconds = 20;
        data.points = 100;
        data.answers = [
          { id: uuid(), title: 'Richtig' },
          { id: uuid(), title: 'Falsch' },
        ];
        break;
      case QUESTION_TYPE.MULTIPLE:
        data.points = 100;
      case QUESTION_TYPE.QUESTIONAIRE:
        data.seconds = 20;
        data.answers = [
          { id: uuid(), title: '' },
          { id: uuid(), title: '' },
          { id: uuid(), title: '' },
          { id: uuid(), title: '' },
        ];
        break;
      case QUESTION_TYPE.OPEN_QUESTION:
        data.seconds = 20;
        break;
      default: break;
    }
    this.props.addSlide({ ...data, cb });
  }

  onUpdateQuiz(quiz) {
    if (!isQuizValid({ ...this.props.quiz, ...quiz, questions: this.props.slides })) {
      quiz.isPublic = false;
    }
    this.props.updateQuiz(quiz);
  }
  handleSelectSlide = id => {
    this.props.setBuilderStep(this.props.slides.findIndex((slide) => slide.id === id) + 1);
    this.setState({ isBuilder: true });
    document.activeElement.blur();
  }
  // handleChange = (key, value) => this.onUpdateQuiz({ [key]: value })

  // onCategoryPress = (categoryId) => {
  //   let newCategoryIds = [];
  //   const { categoryIds } = this.props.quiz;

  //   const categoryIdIndex = Array.isArray(categoryIds) ? categoryIds.indexOf(categoryId) : -1;
  //   if (categoryIdIndex > -1) {
  //     newCategoryIds = [...categoryIds.slice(0, categoryIds.length - 1)];
  //   } else {
  //     newCategoryIds = [...categoryIds, categoryId];
  //   }

  //   this.handleChange('categoryIds', newCategoryIds);
  // };

  // handleChangeFolder = folderId => {
  //   const { quiz: { folderIds } } = this.props;

  //   if (folderIds.includes(folderId)) {
  //     this.onUpdateQuiz({ folderIds: folderIds.filter(i => i !== folderId) });
  //   } else {
  //     this.onUpdateQuiz({ folderIds: [...folderIds, folderId] });
  //   }
  // }

  // handleChangeTag = tag => {
  //   const { quiz: { tags } } = this.props;

  //   if (tags.includes(tag.title)) {
  //     this.onUpdateQuiz({ tags: tags.filter(i => i !== tag.title) });
  //   } else {
  //     this.onUpdateQuiz({ tags: [...tags, tag.title] });
  //   }
  // }

  // handleChangeSearch = search => {
  //   this.setState({ search });

  //   this.props.fetchTags({ search });
  // }

  handleSubmitQuiz = (needToCreateSession = false, redirect = null) => {
    if (!this.canEdit()) return;

    const { quiz, createQuizSession, location } = this.props;
    let cb;
    if (needToCreateSession) {
      cb = quiz => createQuizSession({ quizId: quiz._id, redirect: ROUTES.EDITOR.MY_QUIZZES });
      // cb = quiz => createQuizSession({ quizId: quiz._id, redirect: location.pathname });
    }
    if (quiz._id) {
      this.props.saveQuiz({ cb, isManualSaving: true });
    } else {
      this.props.createQuiz({ cb });
    }
    if (redirect) {
      this.props.history.push(redirect);
    }
  }

  openModal = () => this.setState({ isOpenModal: true })
  closeModal = () => this.setState({ isOpenModal: false })
  finishBuild = () => this.setState({ isBuilder: false })

  deleteSlide = (id) => {
    const { deleteSlide, setBuilderStep, builderStep } = this.props;
    deleteSlide({ id });
    setBuilderStep(builderStep - 1 || 1);

    setTimeout(() => {
      if (this.props.slides.length === 0) {
        this.openModal();
      }
    }, 200);
  }


  handleOpenPreview = () => {
    this.props.setBuilderType('preview');
    this.props.setBuilderStep(this.props.builderStep);
  }

  renderModal() {
    const { isOpenModal } = this.state;
    const { t } = this.props;

    const types = [
      { title: t("createQuizQuiz"), key: QUESTION_TYPE.MULTIPLE },
      { title: t("createQuizPoll"), key: QUESTION_TYPE.QUESTIONAIRE },
      { title: t("createQuizRightOrWrong"), key: QUESTION_TYPE.TRUE_FALSE },
      { title: t("createQuizOpenQuestion"), key: QUESTION_TYPE.OPEN_QUESTION },
      { title: t("createQuizInfo"), key: QUESTION_TYPE.INFO },
    ];

    const tooltips = {
      [QUESTION_TYPE.MULTIPLE]: t('tooltipQuiz'),
      [QUESTION_TYPE.TRUE_FALSE]: t('tooltipTrueFalse'),
      [QUESTION_TYPE.QUESTIONAIRE]: t('tooltipSurvey'),
      [QUESTION_TYPE.OPEN_QUESTION]: t('tooltipOpenQuestion'),
      [QUESTION_TYPE.INFO]: t('tooltipInfo'),
    };

    return <Modal
      onCancel={this.closeModal}
      visible={isOpenModal}
      footer={null}
      width='70%'
      style={{ left: '8%' }}
    >
      <Row gutter={[75, 30]}>
        {types.map(type => <Col span={12} key={type.key} onClick={() => this.handleSelectType(type.key)}>
          <div className='c-createQuiz-modal-card'>
            <Tooltip placement='top' title={tooltips[type.key]}>
              <QuestionCircleFilled className='c-createQuiz-modal-icon' />
            </Tooltip>
            {type.title}
          </div>
        </Col>
        )}
      </Row>
    </Modal>;
  }

  renderPreview = question => {
    const props = { question };

    switch (question.type) {
      case QUESTION_TYPE.MULTIPLE:
      case QUESTION_TYPE.QUESTIONAIRE:
        return <MultiplePreview {...props} />;
      case QUESTION_TYPE.TRUE_FALSE:
        return <TrueFalsePreview {...props} />;
      case QUESTION_TYPE.OPEN_QUESTION:
      case QUESTION_TYPE.INFO:
        return <InfoPreview {...props} />;
      default:
        return null;
    }
  }
  onStoreImage = (payload) => {
    this.props.storeQuizImage(payload);
  }
  updateImage(image) {
    this.onUpdateQuiz({ ...this.props.quiz, image });
  }

  render() {
    const { slides, builderStep, builderType } = this.props;
    const { isBuilder } = this.state;

    const activeSlide = slides[builderStep - 1];

    const canEdit = this.canEdit();


    return (
      canEdit && <div className='c-createQuiz'>
        {this.renderModal()}
        {builderType === 'preview' && !!slides.length && this.renderPreview(activeSlide)}
        {builderType === 'builder' && <Layout>
          <Sidebar
            slides={slides}
            quiz={this.props.quiz}
            finishBuild={this.finishBuild}
            openModal={this.openModal}
            handleOpenPreview={this.handleOpenPreview}
            builderStep={builderStep}
            deleteSlide={this.deleteSlide.bind(this)}
            handleSelectSlide={this.handleSelectSlide}
            setSlides={this.props.setSlides}
            saveQuiz={this.props.saveQuiz}
          />
          <Content className='c-createQuiz-content'>
            {isBuilder
              ? (activeSlide && <div>
                <Slide
                  slide={activeSlide}
                  updateSlide={this.props.updateSlide}
                  storeSlideImage={this.props.storeSlideImage}
                  storeQuizImage={this.props.storeQuizImage}
                  removeSlideImage={this.props.removeSlideImage}
                  removeQuizImage={this.props.removeQuizImage}
                  slides={this.props.slides}
                  canEdit={this.canEdit()}
                />
              </div>)
              // : this.renderQuizMetadata()
              : <MetaData
                removeQuizImage={this.props.removeQuizImage}
                updateImage={this.updateImage.bind(this)}
                storeQuizImage={this.props.storeQuizImage}
                onUpdateQuiz={this.onUpdateQuiz.bind(this)}
                onSubmitQuiz={this.handleSubmitQuiz.bind(this)}
              />
            }
          </Content>
        </Layout>}
      </div>
    );
  }



}

CreateQuizPage.propTypes = {
  addSlide: PropTypes.func,
  builderStep: PropTypes.number,
  builderType: PropTypes.string,
  cleanBuilder: PropTypes.func,
  createQuiz: PropTypes.func,
  createQuizSession: PropTypes.func,
  deleteSlide: PropTypes.func,
  fetchQuizForBuilder: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  quiz: PropTypes.object,
  removeQuizImage: PropTypes.func,
  removeSlideImage: PropTypes.any,
  saveQuiz: PropTypes.func,
  setBuilderStep: PropTypes.func,
  setBuilderType: PropTypes.func,
  setSlides: PropTypes.func,
  slides: PropTypes.array,
  storeQuizImage: PropTypes.func,
  storeSlideImage: PropTypes.func,
  updateQuiz: PropTypes.func,
  updateSlide: PropTypes.any,
  user: PropTypes.object,
};

export default withRouter(CreateQuizPage);
