import { Timer } from "../../../../assets/svg";
import { buildGradient } from "./helper";

export default function Countdown(props) {
  let {seconds, secondsTotal } = props;
  const backgroundImage = buildGradient(seconds, secondsTotal);
    return <li className='quiz__settingsItem'>
      <Timer style={{ position: 'relative', zIndex: 5 }} />
      <div
        className='quiz__settingsItemTimerBG'
        style={{
          backgroundImage
        }} />
      <span>{seconds !== undefined ? secondsTotal - seconds : secondsTotal}</span>
    </li>;
  }
  
