import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import Dropzone from "../Dropzone";
import { IMAGE_AVAILABLE_SIZES } from '../../constants';
import { icons } from '../../resources';
import { Copyright } from './Copyright';
import "./ImageDropzone.scss";
import { Modal, Input, Checkbox } from "antd";
import { t } from "i18next";

export default function ImageDropzone(props) {
  let { image, removeImage, storeImage, title, updateImage } = props;
  let hasImage = image && image?.[IMAGE_AVAILABLE_SIZES.EDITOR] && image[IMAGE_AVAILABLE_SIZES.EDITOR].length > 0;
  let [copyrightModalVisible, setCopyrightModalVisible] = useState(false);
  let [copyright, setCopyright] = useState("");
  let [noCopyrightRequired, setNoCopytightRequired] = useState(false);
  let [editCopyrightModeEnabled, setEditCopyrightModeEnabled] = useState(false);

  const onImageStored = () => {
    setCopyrightModalVisible(true);
  };

  const onStoreImage = (imageUpload) => {
    storeImage({imageUpload, cb: onImageStored});
  };

  const onSaveCopyrightClick = () => {
    updateImage({...image, copyright, noCopyrightRequired});
    setCopyrightModalVisible(false);
    setEditCopyrightModeEnabled(false);
  };
  const onCancelCopyrightClick = () => {
    if (!editCopyrightModeEnabled) {
      removeImage();
    }
    setCopyrightModalVisible(false);
    setEditCopyrightModeEnabled(false);
  };

  const onCopyrightRequiredCheckboxChange = (e) => {
    setNoCopytightRequired(e.target.checked);
  };

  const onEditCopyrightClick = () => {
    setEditCopyrightModeEnabled(true);
    setCopyrightModalVisible(true);
  };

  useEffect(() => {
    setCopyright(image?.copyright || "");
    setNoCopytightRequired(image?.noCopyrightRequired || false);
  }, [image]);

  return (
    <div className="image-dropzone">
      <Modal
        title={t("copyrightModalTitle")}
        visible={copyrightModalVisible}
        onOk={onSaveCopyrightClick}
        okButtonProps={{ disabled: !noCopyrightRequired && !copyright }}
        onCancel={onCancelCopyrightClick}
        cancelText={t("generalCancel")}
        okText={t("generalSave")}
        maskClosable={false}
      >
        <Checkbox onChange={onCopyrightRequiredCheckboxChange} checked={noCopyrightRequired}>{t("noCopyrightRequired")}</Checkbox>
        <Input disabled={noCopyrightRequired} style={{ marginTop: 20 }} value={copyright} onChange={e => setCopyright(e.target.value)} />
      </Modal>
      {!hasImage && <Dropzone
        onAddImage={onStoreImage}
        title={title}></Dropzone>}
      {hasImage && <div className='image'>
        <div className="image__container">
          <img
            className='c-quiz-item-icon'
            src={icons.iconCloseBlack} alt=""
            onClick={() => removeImage()}
          />
          <img src={image?.[IMAGE_AVAILABLE_SIZES.EDITOR]} alt="" />
          <Copyright image={image} isEditable={true} onEditClick={onEditCopyrightClick} />
        </div>
      </div>}
    </div>
  );
}

ImageDropzone.propTypes = {
  image: PropTypes.any,
  removeImage: PropTypes.func,
  storeImage: PropTypes.func,
  updateImage: PropTypes.func,
  title: PropTypes.string
};
