import PropTypes from "prop-types";
import React from 'react';
import { EditOutlined } from "@ant-design/icons";
import "./Copyright.scss";

export const Copyright = ({ image, isEditable, onEditClick = () => {} }) => {
  if ((!image?.copyright || image?.noCopyrightRequired) && !isEditable) {
    return null;
  }

  return (
      <div className='copyright'>© {image?.noCopyrightRequired ? "" : (image?.copyright || "")} {isEditable && <span className="edit-container" onClick={onEditClick}><EditOutlined /></span>}</div>
  );
};
Copyright.propTypes = {
  image: PropTypes.shape({
    copyright: PropTypes.string
  }),
  isEditable: PropTypes.bool,
  onEditClick: PropTypes.func,
};
