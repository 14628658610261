import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const DeletedUser = ({ t, recoverUser, logout }) => {

  const user = useSelector(state => state.user);

  const onLoginPress = () => {
    logout();
  };

  const onRequestRestorePress = () => {
    if (user) {
      recoverUser(user._id, () => {
        logout();
      });
    }
  };

  return (
    <div>
      <Row justify={'center'}>
        <Col>
          <Typography.Title style={{ color: 'white' }}>{t('deletedUser')}</Typography.Title>
        </Col>
      </Row>
      <Row  justify={'center'}>
        <Button onClick={onLoginPress}>{t('loginAnotherUser')}</Button>
        <Button style={{ marginLeft: 20 }} onClick={onRequestRestorePress}>{t('requestRecovery')}</Button>
      </Row>
    </div>
  );
};

DeletedUser.propTypes = {
  t: PropTypes.func,
  recoverUser: PropTypes.func,
  logout: PropTypes.func,
};

export default DeletedUser;
