import {
  takeEvery, put, call, select, all, debounce
} from 'redux-saga/effects';
import {  notification } from 'antd';
import * as types from '../types';
import { QuizService } from '../../services';
import history from '../../history';
import { waitFor } from './general';
import { ROUTES } from '../../constants';
import AzureStorage from "../../services/azure_storage";
import ImageService from '../../services/image';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* createQuiz(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { quiz, slides, token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));
    

    const { cb } = action.payload;

    const data = {
      ...quiz,
      questions: slides,
    };

    const response = yield call([
      quizService,
      quizService.createQuiz,
    ], data);

    if (cb) {
      cb(response);
    } else {
      history.push(ROUTES.EDITOR.MY_QUIZZES);
    }

    yield call(fetchQuizForBuilder, { payload: response._id });


  } catch (error) {
    notification.error({
      message: 'Fehler beim Erstellen des Quizzes',
      description: error.message,
      maxCount: 1
    });
    console.error('createQuiz error', error);
  }
}

function* uploadImage(imageUpload, id) {
  const { config } = yield select((state) => state);
  // console.log(imageUpload);

  const imageService = yield call(() => new ImageService(imageUpload));
  let resizedImages = yield call([imageService, imageService.getResizedImages], id);
  let image = null;

  const azureStorage = yield call(() => new AzureStorage(config));
  try {
    yield all(resizedImages.map(resizedImage => {
      return call([
        azureStorage,
        azureStorage.storeFile,
      ], resizedImage.image, resizedImage.type, resizedImage.filename);
    }));
  } catch (error) {
    notification.error({
      message: 'Fehler beim Speichern des Bildes',
      description: error.message,
      maxCount: 1
    });
    console.error('~/projects/di.cl.goethe-quiz/frontend/src/store/sagas quiz_builder.js', 'error', error);
  }

  for (let i = 0; i < resizedImages.length; i++) {
    let resizedImage = resizedImages[i];
    image = image || {};
    image[resizedImage.size] = yield call([azureStorage, azureStorage.getUrl], resizedImage.filename);
  }
  return image;
}

function* storeSlideImage(action) {

  yield call(waitFor, state => state.slides.length !== 0);
  const { slides, quiz, user } = yield select((state) => state);

  if (quiz.creatorId !== undefined && quiz.creatorId !== user._id) return;

  yield put({ type: types.SET_SHOWING_SPINNER, payload: true });

  let { imageUpload, slide, cb = () => {} } = action.payload;

  let image = yield call(uploadImage, imageUpload, slide.id);

  for (let i = 0; i < slides.length; i++) {
    if (slides[i].id === slide.id) {
      slides[i].image = image;
    }
  }

  yield put({ type: types.SET_SLIDES, payload: slides });
  yield put({ type: types.UPDATE_QUIZ, payload: {} });
  yield call(cb, image);

  yield put({ type: types.SET_SHOWING_SPINNER, payload: false });
}

function* storeQuizImage(action) {
  const { quiz, user } = yield select((state) => state);
  // console.log(action);
  if (quiz.creatorId !== user._id) return;

  yield put({ type: types.SET_SHOWING_SPINNER, payload: true });

  let { imageUpload, cb = () => {} } = action.payload;

  let image = yield call(uploadImage, imageUpload, quiz._id);

  quiz.image = image;

  yield put({ type: types.SET_QUIZ, payload: quiz });
  yield put({ type: types.UPDATE_QUIZ, payload: {} });
  yield call(cb);

  yield put({ type: types.SET_SHOWING_SPINNER, payload: false });
}

function* removeImage(image) {
  const { config } = yield select((state) => state);
  const azureStorage = yield call(() => new AzureStorage(config));
  try {
    if (image) {
      const { copyright, noCopyrightRequired, ...imageUrls } = image;
      yield all(Object.keys(imageUrls).map(size => {
        return call([
          azureStorage,
          azureStorage.removeFile,
        ], image[size]);
      }));
      // console.log(results);
    }
  } catch (error) {
    notification.error({
      message: 'Fehler beim Löschen des Bildes',
      description: error.message,
      maxCount: 1
    });
    console.error('~/projects/di.cl.goethe-quiz/frontend/src/store/sagas quiz_builder.js', 'error', error);
  }
}

function* removeSlideImage(action) {
  const { slides, quiz, user } = yield select((state) => state);
  let slide = action.payload;
  if (quiz.creatorId !== user._id) return;

  // yield call(removeImage, slide.image);

  slide.image = null;
  for (let i = 0; i < slides.length; i++) {
    if (slides[i].id === slide.id) {
      slides[i] = slide;
    }
  }

  yield put({ type: types.SET_SLIDES, payload: slides });
  yield put({ type: types.UPDATE_QUIZ, payload: {} });
}


function* removeQuizImage(action) {
  const { quiz, user } = yield select((state) => state);
  if (quiz.creatorId !== user._id) return;

  yield call(removeImage, quiz.image);

  quiz.image = null;

  yield put({ type: types.SET_QUIZ, payload: quiz });
  yield put({ type: types.UPDATE_QUIZ, payload: {} });
}

function* clearSaving() {
  yield call(delay, 2000);
  yield put({
    type: types.SET_SAVING,
    payload: false
  });
};


function* saveQuiz(action) {
  try {
    yield put({
      type: types.SET_SAVING,
      payload: true
    });
    yield call(waitFor, state => state.slides.length !== 0);
    yield call(waitFor, state => state.token !== null);
    const { quiz, slides, token } = yield select((state) => state);

    const quizService = yield call(() => new QuizService(token));

    const { cb, isManualSaving } = action.payload;

    const data = {
      ...quiz,
      questions: slides,
    };

    if (data._id) {
      const id = data._id;
      delete data._id;
      yield call([
        quizService,
        quizService.saveQuiz,
      ], id, data);

      if (isManualSaving) {
        if (cb) {
          cb({ _id: id });
        }
        notification.info({
          message: 'Das Quiz wurde gespeichert.',
          maxCount: 1

        });
      }
    }else{
      yield put({ type: types.CREATE_QUIZ, payload: { cb: (quiz) => {
        history.push(ROUTES.EDITOR.EDIT_QUIZ.replace(':id', quiz._id));
      }}});
    }
    yield put({ type: types.CLEAR_SAVING });


  } catch (error) {
    yield put({ type: types.CLEAR_SAVING });

    notification.error({
      message: 'Fehler beim Speichern',
      description: error.message,
      maxCount: 1
    });
    console.error('saveQuiz error', error);
  }
}

function* fetchQuizForBuilder(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const response = yield call([
      quizService,
      quizService.getQuiz,
    ], action.payload);

    const {
      title,
      image,
      categoryIds = [],
      level,
      tags = [],
      isPublic = false,
      questions = [],
      folderIds = [],
      creatorId,
      _id
    } = response.result;

    const quiz = {
      title,
      image,
      categoryIds,
      level,
      tags,
      isPublic,
      folderIds,
      creatorId,
      _id
    };

    yield put({
      type: types.UPDATE_QUIZ,
      payload: quiz
    });

    yield put({
      type: types.SET_SLIDES,
      payload: questions
    });

  } catch (error) {
    notification.error({
      message: 'Fehler beim Laden',
      description: error.message,
      maxCount: 1
    });

    console.error('fetchQuizForBuilder error', error);
  }
}

function* addSlide(action) {
  try {
    const { slides, quiz, user } = yield select((state) => state);
    if (quiz.creatorId !== user._id) return;

    if (slides.length === 1) {
      yield call(createQuiz, { payload: { cb: ({ _id }) => history.push(ROUTES.EDITOR.EDIT_QUIZ.replace(':id', _id)) } });
    } else {
      yield call(saveQuiz, { payload: {} });
    }
  } catch (error) {
    notification.error({
      message: 'Fehler beim Speichern',
      description: error.message,
      maxCount: 1
    });

    console.error('addSlide error', error);
  }
}

export function* quizBuilderSagas() {
  yield takeEvery(types.CREATE_QUIZ, createQuiz);
  yield takeEvery(types.UPDATE_QUIZ, saveQuiz);
  yield debounce(500, types.UPDATE_QUIZ, saveQuiz);
  yield takeEvery(types.SAVE_QUIZ, saveQuiz);
  yield takeEvery(types.ADD_SLIDE, addSlide);
  yield debounce(500, types.UPDATE_SLIDE, saveQuiz);
  yield takeEvery(types.DELETE_SLIDE, saveQuiz);
  yield takeEvery(types.STORE_SLIDE_IMAGE, storeSlideImage);
  yield takeEvery(types.STORE_QUIZ_IMAGE, storeQuizImage);
  yield takeEvery(types.REMOVE_SLIDE_IMAGE, removeSlideImage);
  yield takeEvery(types.REMOVE_QUIZ_IMAGE, removeQuizImage);
  yield takeEvery(types.FETCH_QUIZ_FOR_BUILDER, fetchQuizForBuilder);
  yield takeEvery(types.CLEAR_SAVING, clearSaving);
}

export default quizBuilderSagas;
