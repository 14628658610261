import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import cn from 'classnames';
import { Button } from '../../../components';

function MobileQuestionMultiple({  onAnswerQuestion, t }) {
  const [openAnswer, setOpenAnswer] = useState('');
  const [openAnswerCheckedIn, setOpenAnswerCheckedIn] = useState(false);

  const onAnswerOpenQuestion = () => {
    setOpenAnswerCheckedIn(true);
    onAnswerQuestion(openAnswer);
  };
  const onAnswerOpenQuestionEdit = () => {
    setOpenAnswerCheckedIn(false);
  };

  return (
    <>
      <div className='quizMobile__form'>
        <div className='quizMobile__openAnswer'>
          <input
            maxLength="25"
            value={openAnswer}
            placeholder={t("mobileQuestionViewMaxChars")}
            onChange={e => setOpenAnswer(e.target.value)}
            disabled={openAnswerCheckedIn}
          />
        </div>
        <div className='quizMobile__formButton'>
          {openAnswerCheckedIn ?
            <Button block onClick={() => onAnswerOpenQuestionEdit()}>{t("mobileQuestionViewEdit")}</Button> :
            <Button block onClick={() => onAnswerOpenQuestion()}>{t("mobileQuestionViewSave")}</Button>
          }
        </div>
      </div>
    </>
  );
}

MobileQuestionMultiple.propTypes = {
  onAnswerQuestion: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation()(MobileQuestionMultiple);
