import React, { Component } from 'react';
import { Table, Popconfirm, Button, Modal, Input, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import './Tags.scss';

export default class Tags extends Component {

  static propTypes = {
    tags: PropTypes.array,
    fetchTags: PropTypes.func,
    createTag: PropTypes.func,
    deleteTag: PropTypes.func,
  }
  state = {
    isModalVisible: false,
    modalTitle: '',
    filter: {
      title: ''
    }
  }

  componentDidMount() {
    this.props.fetchTags(this.state.filter);
  }

  openModal = () => {
    this.setState({ isModalVisible: true });
  }

  handleCancel = () => {
    this.setState({ modalTitle: '', isModalVisible: false });
  }

  handleOk = () => {
    const { modalTitle } = this.state;
    const { createTag } = this.props;

    createTag({ title: modalTitle });
    this.handleCancel();
  }

  handleTitleChange = (value) => {
    this.setState({ modalTitle: value });
  }
  handleFilterChange = (filter) => {
    this.setState(
      { filter: Object.assign(this.state.filter, filter) },
      () => this.props.fetchTags(this.state.filter)
    );
  }
  render() {
    const { tags, deleteTag, t } = this.props;
    const { isModalVisible, modalTitle } = this.state;

    const columns = [
      {
        title: t("tagsTag"),
        dataIndex: 'title',
        key: 'title',
        width: '60%'
      },
      {
        title: t("tagsAction"),
        key: 'action',
        width: '40%',
        render: (text, record) => (
          <Popconfirm
            title={t("tagsDeleteConfirmation")}
            onConfirm={() => deleteTag(record._id)}
            okText={t("generalYes")}
            cancelText={t("generalNo")}
          >
            <Button type='link'>{t("tagsDelete")}</Button>
          </Popconfirm>
        ),
      }
    ];

    return <div className='c-tags'>
      <Modal
        title={t("tagsNewTag")}
        visible={isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        cancelText={t("generalCancel")}
        okText={t("generalOk")}
      >
        <Input value={modalTitle} onChange={e => this.handleTitleChange(e.target.value)} />
      </Modal>
      <Row>
      <Col md={12}>
          <Input placeholder={t("tagsSearchTags")} value={this.state.filter.title} onChange={e => this.handleFilterChange({ title: e.target.value })} />
        </Col>
        <Col md={2}></Col>
        <Col md={10}>
          <Button type='primary' onClick={this.openModal}>{t("tagsNew")}</Button>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
        <Table columns={columns} dataSource={tags} rowKey={'_id'} />
        </Col>
      </Row>


    </div>;
  }
}
