import React from 'react';
import cn from 'classnames';
import './Button.scss';
import PropTypes from "prop-types";

function Button (props) {
  const { children, type = 'default', block = false, className, ...rest } = props;

  return (
    <button
      {...rest}
      className={cn({
      'c-button': true,
      'c-button_primary': type === 'default',
      'c-button_secondary': type === 'secondary',
      'c-button_block': block
    }, className)}>
      { children }
    </button>
  );
}

Button.propTypes = {
  children:PropTypes.any,
  htmlType:PropTypes.string,
  block:PropTypes.bool,
  type:PropTypes.string,
  className:PropTypes.string,
};

export default Button;