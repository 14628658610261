/** CATEGORIES TYPES */
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

/** QUIZZES TYPES */
export const FETCH_QUIZZES = 'FETCH_QUIZZES';
export const FETCH_LAST_QUIZZES = 'FETCH_LAST_QUIZZES';
export const FETCH_MY_QUIZZES = 'FETCH_MY_QUIZZES';
export const FETCH_FAVORITE_QUIZZES = 'FETCH_FAVORITE_QUIZZES';
export const SET_QUIZZES = 'SET_QUIZZES';
export const SET_LAST_QUIZZES = 'SET_LAST_QUIZZES';
export const SET_MY_QUIZZES = 'SET_MY_QUIZZES';
export const SET_FAVORITE_QUIZZES = 'SET_FAVORITE_QUIZZES';
export const SET_QUIZZES_COUNT = 'SET_QUIZZES_COUNT';
export const SET_LAST_QUIZZES_COUNT = 'SET_LAST_QUIZZES_COUNT';
export const SET_MY_QUIZZES_COUNT= 'SET_MY_QUIZZES_COUNT';
export const SET_FAVORITE_QUIZZES_COUNT = 'SET_FAVORITE_QUIZZES_COUNT';
export const SET_MORE_QUIZZES = 'SET_MORE_QUIZZES';
export const SET_MORE_LAST_QUIZZES = 'SET_MORE_LAST_QUIZZES';
export const SET_MORE_MY_QUIZZES = 'SET_MORE_MY_QUIZZES';
export const SET_MORE_FAVORITE_QUIZZES = 'SET_MORE_FAVORITE_QUIZZES';
export const SET_CAN_LOAD_MORE_QUIZZES = 'SET_CAN_LOAD_MORE_QUIZZES';
export const SET_CAN_LOAD_MORE_LAST_QUIZZES = 'SET_CAN_LOAD_MORE_LAST_QUIZZES';
export const SET_CAN_LOAD_MORE_MY_QUIZZES = 'SET_CAN_LOAD_MORE_MY_QUIZZES';
export const SET_CAN_LOAD_MORE_FAVORITE_QUIZZES = 'SET_CAN_LOAD_MORE_FAVORITE_QUIZZES';
export const FAVORITE_QUIZ = 'FAVORITE_QUIZ';
export const UNFAVORITE_QUIZ = 'UNFAVORITE_QUIZ';
export const PATCH_QUIZ_BY_ID = 'PATCH_QUIZ_BY_ID';
export const FETCH_QUIZ_BY_ID = 'FETCH_QUIZ_BY_ID';
export const SET_QUIZ_BY_ID = 'SET_QUIZ_BY_ID';
export const COPY_QUIZ = 'COPY_QUIZ';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const CREATE_QUIZ_SESSION = 'CREATE_QUIZ_SESSION';


/** QUIZ BUILDER */
export const ADD_SLIDE = 'ADD_SLIDE';
export const UPDATE_SLIDE = 'UPDATE_SLIDE';
export const STORE_SLIDE_IMAGE = 'STORE_SLIDE_IMAGE';
export const REMOVE_SLIDE_IMAGE = 'REMOVE_SLIDE_IMAGE';
export const DELETE_SLIDE = 'DELETE_SLIDE';
export const SET_SLIDES = 'SET_SLIDES';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const STORE_QUIZ_IMAGE = 'STORE_QUIZ_IMAGE';
export const REMOVE_QUIZ_IMAGE = 'REMOVE_QUIZ_IMAGE';
export const CLEAN_BUILDER = 'CLEAN_BUILDER';
export const SET_QUIZ = 'SET_QUIZ';
export const FETCH_QUIZ_FOR_BUILDER = 'FETCH_QUIZ_FOR_BUILDER';
export const SAVE_QUIZ = 'SAVE_QUIZ';
export const SET_SHOWING_SPINNER = 'SET_SHOWING_SPINNER';

/** FOLDERS TYPES */
export const FETCH_FOLDERS = 'FETCH_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const SET_BUILDER_TYPE = 'SET_BUILDER_TYPE';
export const SET_BUILDER_STEP = 'SET_BUILDER_STEP';
export const SET_SAVING = 'SET_SAVING';
export const CLEAR_SAVING = 'CLEAR_SAVING';

/** TAGS TYPES */
export const FETCH_TAGS = 'FETCH_TAGS';
export const SET_TAGS = 'SET_TAGS';
export const DELETE_TAG = 'DELETE_TAG';
export const CREATE_TAG = 'CREATE_TAG';

/** USER TYPES */
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOGING_IN = 'SET_LOGING_IN';
export const INIT_USER = 'INIT_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const SET_USERS = 'SET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USER = 'SET_USER';
export const CREATE_INVITE_CODES = 'CREATE_INVITE_CODES';
export const CHECK_INVITE_CODE = 'CHECK_INVITE_CODE';
export const RECOVER_USER = 'RECOVER_USER';

/** QUIZ PRESENTATION */
export const FETCH_QUIZ_FOR_PRESENTATION = 'FETCH_QUIZ_FOR_PRESENTATION';
export const SET_QUIZ_FOR_PRESENTATION = 'SET_QUIZ_FOR_PRESENTATION';
export const SET_PRESENTATION_STEP = 'SET_PRESENTATION_STEP';
export const SET_PRESENTATION_TYPE = 'SET_PRESENTATION_TYPE';
export const FINISH_QUESTION = 'FINISH_QUESTION';
export const START_QUIZ_SESSION = 'START_QUIZ_SESSION';
export const DOWNLOAD_QUIZ_RESULTS = 'DOWNLOAD_QUIZ_RESULTS';

/** Config types */
export const SET_CONFIG='SET_CONFIG';
export const LOAD_CONFIG='LOAD_CONFIG';

/** Mobile Types */
export const INIT_MOBILE_QUIZ = 'INIT_MOBILE_QUIZ';
export const SET_SOCKET_CONNECTED = 'SET_SOCKET_CONNECTED';
export const FETCH_QUIZ_SESSION = 'FETCH_QUIZ_SESSION';
export const FETCH_QUIZ_SESSION_BY_CODE = 'FETCH_QUIZ_SESSION_BY_CODE';
export const SET_QUIZ_SESSION = 'SET_QUIZ_SESSION';
export const SET_QUIZ_SESSION_REDIRECT = 'SET_QUIZ_SESSION_REDIRECT';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';

export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_TOTAL_RESULT = 'SET_TOTAL_RESULT';
export const SET_TOTAL_PARTICIPANT_RESULT = 'SET_TOTAL_PARTICIPANT_RESULT';
export const SET_MOBILE_STEP = 'SET_MOBILE_STEP';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const INIT_MOBILE_WEB_SOCKETS_CHANNEL = 'INIT_MOBILE_WEB_SOCKETS_CHANNEL';
export const MOBILE_WEB_SOCKET_HANDLE_FINISH_QUESTION = 'MOBILE_WEB_SOCKET_HANDLE_FINISH_QUESTION';
export const MOBILE_WEB_SOCKET_HANDLE_FETCH_QUESTION = 'MOBILE_WEB_SOCKET_HANDLE_FETCH_QUESTION';
export const MOBILE_WEB_SOCKET_HANDLE_FETCH_ANSWER = 'MOBILE_WEB_SOCKET_HANDLE_FETCH_ANSWER';
export const MOBILE_WEB_SOCKET_HANDLE_FINISH_QUIZ = 'MOBILE_WEB_SOCKET_HANDLE_FINISH_QUIZ';
export const MOBILE_WEB_SOCKET_HANDLE_FINAL_RESULT = 'MOBILE_WEB_SOCKET_HANDLE_FINAL_RESULT';
export const MOBILE_WEB_SOCKET_HANDLE_FETCH_RESULT = 'MOBILE_WEB_SOCKET_HANDLE_FETCH_RESULT';
export const MOBILE_COMMIT_ANSWER = 'MOBILE_COMMIT_ANSWER';
export const MOBILE_SEND_ANSWER = 'MOBILE_SEND_ANSWER';
export const MOBILE_RESTART_PARTICIPANT_SESSION = 'MOBILE_RESTART_PARTICIPANT_SESSION';
export const MOBILE_FETCH_PARTICIPANT_RESULT = 'MOBILE_FETCH_PARTICIPANT_RESULT';
export const MOBILE_SET_CURRENT_ANSWER_RESULT = 'MOBILE_SET_CURRENT_ANSWER_RESULT';
export const MOBILE_SET_WAIT_FOR_QUESTION_RESULT = 'MOBILE_SET_WAIT_FOR_QUESTION_RESULT';

export const INIT_PRESENTATION_WEB_SOCKETS_CHANNEL = 'INIT_PRESENTATION_WEB_SOCKETS_CHANNEL';
export const PRESENTATION_WEB_SOCKET_HANDLE_ADD_PARTICIPANT = 'PRESENTATION_WEB_SOCKET_HANDLE_ADD_PARTICIPANT';
export const PRESENTATION_WEB_SOCKET_HANDLE_FINISH_QUESTION = 'PRESENTATION_WEB_SOCKET_HANDLE_FINISH_QUESTION';
export const PRESENTATION_WEB_SOCKET_HANDLE_FETCH_RESULT = 'PRESENTATION_WEB_SOCKET_HANDLE_FETCH_RESULT';
export const PRESENTATION_WEB_SOCKET_HANDLE_TICK_SECONDS = 'PRESENTATION_WEB_SOCKET_HANDLE_TICK_SECONDS';
export const PRESENTATION_WEB_SOCKET_HANDLE_RECEIVED_ANSWER = 'PRESENTATION_WEB_SOCKET_HANDLE_RECEIVED_ANSWER';
export const PRESENTATION_SET_PARTICIPANTS = 'PRESENTATION_SET_PARTICIPANTS';
export const PRESENTATION_GET_QUESTION_RESULT = 'PRESENTATION_GET_QUESTION_RESULT';
export const PRESENTATION_FETCH_PRESENTER_RESULT = 'PRESENTATION_FETCH_PRESENTER_RESULT';
export const PRESENTATION_SET_QUESTION_SECONDS = 'PRESENTATION_SET_QUESTION_SECONDS';
export const PRESENTATION_SET_FINAL_RESULT = 'PRESENTATION_SET_FINAL_RESULT';
export const PRESENTATION_SET_TOTAL_ANSWERED_COUNT = 'PRESENTATION_SET_TOTAL_ANSWERED_COUNT';
export const PRESENTATION_SET_PRESENTATION_RESULT = 'PRESENTATION_SET_PRESENTATION_RESULT';

