import { notification } from "antd";
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { CategoryService } from '../../services';
import * as types from '../types';
import { waitFor } from './general';

function* fetchCategories(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const categoryService = yield call(() => new CategoryService(token));

    const categories = yield call([
      categoryService,
      categoryService.getCategories,
    ], action?.payload);

    yield put({
      type: types.SET_CATEGORIES,
      payload: categories.result,
    });

  } catch (error) {
    console.error('fetchCategories error', error);
  }
}

function* createCategory(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const categoryService = yield call(() => new CategoryService(token));

    const result = yield call([
      categoryService,
      categoryService.createCategory,
    ], action.payload);

    if (result.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield call(fetchCategories);
    }
  } catch (error) {
    notification.error({message:error.message});
    console.error('createCategory error', error);
  }
}

function* deleteCategory(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { categories, token } = yield select((state) => state);
    const categoryService = yield call(() => new CategoryService(token));

    const result = yield call([
      categoryService,
      categoryService.deleteCategory,
    ], action.payload);

    if (result.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield put({
        type: types.SET_CATEGORIES,
        payload: categories.filter(i => i._id !== action.payload),
      });
    }

  } catch (error) {
    notification.error({message:error.message});
    console.error('deleteCategory error', error);
  }
}

export function* categoriesSagas() {
  yield takeEvery(types.FETCH_CATEGORIES, fetchCategories);
  yield takeEvery(types.CREATE_CATEGORY, createCategory);
  yield takeEvery(types.DELETE_CATEGORY, deleteCategory);
}

export default categoriesSagas;
