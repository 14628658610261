import { Layout, Menu } from 'antd';
import cn from "classnames";
import PropTypes from "prop-types";
import React from 'react';
import { Route } from 'react-router-dom';
import { Header } from '../../components';
import { ROUTES } from '../../constants';
import history from '../../history';
import logo from '../../resources/images/logo.png';
import './AdminRoute.scss';
import { withTranslation } from 'react-i18next';


const { Content, Sider } = Layout;

function AdminRoute({ component: Component, path, t, ...rest }) {
  const header = [
    {
      component: <img src={logo} alt="Logo" className="logo" />,
      cb: () => history.push(ROUTES.EDITOR.DEFAULT)
    },
    {
      title: t("headerDiscover"),
      cb: () => history.push(ROUTES.EDITOR.DISCOVER),
      path: ROUTES.EDITOR.DISCOVER
    },
    {
      title: t("headerMyQuiz"),
      cb: () => history.push(ROUTES.EDITOR.MY_QUIZZES),
      isActive: history.location.pathname === ROUTES.EDITOR.MY_QUIZZES || history.location.pathname.indexOf(ROUTES.EDITOR.EDIT_QUIZ_BASE) === 0
    },
    {
      title: t("headerCreate"),
      cb: () => history.push(ROUTES.EDITOR.CREATE_QUIZ),
      path: ROUTES.EDITOR.CREATE_QUIZ

    },
    {
      title: t("headerAdmin"),
      cb: () => history.push(ROUTES.ADMIN.TAGS),
      isActive:  history.location.pathname.indexOf(ROUTES.ADMIN.DEFAULT) === 0 
    },
  ];
  const menuItems = [
    {
      title: t("adminTags"), key: 'tags',
      cb: () => history.push(ROUTES.ADMIN.TAGS),
      path:ROUTES.ADMIN.TAGS
    },
    {
      title: t("adminCategories"), key: 'categories',
      cb: () => history.push(ROUTES.ADMIN.CATEGORIES),
      path:ROUTES.ADMIN.CATEGORIES
    },
    {
      title: t("adminUsers"), key: 'users',
      cb: () => history.push(ROUTES.ADMIN.USERS),
      path:ROUTES.ADMIN.USERS
    }
  ];
  const keys = [path.replace('/', '')];
  
  const isActive = item => history.location.pathname === item.path;

  return (
    <Route
      {...rest}
      render={props => (        
        <Layout className='wrapper'>
          <Header menu={header} />
          <Layout>
            <Sider>
              <Menu
                className='c-admin-sider-menu'
                mode='inline'
                theme='light'
                selectedKeys={keys}
              >
                {menuItems.map(item =>
                  <Menu.Item
                   key={item.key} 
                   onClick={item.cb}
                   className={cn({
                    'c-menu-item': true,
                    'c-menu-item__active': isActive(item)||item.isActive,
                  })}
                   >
                    {item.title}
                  </Menu.Item>
                )}
              </Menu>
            </Sider>
            <Content className='c-admin-content'>
              {Component && <Component {...props} />}
            </Content>
          </Layout>
        </Layout>
      )}
    />
  );
}
AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  path: PropTypes.string,
};
export default withTranslation()(AdminRoute);