import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';
import configureStore from './store';
import rootSaga from './store/sagas';
import { Provider } from 'react-redux';
import App from './App';

import 'antd/dist/antd.css';
import './styles/app.scss';

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

