import React from 'react';
import './styles.scss';



export default function OpenQuestion({
  slide,
  onUpdateSlide
}) {

  

  return (
    <></>
  );
}
