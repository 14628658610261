import { fromImage } from 'imtool';
import { IMAGE_SIZES,IMAGE_AVAILABLE_SIZES } from '../constants';

export default class ImageService {


    constructor(image) {
        this.image = image;
    }
    async getResizedImages(id) {
        let result = [];

        let sizes = Object.keys(IMAGE_SIZES);
        for (let i = 0; i < sizes.length; i++) {
            let imtool = await fromImage(this.image);
            let size = sizes[i];
            let imageDimension = IMAGE_SIZES[size];
            let data = { 
                filename: this.getFileName(id, this.image.type, size), 
                type: this.image.type,
                size
            };
            if (imageDimension.maxHeight > 0 && imageDimension.maxWidth > 0) {
                let ratio = imageDimension.maxHeight / imageDimension.maxWidth;
                let thumbsize = ratio > 1 ? imageDimension.maxHeight : imageDimension.maxWidth;
                data.image = await imtool.thumbnail(thumbsize, false).toBlob();
            } else {
                data.image = await imtool.toBlob();
            }
            result.push(data);
        }
        return result;
    }

    getFileName(id, filetype, size) {
        let filename = `${id}_${size}`;

        if (filetype === 'image/jpeg') {
            filename += '.jpg';
        }
        if (filetype === 'image/png') {
            filename += '.png';
        }
        return filename;
    }

}