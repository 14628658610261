import { MOBILE_VIEW } from '../../constants';
import * as types from '../types';

const moduleReducers = {
  socketConnected: setSocketConnected,
  participant: setParticipant,
  mobileStep: setMobileStep,
  mobileView: setMobileView,
  currentQuestionResult: setCurrentAnswerResult,
  waitForQuestionResult: setWaitForQuestionResult,
  totalParticipantResult: setTotalParticipantResult,
};


function setSocketConnected(state=false, action ){
  switch (action.type) {
    case types.SET_SOCKET_CONNECTED:
      return action.payload;
    default:
      return state;
  }
}

function setParticipant(state=null, action ){  
  switch (action.type) {
    case types.SET_PARTICIPANT:
      return action.payload;
    default:
      return state;
  }
}
function setMobileStep(state=0, action ){  
  switch (action.type) {
    case types.SET_MOBILE_STEP:
      return action.payload;
    default:
      return state;
  }
}

function setMobileView(state=MOBILE_VIEW.NAME, action ){  
  switch (action.type) {
    case types.SET_MOBILE_VIEW:
      return action.payload;
    default:
      return state;
  }
}

function setCurrentAnswerResult(state={ status: 'failure', points:0 }, action ){
  switch (action.type) {
    case types.MOBILE_SET_CURRENT_ANSWER_RESULT:
      return action.payload;
    default:
      return state;
  }
}

function setWaitForQuestionResult(state=false, action ){
  switch (action.type) {
    case types.MOBILE_SET_WAIT_FOR_QUESTION_RESULT:
      return action.payload;
    default:
      return state;
  }
}

function setTotalParticipantResult(state={}, action ){
  switch (action.type) {
    case types.SET_TOTAL_PARTICIPANT_RESULT:
      return action.payload;
    default:
      return state;
  }
}


export default moduleReducers;
