import PropTypes from "prop-types";
import React from 'react';
import cn from 'classnames';
import { Figure, CheckMark, CloseMark } from '../../../../assets/svg';
import { colors, MAX_ANSWER_CHARS_LENGTH, QUESTION_TYPE } from '../../../../constants';
import { withTranslation } from 'react-i18next';

const sortByPlaceAsc = (a, b) => {
  return a.place - b.place;
};

const MAX_RANKING_PARTICIPANTS = 5;
const MAX_BUBBLE_SIZE = 50;
const MAX_BUBBLE_FONT_SIZE = 1.8;

const Result = withTranslation()(({ question, answerStats, participantsCount, totalResult, t, ...rest }) => {
  const totalAnswers = Array.isArray(answerStats) && answerStats.reduce((acc, answerStat) => acc + answerStat.count, 0);

  const minItemContentHeight = 12;
  const maxItemContentHeight = 20;
  const minAnswerCount = Math.min(...answerStats.map(o => o.count));
  const maxAnswerCount = Math.max(...answerStats.map(o => o.count));
  const percent = (maxItemContentHeight - minItemContentHeight) / ((maxAnswerCount - minAnswerCount || 1));

  return (
    <div className={cn({
      'quiz': true,
      'quiz_result': true,
      'quiz_two-answers': question.type === QUESTION_TYPE.TRUE_FALSE,
    })}>

      <div className={cn({
        'quiz__content': true,
        'result': true,
      })}>
        <div className={cn({
          'quiz__title': true,
        })}>{question.title}</div>
      </div>

      <div className='quiz__result'>
        <div className="results-row">
          <ul className='quiz__resultList'>
            {Array.isArray(answerStats) && answerStats.map((data, idx) => (
              <li
                className='quiz__resultItem'
                key={idx}
              >
                {(question.type === QUESTION_TYPE.QUESTIONAIRE && totalAnswers)
                ?
                <div
                  style={{ color: colors[idx], alignSelf: 'center', lineHeight: 1.2 }}
                >{(data.count / totalAnswers * 100).toFixed()}%</div>
                :
                <div
                  className='quiz__resultItemStats'
                  style={{ color: colors[idx] }}
                >
                  <Figure />
                  <span>{data.count}</span>
                </div>
                }
                <div
                  style={{ backgroundColor: colors[idx], height: `${minItemContentHeight + percent * data.count}vh` }}
                  className='quiz__resultItemContent'
                >
                  <span>{idx + 1}</span>
                </div>
              </li>
            ))}
          </ul>
          {Array.isArray(totalResult) && totalResult.length > 0 &&
          <div className="ranking-container">
            <div className="ranking-card">
              <div className="ranking-title">{t("resultRanking")}</div>
              {totalResult.sort(sortByPlaceAsc).slice(0, MAX_RANKING_PARTICIPANTS).map((result, index) => (
                <div className="ranking-row" key={`${index}`}>
                  <div className="ranking-text">{result.place}</div>
                  <div className="ranking-text ranking-name">{result.name}</div>
                </div>
              ))}
            </div>
          </div>
          }
        </div>
      </div>

      {answerStats && answerStats.length &&
        <ul className='quiz__answers' style={{ rowGap: answerStats.length > 4 ? '0vh' : '2vh' }}>
          {answerStats.map((data, idx) => (
            <li
              key={idx}
              className='quiz__answersItem'
            >
              <div className='quiz__answer'>
                <span>{idx + 1}</span>
                <div
                  className={cn({
                    'quiz__answerContent': true,
                    'quiz__answerContent_result': true,
                    'length-25': answerStats.length > 2 && data.title?.length > 25,
                    'two-answers-length-25': answerStats.length <= 2 && data.title?.length > 25,
                  })}
                  style={{ backgroundColor: colors[idx] }}
                >
                  {data.title?.substring(0, MAX_ANSWER_CHARS_LENGTH)}

                  <span className='result-icon'>
                  {[QUESTION_TYPE.TRUE_FALSE, QUESTION_TYPE.MULTIPLE].includes(question.type) && <>
                    {data.right
                      ? <CheckMark className="result-icon" size={question.type === QUESTION_TYPE.TRUE_FALSE ? 'large' : 'default'} />
                      : <CloseMark className="result-icon" size={question.type === QUESTION_TYPE.TRUE_FALSE ? 'large' : 'default'} />}
                  </>
                  }
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>}
    </div>
  );
});

const OpenQuestionResult = withTranslation()(({ question, answerStats, participantsCount, t }) => {

  let answers = {};
  let givenAnswers = answerStats.reduce((acc, answerStats) => answerStats.answer?.length ? acc + 1 : acc, 0);
  answerStats.forEach(answerStat => {
    if (answerStat.answer?.length) {
      let answerString = answerStat.answer.toLowerCase();
      answers[answerString] = answers[answerString] || { count: 0 };
      answers[answerString].count++;
      answers[answerString].percentage = answers[answerString].count / givenAnswers * 100;
    }
  });
  // console.log(answers);
  return (
    <div className={cn({
      'quiz': true,
      'quiz_openQuestion': true,
    })}>
      <div className={cn({
        'quiz__content': true
      })}>
        <div className={cn({
          'quiz__title': true,
        })}>{question.title}</div>
      </div>

      <div className='quiz__subTitle'>{t("quizHaveReplied", {
        current: givenAnswers,
        total: participantsCount,
      })}</div>

      <div className='quiz__openQuestionList'>
        {Object.keys(answers).sort((a,b)=>answers[a].count>answers[b].count?-1:1).map((answer,index)=>{
          let answerStat = answers[answer];
          let numberOfAnswers=answerStat?.count;
          // console.log(answerStat);
          // if (index === 0) {
          //   numberOfAnswers = 1;
          // }
          let answerSize = Math.min(answerStat.percentage*(0.5 + numberOfAnswers/10), MAX_BUBBLE_SIZE);
          let answerFontSize = Math.min(answerStat.percentage/100*3*(0.8 + numberOfAnswers/10), MAX_BUBBLE_FONT_SIZE);
          if (answer?.length > 12) {
            answerFontSize *= 0.6;
          }
  
          return (
            <div
              style={{
                color: colors[index % 6],
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
              key={`${index}`}
            >
              <div className="figure-container">
                <Figure/>
                <div className="answers-count">
                  {numberOfAnswers}
                </div>
              </div>
              <div
                key={index}
                className='quiz__openQuestionItem'
                style={{
                  backgroundColor: colors[index % 6],
                  width:`calc(${answerSize}vh)`,
                  height:`${answerSize}vh`,
                  minWidth:`${answerSize}vh`,
                  minHeight:`${answerSize}vh`,
                  fontSize:`${answerFontSize}em`,
                  borderRadius:'50%'
                }}
              >
                {answer}
              </div>
            </div>

          );
        })}
        {/* {answerStats.map((i, ind) => {

          return (
            <li
              key={ind}
              className='quiz__openQuestionItem'
              style={{ backgroundColor: colors[ind % 6] }}
            >
              {i.answer}
            </li>

          )
        }
        )} */}
      </div>
    </div>
  );
});


export default function ResultComponent({ question, ...rest }) {
	if (!question) {
		return null;
	}
  if (question.type === QUESTION_TYPE.OPEN_QUESTION) {
    return <OpenQuestionResult question={question} {...rest} />;
  }
  return <Result question={question} {...rest} />;
}

ResultComponent.propTypes = {
  question: PropTypes.object,
};
