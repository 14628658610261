import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ROUTES } from '../../constants';
import history from '../../history';
import { QuizService } from '../../services';
import * as types from '../types';
import { waitFor } from './general';

const canLoadMore = (prevQuizzesAmount, fetchedQuizzesAmount, totalCount, loadMore) => {
  const totalCurrentQuizzesAmount = loadMore ? prevQuizzesAmount + fetchedQuizzesAmount : fetchedQuizzesAmount;
  return totalCount > totalCurrentQuizzesAmount;
};

function* fetchQuizzes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token, quizzes } = yield select((state) => state);


    const quizService = yield call(() => new QuizService(token));
    const fetchedQuizzes = yield call([
      quizService,
      quizService.getQuizzes,
    ], action.payload);

    yield put({
      type: types.SET_CAN_LOAD_MORE_QUIZZES,
      payload: canLoadMore(quizzes.length, fetchedQuizzes.result.length, fetchedQuizzes.totalCount, action.payload.loadMore),
    });

    yield put({
      type: types.SET_QUIZZES_COUNT,
      payload: fetchedQuizzes.totalCount,
    });

    yield put({
      type: action.payload.loadMore ? types.SET_MORE_QUIZZES : types.SET_QUIZZES,
      payload: fetchedQuizzes.result,
    });

  } catch (error) {
    console.error('fetchQuizzes error', error);
  }
}

function* fetchLastQuizzes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token, lastQuizzes } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const fetchedQuizzes = yield call([
      quizService,
      quizService.getQuizzes,
    ], {
      ...action.payload,
    });

    yield put({
      type: types.SET_CAN_LOAD_MORE_LAST_QUIZZES,
      payload: canLoadMore(lastQuizzes.length, fetchedQuizzes.result.length, fetchedQuizzes.totalCount, action.payload.loadMore),
    });

    yield put({
      type: types.SET_LAST_QUIZZES_COUNT,
      payload: fetchedQuizzes.totalCount,
    });

    yield put({
      type: action.payload.loadMore ? types.SET_MORE_LAST_QUIZZES : types.SET_LAST_QUIZZES,
      payload: fetchedQuizzes.result,
    });

  } catch (error) {
    console.error('fetchLastQuizzes error', error);
  }
}

function* fetchMyQuizzes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token, myQuizzes } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const params = { ...action.payload, type: 'my' };

    const fetchedQuizzes = yield call([
      quizService,
      quizService.getQuizzes,
    ], params);

    yield put({
      type: types.SET_CAN_LOAD_MORE_MY_QUIZZES,
      payload: canLoadMore(myQuizzes.length, fetchedQuizzes.result.length, fetchedQuizzes.totalCount, action.payload.loadMore),
    });

    yield put({
      type: types.SET_MY_QUIZZES_COUNT,
      payload: fetchedQuizzes.totalCount,
    });

    yield put({
      type: action.payload.loadMore ? types.SET_MORE_MY_QUIZZES : types.SET_MY_QUIZZES,
      payload: fetchedQuizzes.result,
    });

  } catch (error) {
    console.error('fetchMyQuizzes error', error);
  }
}

function* fetchFavoriteQuizzes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token, favoriteQuizzes } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const params = { ...action.payload, type: 'favorite' };

    const fetchedQuizzes = yield call([
      quizService,
      quizService.getQuizzes,
    ], params);

    yield put({
      type: types.SET_CAN_LOAD_MORE_FAVORITE_QUIZZES,
      payload: canLoadMore(favoriteQuizzes.length, fetchedQuizzes.result.length, fetchedQuizzes.totalCount, action.payload.loadMore),
    });
    
    yield put({
      type: types.SET_FAVORITE_QUIZZES_COUNT,
      payload: fetchedQuizzes.totalCount,
    });

    yield put({
      type: action.payload.loadMore ? types.SET_MORE_FAVORITE_QUIZZES : types.SET_FAVORITE_QUIZZES,
      payload: fetchedQuizzes.result,
    });

  } catch (error) {
    console.error('fetchFavoriteQuizzes error', error);
  }
}

function* fetchQuizById(action) {
  try {
    if (!action.payload) {
      yield put({
        type: types.SET_QUIZ_BY_ID,
        payload: null,
      });
      return;
    }
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const quiz = yield call([
      quizService,
      quizService.getQuiz,
    ], action.payload);

    yield put({
      type: types.SET_QUIZ_BY_ID,
      payload: quiz.result,
    });

  } catch (error) {
    console.error('fetchQuizById error', error);
  }
}

function* favoriteQuiz(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const quiz = yield call([
      quizService,
      quizService.favoriteQuiz,
    ], action.payload);

    yield put({
      type: types.PATCH_QUIZ_BY_ID,
      payload: quiz,
    });
  } catch (error) {
    console.error('favoriteQuiz error', error);
  }
}

function* unfavoriteQuiz(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const quiz = yield call([
      quizService,
      quizService.unfavoriteQuiz,
    ], action.payload);

    yield put({
      type: types.PATCH_QUIZ_BY_ID,
      payload: quiz,
    });
  } catch (error) {
    console.error('unfavoriteQuiz error', error);
  }
}

function* copyQuiz(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    const quiz = yield call([
      quizService,
      quizService.copyQuiz,
    ], action.payload);

    history.push(ROUTES.EDITOR.EDIT_QUIZ.replace(':id', quiz._id));
  } catch (error) {
    console.error('copyQuiz error', error);
  }
}

function* deleteQuiz(action) {
  const quizId = action.payload.id;
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizService = yield call(() => new QuizService(token));

    yield call([
      quizService,
      quizService.deleteQuiz,
    ], quizId);

    action.payload.cb();

  } catch (error) {
    console.error('deleteQuiz error', error);
  }
}

export function* quizzesSagas(){
  yield takeEvery(types.FETCH_QUIZZES, fetchQuizzes);
  yield takeEvery(types.FETCH_LAST_QUIZZES, fetchLastQuizzes);
  yield takeEvery(types.FETCH_MY_QUIZZES, fetchMyQuizzes);
  yield takeEvery(types.FETCH_FAVORITE_QUIZZES, fetchFavoriteQuizzes);
  yield takeEvery(types.FAVORITE_QUIZ, favoriteQuiz);
  yield takeEvery(types.UNFAVORITE_QUIZ, unfavoriteQuiz);
  yield takeEvery(types.FETCH_QUIZ_BY_ID, fetchQuizById);
  yield takeEvery(types.COPY_QUIZ, copyQuiz);
  yield takeEvery(types.DELETE_QUIZ, deleteQuiz);
}

export default quizzesSagas;
