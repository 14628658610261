import {
  takeEvery, put, call,
} from 'redux-saga/effects';
import * as types from '../types';
import { ConfigService } from '../../services';

function* loadConfig(action) {
  try {
    const configService = yield call(() => new ConfigService());

    const config = yield call([
      configService,
      configService.getConfig,
    ]);

    yield put({
      type: types.SET_CONFIG,
      payload: config.result,
    });

  } catch (error) {
    console.error('fetchConfig error', error);
  }
}

export function* configSagas() {
  yield takeEvery(types.LOAD_CONFIG, loadConfig);
}

export default configSagas;
