import { FileProtectOutlined, FileTextOutlined, GlobalOutlined, LogoutOutlined, UserAddOutlined, MenuOutlined, MessageOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InviteCodeModal } from '../../pages/Admin/Users/InviteCodeModal';
import * as dayjs from 'dayjs';

const HamburgerMenu = (props) => {
	const { t, onLogoutPress, isWhite } = props;
	
	const changeLanguage = async (lng) => {
		await i18n.changeLanguage(lng);
	};

	const onFeedbackClick = () => {
		window.open(t('linkFeedback'), '_blank');
	};
	
	const onToggle = () => { setMenuIsOpen(!menuIsOpen); };
	const user = useSelector(state => state.user);
	
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [inviteCodeModalVisible, setInviteCodeModalVisible] = useState(false);

	const handleCreateInviteCodes = (values) => {
    let params = { count: values.count };
    params.validUntil = dayjs().add(values.daysValid, 'd').toDate();
    props.onCreateInviteCodes(params);
    closeInviteCodeModalVisible();
  };

	const openInviteCodeVisible = () => {
		setInviteCodeModalVisible(true);
	};

	const closeInviteCodeModalVisible = () => {
		setInviteCodeModalVisible(false);
	};

	const onImprintClick = () => {
		window.open(t('linkImprint'), '_blank');
	};

	const onPrivacyClick = () => {
		window.open(t('linkPrivacy'), '_blank');
	};
	
	const menu = (
		<Menu className='header-menu'>
			<h1>{t('headerMenuTitle')}</h1>
			
			<div className="item">
				<GlobalOutlined className="icon"/>{t('headerMenuAppLanguage')}
			</div>
			
			<Menu.Item onClick={() => changeLanguage('de')} className="sub-item">
				<div className={`title${i18n.language === 'de' ? ' active' : ''}`}>{t('userProfileLanguageDe')}</div>
			</Menu.Item>
			
			<Menu.Item onClick={() => changeLanguage('en')} className="sub-item">
				<div className={`title${i18n.language === 'en' ? ' active' : ''}`}>{t('userProfileLanguageEn')}</div>
			</Menu.Item>
			
			<Menu.Item onClick={onImprintClick} className="item">
				<FileTextOutlined className="icon"/>{t('headerMenuImprint')}
			</Menu.Item>
			
			<Menu.Item onClick={onPrivacyClick} className="item">
				<FileProtectOutlined className="icon"/>{t('headerMenuPrivacy')}
			</Menu.Item>

			<Menu.Item onClick={onFeedbackClick} className="item">
				<MessageOutlined className="icon"/>{t('menuFeedback')}
			</Menu.Item>

			{user?.isModerator && 
			<Menu.Item onClick={openInviteCodeVisible} className="item">
				<UserAddOutlined className="icon"/>{t('usersGenerateCode')}
			</Menu.Item>
			}
			
			{user && (
				<Menu.Item onClick={onLogoutPress} className="item">
					<LogoutOutlined className="icon"/>{t('headerLogout')}
				</Menu.Item>
			)}
		</Menu>
	);
	
	return (
		<>
			<Dropdown overlay={menu} placement='bottomRight' trigger='click'>
				<MenuOutlined className={`icon hamburger-menu ${isWhite && 'is-white'}`} onClick={onToggle}/>
			</Dropdown>
			<InviteCodeModal
				onCreateInviteCodes={handleCreateInviteCodes}
				onCancel={closeInviteCodeModalVisible}
				isInviteCodeModalVisible={inviteCodeModalVisible}
				t={t}
			/>
		</>
	);
};

HamburgerMenu.defaultProps = {
	t: () => {},
	onLogoutPress: () => {},
	isWhite: false,
	onCreateInviteCodes: () => {},
};

HamburgerMenu.propTypes = {
	t: PropTypes.func,
	onLogoutPress: PropTypes.func,
	isWhite: PropTypes.bool,
	onCreateInviteCodes: PropTypes.func,
};

export default withTranslation()(HamburgerMenu);
