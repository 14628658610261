import * as types from '../types';

const moduleReducers = { 
  user: setUser, 
  users: setUsers
};

function setUser(state = null, action) {
  switch (action.type) {
  case types.SET_USER:
    return action.payload;
  default:
    return state;
  }
}

function setUsers(state = [], action) {
  switch (action.type) {
  case types.SET_USERS:
    return action.payload;
  default:
    return state;
  }
}

export default moduleReducers;