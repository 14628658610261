import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import CreateQuiz from './CreateQuiz';
import {
  addSlide,
  updateSlide,
  storeSlideImage,
  removeSlideImage,
  deleteSlide,
  updateQuiz,
  createQuiz,
  storeQuizImage,
  removeQuizImage,
  cleanBuilder,
  fetchQuizForBuilder,
  saveQuiz,
  setSlides,
  setBuilderStep,
  setBuilderType
} from '../../../store/actions/quiz_builder';
import { fetchCategories } from '../../../store/actions/category';
import { fetchFolders } from '../../../store/actions/folder';
import { fetchTags } from '../../../store/actions/tag';
import { createQuizSession } from '../../../store/actions/quiz_session';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addSlide,
      updateSlide,
      deleteSlide,
      setSlides,
      storeSlideImage,
      removeSlideImage,
      fetchCategories,
      updateQuiz,
      createQuiz,
      storeQuizImage,
      removeQuizImage,
      saveQuiz,
      cleanBuilder,
      fetchFolders,
      fetchTags,
      fetchQuizForBuilder,
      createQuizSession,
      setBuilderStep,
      setBuilderType
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    slides: state.slides,
    categories: state.categories,
    quiz: state.quiz,
    user: state.user,
    folders: state.folders,
    tags: state.tags,
    builderType: state.builderType,
    builderStep: state.builderStep,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(CreateQuiz));
