import React, { Component } from 'react';
import cn from 'classnames';
import { Col, Collapse, Layout, Row } from 'antd';

import { Crown } from '../../../../assets/svg';
import {icons} from "../../../../resources";
import { withTranslation } from 'react-i18next';
import { QUIZ_SESSION_STATES } from '../../../../constants';

const colors = [
  '#eb6400',
  '#a0c814',
  '#5ac8f5'
];

export default withTranslation()(class FinalResult extends Component {
  state = {
    questionIndex: 0
  }

  handleDecreaseIndex = () => {
    const { questionIndex } = this.state;

    if (!questionIndex) {
      return;
    }
    this.setState({ questionIndex: questionIndex - 1 });
  }

  handleIncreaseIndex = () => {
    const { questionIndex } = this.state;
    const { questions } = this.props;

    if (questionIndex + 5 === questions.length) {
      return;
    }
    this.setState({ questionIndex: questionIndex + 1 });
  }

  render () {
    const { questions, totalResult, handleQuestionClick, t } = this.props;
    const { questionIndex } = this.state;

    const visibleQuestions = questions.slice(questionIndex, questionIndex + 5);

    return (
      <div className={cn({
        'quiz': true,
        'quiz_result': true,
      })}>

        <div className='quiz__content result final_result'>
          <div className='quiz__title'>{t("quizHaveWon")}</div>

          <div className='quiz__fullResult'>
            <ul className='quiz__fullResult-TopList'>
              {totalResult.slice(0, 3).map((result, idx) => (
                <li
                  style={{color: colors[idx]}}
                  key={idx}
                  className='quiz__fullResult-TopListItem'
                  data-name={result.name}
                >
                  <Crown color={colors[idx]} top={result.place === 1}/>
                  <div
                    data-name={result.name}
                    style={{
                      backgroundColor: colors[idx],
                      height: `${[39.2, 24.68, 19.6][idx]}vh`
                    }}
                    className='quiz__fullResult-TopListItemBar'
                  >
                    <span>{result.totalPoints}</span>
                    <span>{result.place}.</span>
                  </div>
                </li>
              ))}
            </ul>

            <div className='quiz__fullResult-ListWrapper'>
              <Crown/>
              <ul className='quiz__fullResult-List'>
                <li key='header' className='quiz__fullResult-ListItem'>
                  <span>{t("quizTotalNumberParticipants", {
                    value: totalResult.length,
                  })}</span>
                </li>
                {totalResult.slice(3).map((result, idx) => (
                  <li className='quiz__fullResult-ListItem' key={idx}>
                    <span>{result.place}</span>
                    <span style={{paddingLeft: 20}}>{result.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <Row className='quiz__fullResult-BottomList'>
            <Col span={24}>
              <Row>
                <Col span={2} className='quiz__fullResult-BottomListItem'>
                  <img
                    className='quiz__fullResult-BottomListItem__icon'
                    src={icons.iconArrowLeft} alt=""
                    onClick={this.handleDecreaseIndex}
                  />
                </Col>
                <Col span={20}>
                  <Row justify='space-between'>
                    {visibleQuestions.slice(0, 5).map(question =>
                      <Col
                        key={question.id}
                        span={4}
                        className='quiz__fullResult-BottomListItem__nested quiz__fullResult-BottomListItem'
                        onClick={() => handleQuestionClick(question.id, QUIZ_SESSION_STATES.FINISHED_QUESTION_REVIEW)}
                      >
                        <div className="title">{question.title}</div>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={2} className='quiz__fullResult-BottomListItem'>
                  <img
                    className='quiz__fullResult-BottomListItem__icon quiz__fullResult-BottomListItem__icon-right'
                    src={icons.iconArrowLeft} alt=""
                    onClick={this.handleIncreaseIndex}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
});
