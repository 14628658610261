import ApiService from './api';

export default class CategoryService extends ApiService {
  async getCategories(params) {
    return this.get('/category/', params);
  }

  async createCategory(body) {
    return this.post('/category/', body);
  }

  async deleteCategory(id) {
    return this.delete('/category/' + id);
  }
}
