import React from 'react';
import cn from 'classnames';
import { Cup } from '../../../../assets/svg';
import Image from "../../../../components/Image/index";
import { IMAGE_AVAILABLE_SIZES, MAX_ANSWER_CHARS_LENGTH } from '../../../../constants';
import Countdown from './Countdown';

const colors = [
  '#a0c814',
  '#eb6400',
  '#5ac8f5',
  '#82055f',
  '#788287',
  '#003969'
];

export default function Multiple({ question, seconds }) {
  return (
    <div className='quiz'>

      <div className={cn({
        'quiz__content': true,
        'quiz__content_withoutImage': !question.image
      })}>
        <div className={cn({
          'quiz__title': true,
          'quiz__title_halfWidth': !question.image
        })}>{question.title}</div>
       {/* <div className='quiz__content__wrapper'> */}
        {question.image &&
          <div className='quiz__image'>
            <Image image={question.image} size={IMAGE_AVAILABLE_SIZES.PRENSENTATION} noFallback></Image>
          </div>}

        {(question.points || question.seconds) &&
          <div className='quiz__settings'>
            <ul className='quiz__settingsList'>
              {question.points && <li className='quiz__settingsItem'>
                <Cup />
                <span>{question.points}</span>
              </li>
              }

              {question.seconds && <Countdown
                seconds={seconds}
                secondsTotal = {question.seconds}
              />              
              }
            </ul>
          </div>}
          {/* </div> */}

      </div>

      {question.answers && question.answers.length>0 &&
        <ul className='quiz__answers'>
          {question.answers.map((answer, idx) => (
            <li
              key={idx}
              className='quiz__answersItem'
            >
              <div className='quiz__answer'>
                <span>{idx + 1}</span>
                <div
                  className={cn({
                    'quiz__answerContent': true,
                    'quiz__answerContent_result': true,
                    'length-25': answer.title?.length > 25,
                  })}
                  style={{ backgroundColor: colors[idx] }}
                >
                  {answer.title?.substring(0, MAX_ANSWER_CHARS_LENGTH)}
                </div>
              </div>
            </li>
          ))}
        </ul>}
    </div>
  );
}
