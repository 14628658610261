import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import PresentationHeader from './PresentationHeader';
import { finishQuestion, setPresentationType, setPresentationStep, downloadQuizResults, getQuestionResult, setFinalResult, setTotalAnsweredCount, } from '../../store/actions/quiz_presentation';
import { startQuizSession } from '../../store/actions/quiz_session';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        setPresentationType,
        setPresentationStep,
        startQuizSession,
        finishQuestion,
        downloadQuizResults,
        getQuestionResult,
        setFinalResult,
        setTotalAnsweredCount,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    quizSession: state.quizSession,
    presentationStep: state.presentationStep,
    presentationType: state.presentationType,
    quizSessionRedirect: state.quizSessionRedirect,
    totalAnsweredCount: state.totalAnsweredCount,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PresentationHeader);
