import * as types from '../types';

const moduleReducers = {
  categories: setCategories,
};

function setCategories(state = [], action) {
  switch (action.type) {
    case types.SET_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
