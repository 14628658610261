import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Users from './Users';
import { fetchUsers, updateUser, deleteUser, createInviteCodes } from '../../../store/actions/user';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers, 
      updateUser, 
      deleteUser,
      createInviteCodes
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    users: state.users,
    user:state.user
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Users));
