const buildGradient = (seconds, totalSeconds) => {
  if (!seconds) {
    return 'linear-gradient(270deg, transparent 50%, white 50%), linear-gradient(270deg, white 50%, transparent 50%)';
  }

  const deg = 180 * (seconds / (totalSeconds / 2));

  if (seconds < totalSeconds / 2) {

    return `linear-gradient(${90 + deg}deg, transparent 50%, white 50%), linear-gradient(90deg, white 50%, transparent 50%)`;
  }

  if (seconds === totalSeconds / 2) {
    return 'linear-gradient(90deg, white 50%, transparent 50%)';
  }

  if (seconds > totalSeconds / 2) {
    return `linear-gradient(90deg, transparent 50%, #d5e892 50%), linear-gradient(${deg - 90}deg, white 50%, transparent 50%)`;
  }
};

export {
  buildGradient
};
