import React from 'react';
import { Select } from 'antd';

import './Select.scss';

const AntdSelect = (props) => {
  return (
    <Select className='c-select' {...props} />
  );
};

export default AntdSelect;
