import PropTypes from "prop-types";
import React from 'react';
import { Form } from 'antd';
import { Button, Input } from '../../../components';

import './InviteCode.scss';
import { ROUTES } from "../../../constants";

export default function InviteCode (props) {
  const onSubmit=(values)=>{
    props.checkInviteCode(values.code);
  };
  if(props.user?.isActivated) {
    props.history.push(ROUTES.EDITOR.DEFAULT);
  }
  const { t } = props;
  return (
    <div className='c-login'>
      <Form
        layout='vertical'
        hideRequiredMark
        className='c-login__form'
        onFinish={onSubmit}
      >
        <Form.Item
          name='code'
          rules={[
            { required: true, message: t("inviteCodeEnterCode") }
          ]}
        >
          <Input placeholder={t("inviteCodeCode")} />
        </Form.Item>
        <Form.Item>
          <Button
            type='submit'
          >
            {t("inviteCodeSubmit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

InviteCode.propTypes = {
  checkInviteCode: PropTypes.func
};
