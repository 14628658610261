import React, {Component} from 'react';
import QRCode from 'qrcode.react';

import './WelcomeQuiz.scss';
import { ROUTES } from '../../../constants';

const baseUrl = `${window.location.protocol}//${window.location.host}`;

export default class WelcomeQuiz extends Component {

  componentDidMount() {
    const { match, initPresentation, } = this.props;

    if (match && match.params.code) {
      initPresentation({ code: match.params.code });
    }
  }

  render () {
    const { quizSession: { code }, t } =  this.props;
    const { participants } = this.props;
    return (
      <div className='welcomeQuiz'>
        <div className='welcomeQuiz__inner'>
          <div className='welcomeQuiz__banner'>
            <div className='welcomeQuiz__bannerItem'>
              <div className='welcomeQuiz__bannerText'>
                {t("welcomeQuizCode", {
                  value: code,
                })}
              </div>

              <div className='welcomeQuiz__bannerText'>
                {t("welcomeQuizLink", {
                  value: `${baseUrl}${ROUTES.QUIZ.MOBILE_LINK.replace(':code', code)}`
                })}
              </div>
            </div>

            <div className='welcomeQuiz__bannerItem'>
              <div className='welcomeQuiz__bannerQR'>
                <QRCode renderAs='svg' value={`${baseUrl}${ROUTES.QUIZ.MOBILE_LINK.replace(':code', code)}`} size={260} />
              </div>
            </div>
          </div>

          <div className='welcomeQuiz__players'>
            <div className='welcomeQuiz__playersCount'>{t("welcomeQuizParticipants", {
              value: participants.length,
            })}</div>

            <ul className='welcomeQuiz__playersList'>
              {participants.map((participant, idx) => (
                <li key={idx} className='welcomeQuiz__playersListItem'>{participant.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
