import cn from 'classnames';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Cup } from '../../../../assets/svg';
import Image from "../../../../components/Image/index";
import { IMAGE_AVAILABLE_SIZES, MAX_ANSWER_CHARS_LENGTH } from '../../../../constants';
import Countdown from './Countdown';
import './styles.scss';

export default withTranslation()(function TrueFalse ({ question, seconds, t }) {
  return (
    <div className={cn({
      'quiz': true,
      'quiz_two-answers': true,
    })}>

      <div className={cn({
        'quiz__content': true,
        'quiz__content_withoutImage': !question.image
      })}>
        <div className={cn({
          'quiz__title': true,
          'quiz__title_halfWidth': !question.image
        })}>{question.title}</div>

        {question.image &&
        <div className='quiz__image'>
          <Image image={question.image} size={IMAGE_AVAILABLE_SIZES.PRENSENTATION} noFallback></Image>
        </div>}

        {(question.points || question.seconds) &&
        <div className='quiz__settings'>
          <ul className='quiz__settingsList'>
            {question.points && <li className='quiz__settingsItem'>
              <Cup />
              <span>{question.points}</span>
            </li>
            }

            {question.seconds && <Countdown
                seconds={seconds}
                secondsTotal = {question.seconds}
              />
            }
          </ul>
        </div>}


      </div>

      <ul className='quiz__answers'>
        <li className='quiz__answersItem'>
          <div className='quiz__answer'>
            <span>1</span>
            <div
              className={cn({
                'quiz__answerContent': true,
                'two-answers-length-25': question.answers[0]?.title?.length > 25,
              })}
              style={{ backgroundColor: '#a0c814' }}
            >
              {question.answers?.length > 0 ? question.answers[0].title?.substring(0, MAX_ANSWER_CHARS_LENGTH) : ""}
            </div>
          </div>
        </li>
        <li className='quiz__answersItem'>
          <div className='quiz__answer'>
            <span>2</span>
            <div
              className={cn({
                'quiz__answerContent': true,
                'two-answers-length-25': question.answers[1]?.title?.length > 25,
              })}
              style={{ backgroundColor: '#eb6400' }}
            >
              {question.answers?.length > 1 ? question.answers[1].title?.substring(0, MAX_ANSWER_CHARS_LENGTH) : ""}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
});
