import { eventChannel } from 'redux-saga';
import { put, call, take, takeEvery, fork } from "redux-saga/effects";
import * as types from '../types';
import io from 'socket.io-client';

function connect(code) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const socket = io(`${baseUrl}?code=${code}`, { transports: ['websocket'] });

    return new Promise(resolve => {
        socket.on('connect', (data) => {
            resolve(socket);
            // console.log("Socket connected");
        });
    });
}

function subscribe(socket) {
    return new eventChannel(emit => {
        // console.log("socket listening on any event");

        socket.on('addParticipant', payload => emit({ type: types.PRESENTATION_WEB_SOCKET_HANDLE_ADD_PARTICIPANT, payload })); // triggered when new user joins the presentation
        socket.on('finishQuestion', payload => emit({ type: types.PRESENTATION_WEB_SOCKET_HANDLE_FINISH_QUESTION, payload })); // triggered when a question answered or time is over
        socket.on('fetchResult', payload => emit({ type: types.PRESENTATION_WEB_SOCKET_HANDLE_FETCH_RESULT, payload })); // triggered when new total scores are available
        socket.on('tickSeconds', payload => emit({ type: types.PRESENTATION_WEB_SOCKET_HANDLE_TICK_SECONDS, payload })); // triggered when question time is updated during playing quiz
        socket.on('receivedAnswer', payload => emit({ type: types.PRESENTATION_WEB_SOCKET_HANDLE_RECEIVED_ANSWER, payload })); // triggered when answer is given by some user
        
        return () => { };
    });
}

function* read(socket) {
    const channel = yield call(subscribe, socket);
    // console.log("first action", channel);
    yield put({ type: 'SET_SOCKET_CONNECTED', payload: true});
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

function* getQuestionResult(socket) {
    while (true) {
        const { payload } = yield take(types.PRESENTATION_GET_QUESTION_RESULT);
        // console.log('getQuestionResult', payload);
        socket.emit('getQuestionResult', payload);
    }
}

function* fetchPresenterResult(socket) {
    while (true) {
        const { payload } = yield take(types.PRESENTATION_FETCH_PRESENTER_RESULT);
        // console.log('fetchPresenterResult', payload);
        socket.emit('fetchPresenterResult', payload);
    }
}

function* setFinalResult(socket) {
    while (true) {
        const { payload } = yield take(types.PRESENTATION_SET_FINAL_RESULT);
        // console.log('setFinalResult', payload);
        socket.emit('setFinalResult', payload);
    }
}

function* flow(action) {
    const { code } = action.payload;
    const socket = yield call(connect, code);
    yield fork(read, socket);
    yield fork(getQuestionResult, socket);
    yield fork(fetchPresenterResult, socket);
    yield fork(setFinalResult, socket);
    yield put({ type: types.FETCH_QUIZ_SESSION_BY_CODE, payload: action.payload });
    yield put({ type: types.FETCH_QUIZ_FOR_PRESENTATION, payload: action.payload.code });
}
export default function* presentationWebsocketSaga() {
    yield takeEvery(types.INIT_PRESENTATION_WEB_SOCKETS_CHANNEL, flow);
}