import ApiService from './api';

export default class QuizService extends ApiService {
  async getQuizzes(params) {
    return this.get('/quiz/', params);
  }

  async getQuiz(quizId) {
    return this.get(`/quiz/${quizId}/`);
  }

  async favoriteQuiz(quizId) {
    return this.post(`/quiz/${quizId}/favorite`);
  }

  async unfavoriteQuiz(quizId) {
    return this.post(`/quiz/${quizId}/unfavorite`);
  }

  async copyQuiz(quizId) {
    return this.post(`/quiz/${quizId}/copy`);
  }

  async createQuiz(data) {
    return this.post(`/quiz/`, data);
  }

  async saveQuiz(quizId, data) {
    return this.put(`/quiz/${quizId}`, data);
  }

  async createQuizSession(data) {
    return this.post(`/quiz_session/`, data);
  }

  async getQuizSessionByQuizId(quizId) {
    return this.get(`/quiz_session/quiz/${quizId}/`);
  }

  async getQuizSessionByCode(code) {
    return this.get(`/quiz_session/code/${code}/`);
  }

  async addParticipant(sessionId, data) {
    return this.post(`/quiz_session/${sessionId}/participant`, data);
  }

  async startQuizSession(sessionId) {
    return this.post(`/quiz_session/${sessionId}/start`);
  }

  async finishQuestion(sessionId, questionId) {
    return this.post(`/quiz_session/${sessionId}/finish/${questionId}`);
  }

  async deleteQuiz(quizId) {
    return this.delete(`/quiz/${quizId}`);
  }
}
