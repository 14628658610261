import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from '../../../components';
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

function MobileCode(props) {

    let { t } = props;
    let [code, setCode] = React.useState('');
    let history = useHistory();

    const handleEnterCode = () => {
        if (code > 0) history.push('/' + code);
    };

    return (
        <div className='mobLogin'>
            <div className='mobLogin__content'>
                <div className='mobLogin__input'>
                    <Input
                        placeholder={t("mobileQuizCode")}
                        value={code}
                        type='number'
                        onChange={e => setCode(e.target.value)}
                    />
                </div>
                <div className='mobLogin__button'>
                    <Button
                        onClick={handleEnterCode}
                        disabled={!code}
                        block
                    >
                        {t("generalNext")}
                    </Button>
                </div>

                <div className='mobLogin__qr'>
                    {t("mobileQuizScanCode")}
                </div>
            </div>
        </div>
    );
}

MobileCode.propTypes = {};

export default withTranslation()(MobileCode);
