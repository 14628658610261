import PropTypes from "prop-types";
import React, { Component } from 'react';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { Row, Col, Select as AntdSelect } from 'antd';
import { icons } from '../../../resources';
import { Modal, Input, Button, Select, Card } from '../../../components';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import './Discover.scss';

const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

const sortingOptions = [
  { key: 'title', title: 'sortingTitle', sortKey: 'title', sortDir: SORT_DIR.ASC },
  { key: 'favCount', title: 'sortingFavCount', sortKey: 'favCount' , sortDir: SORT_DIR.DESC },
  { key: 'level', title: 'sortingLevel', sortKey: 'level' , sortDir: SORT_DIR.ASC },
  { key: 'createdAt', title: 'sortingCreatedAt', sortKey: 'createdAt', sortDir: SORT_DIR.DESC  },
  { key: 'creatorSortName', title: 'sortingAuthor', sortKey: 'creatorSortName', sortDir: SORT_DIR.ASC  },
];

const LG_SCREEN_WIDTH = 992;
const QUIZZES_OFFSET_STEP = 3;

export default class Discover extends Component {
  
  constructor(props) {
    super(props);
   
    this.state = {
      categoryIndex: 0,
      search: '',
      alreadySearched: false,
      sorting: sortingOptions[1].key,
      sortingLast: sortingOptions[3].key,
      activeCategoryId: null,
      quizzesOffsetLast: 0,
      quizzesOffset: 0,
      isOpenModal: false,
      sortDir: SORT_DIR.DESC,
      sortDirLast: SORT_DIR.DESC,
      visibleCategoriesAmount: this.getVisibleCategoriesAmount(),
    };
  }

  componentDidMount () {
    const { fetchCategories } = this.props;

    this.fetchLastQuizzes(QUIZZES_OFFSET_STEP, 0, false);
    fetchCategories();
  
    window.addEventListener('resize', this.updateVisibleCategoriesAmount);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateVisibleCategoriesAmount);
  }
  
  getVisibleCategoriesAmount = () => {
    return window.innerWidth < LG_SCREEN_WIDTH ? 2 : 3;
  };
  
  updateVisibleCategoriesAmount = () => {
    const amount = this.getVisibleCategoriesAmount();
    if (amount !== this.state.visibleCategoriesAmount) {
      this.setState({
        visibleCategoriesAmount: amount,
      });
    }
  };
  
  handleChange = (key, value, alreadySearched = true) => {
    this.setState({ [key]: value, alreadySearched: this.state.alreadySearched || alreadySearched }, () => {
      this.refreshQuizzes();
    });
  };
  
  handleChangeLast = (key, value) => {
    this.setState({ [key]: value }, () => {
      this.refreshLastQuizzes();
    });
  };

  handleDecreaseIndex = () => {
    const { categoryIndex } = this.state;

    if (!categoryIndex) {
      return;
    }
    this.setState({ categoryIndex: categoryIndex - 1 });
   }

  handleIncreaseIndex = () => {
    const { categoryIndex, visibleCategoriesAmount } = this.state;
    const { categories } = this.props;

    if (categoryIndex + visibleCategoriesAmount === categories.length) {
      return;
    }
    this.setState({ categoryIndex: categoryIndex + 1 });
  }

  loadMoreLastQuizzes = () => {
    this.setState(prevState => ({
      quizzesOffsetLast: prevState.quizzesOffsetLast + QUIZZES_OFFSET_STEP
    }), () => {
      const { quizzesOffsetLast } = this.state;
      this.fetchLastQuizzes(QUIZZES_OFFSET_STEP, quizzesOffsetLast, true);
    });
  }

  loadMoreQuizzes = () => {
    this.setState(prevState => ({
      quizzesOffset: prevState.quizzesOffset + QUIZZES_OFFSET_STEP
    }), () => {
      const { quizzesOffset } = this.state;
      this.fetchQuizzes(QUIZZES_OFFSET_STEP, quizzesOffset, true);
    });
  }

  openQuizModal = quiz => {
    this.setState({
      isOpenModal: true,
    });
    this.props.fetchQuizById(quiz._id);
  }

  closeQuizModal = () => {
    this.setState({
      isOpenModal: false
    });
    this.props.fetchQuizById(null);
  };

  toggleSortDir = () => {
    this.setState((prevState) => ({
      sortDir: prevState.sortDir === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC,
    }));
  };

  createFetchQuizzesParams = (limit, sortDir, sortKey, offset, loadMore) => {
    const { activeCategoryId } = this.state;

    const params = { 
      limit, 
      sortDir, 
      sortKey, 
      offset, 
      loadMore 
    };

    if (activeCategoryId) {
      params.categoryId = activeCategoryId;
    }

    return params;
  }

  fetchQuizzes = (amount, offset, loadMore) => {
    const { fetchQuizzes } = this.props;
    const { search, sorting, sortDir } = this.state;

    const { sortKey } = sortingOptions.find(option => option.key === sorting);
    const params = this.createFetchQuizzesParams(amount, sortDir, sortKey, offset, loadMore);

    if (search) {
      params.search = search;
    }

    fetchQuizzes(params);
  }

  refreshQuizzes = () => {
    const { quizzesOffset } = this.state;
    this.fetchQuizzes(quizzesOffset + QUIZZES_OFFSET_STEP, 0, false);
  };

  fetchLastQuizzes = (amount, offset, loadMore) => {
    const { fetchLastQuizzes } = this.props;
    const { sortingLast, sortDirLast } = this.state;
  
    const { sortKey } = sortingOptions.find(option => option.key === sortingLast);
    const params = this.createFetchQuizzesParams(amount, sortDirLast, sortKey, offset, loadMore);

    fetchLastQuizzes(params);
  }

  refreshLastQuizzes = () => {
    const { quizzesOffsetLast } = this.state;
    this.fetchLastQuizzes(quizzesOffsetLast + QUIZZES_OFFSET_STEP, 0, false);
  };

  onDeleteQuiz = (id) => {
    this.props.deleteQuiz({ id, cb: () => {
      this.refreshAllQuizzes();
      this.closeQuizModal();
    }});
  };

  onCategoryPress = (categoryId) => {
    this.setState((prevState) => ({
      activeCategoryId: prevState.activeCategoryId === categoryId ? null : categoryId,
    }), () => {
      this.refreshAllQuizzes();
    });
  };

  refreshAllQuizzes = () => {
    this.refreshQuizzes();
    this.refreshLastQuizzes();
  };

  render () {
    const {
      categories,
      quizzes,
      quizzesCount,
      lastQuizzes,
      canLoadMoreQuizzes,
      canLoadMoreLastQuizzes,
      currentQuiz,
      t,
    } = this.props;
    
    const { categoryIndex, activeCategoryId, sorting, sortingLast, sortDirLast, isOpenModal, alreadySearched, visibleCategoriesAmount } = this.state;

    const visibleCategories = categories.slice(categoryIndex, categoryIndex + visibleCategoriesAmount);

    return (
      <div className='c-start'>
        {isOpenModal && <Modal visible footer={null} width={1250} onCancel={this.closeQuizModal}>
          <Row>
            <Col span={10}>
              <Card
                quiz={currentQuiz}
                type='singleItem'
                onDeleteQuiz={this.onDeleteQuiz}
              />
            </Col>
            <Col span={12} offset={2}>
              {currentQuiz && Array.isArray(currentQuiz.questions) && currentQuiz.questions.map(question => <div className='c-start-modal-question' key={question.title}>
                <p>{question.title}</p>
                <p>{question.description}</p>
              </div>)}
            </Col>
          </Row>
        </Modal>
        }

        <div className='c-start-slider'>
          <Row>
            <Col span={3} className='c-start-slider-column'>
              <img
                className='c-start-slider-column-icon'
                src={icons.iconArrowLeft} alt=""
                onClick={this.handleDecreaseIndex}
              />
            </Col>
            <Col span={18}>
              <Row justify='space-between'>
                {visibleCategories.map(category =>
                  <Col
                    onClick={() => { this.onCategoryPress(category._id); }}
                    key={category.title}
                    xs={9}
                    lg={6}
                    className='c-start-slider-column c-start-slider-column__nested'
                  >
                    {activeCategoryId === category._id && <CheckOutlined className='c-start-slider-column__active'/>}
                    {category.title}
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={3} className='c-start-slider-column'>
              <img
                className='c-start-slider-column-icon c-start-slider-column-icon__right'
                src={icons.iconArrowLeft} alt=""
                onClick={this.handleIncreaseIndex}
              />
            </Col>
          </Row>
        </div>
        <div className='c-start-search'>
          <Input suffix={<SearchOutlined/>} placeholder={t("generalSearch")} onChange={e => this.handleChange('search', e.target.value)}/>
        </div>

        {alreadySearched && <div className='c-start-cards'>
          <Row justify='space-between'>
            <Col span={12} className='c-start-cards-header'>{t("discoverSearchResult")}</Col>
            <Col span={12} className='c-start-cards-header c-start-cards-header__right'>
              <span>{t("discoverSortBy")}</span>
              <Select value={sorting} onChange={e => this.handleChange('sorting', e)}>
                {sortingOptions.map(option => <AntdSelect.Option key={option.key}>{t(option.title)}</AntdSelect.Option>)}
              </Select>
              {this.state.sortDir === SORT_DIR.ASC
                ?
                <SortAscendingOutlined
                  className='sort-direction-btn'
                  onClick={() => this.handleChange('sortDir', this.state.sortDir === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC)}
                />
                :
                <SortDescendingOutlined
                  className='sort-direction-btn'
                  onClick={() => this.handleChange('sortDir', this.state.sortDir === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC)}
                />
              }
            </Col>
          </Row>
	        
	        <Row className='total-count'>
		        {t('generalTotalCount', {
			        value: quizzesCount
		        })}
	        </Row>

          <Row className='c-start-cards-list' gutter={[54, 54]}>
            {quizzes.map(quiz =>
              <Col xs={12} lg={8} key={quiz._id}>
                <Card
                  quiz={quiz}
                  type='listItem'
                  openModal={this.openQuizModal}
                  onDeleteQuiz={this.onDeleteQuiz}
                />
              </Col>
            )}
          </Row>

          {!quizzes.length && <Row justify='space-between'>
            <Col span={7}/>
            <Col span={7} className='c-start-cards-error'>
              {t("generalNotFound")}
            </Col>
            <Col span={7}/>
          </Row>}

          <Row justify='space-between'>
            <Col span={7}/>
            <Col span={7} className='c-start-cards-more'>
              <Button
                className='c-start-cards-more-button'
                onClick={this.loadMoreQuizzes}
                disabled={!canLoadMoreQuizzes}
              >
                {t("discoverShowMore")}
              </Button>
            </Col>
            <Col span={7}/>
          </Row>
        </div>}

        <div className='c-start-cards'>
          <Row justify='space-between'>
            {!!lastQuizzes.length && <>
              <Col span={12} className='c-start-cards-header'>{t("discoverRecentlyReleased")}</Col>
              <Col span={12} className='c-start-cards-header c-start-cards-header__right'>
                <span>{t("discoverSortBy")}</span>
                <Select value={sortingLast} onChange={value => this.handleChangeLast('sortingLast', value)}>
                  {sortingOptions.map(option => <AntdSelect.Option key={option.key}>{t(option.title)}</AntdSelect.Option>)}
                </Select>
                {this.state.sortDirLast === SORT_DIR.ASC
                  ?
                  <SortAscendingOutlined
                    className='sort-direction-btn'
                    onClick={() => this.handleChangeLast('sortDirLast', sortDirLast === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC)}
                  />
                  :
                  <SortDescendingOutlined
                    className='sort-direction-btn'
                    onClick={() => this.handleChangeLast('sortDirLast', sortDirLast === SORT_DIR.ASC ? SORT_DIR.DESC : SORT_DIR.ASC)}
                  />
                }
              </Col>
            </>}
          </Row>

          <Row className='c-start-cards-list' gutter={[54, 54]}>
            {lastQuizzes.map(quiz =>
              <Col xs={12} lg={8} key={quiz._id} >
                <Card
                  quiz={quiz}
                  type='listItem'
                  openModal={this.openQuizModal}
                  onDeleteQuiz={this.onDeleteQuiz}
                />
              </Col>
            )}
          </Row>

          {!!lastQuizzes.length &&
          <Row justify='space-between'>
            <Col span={7}/>
            <Col span={7} className='c-start-cards-more'>
              <Button
                className='c-start-cards-more-button'
                onClick={this.loadMoreLastQuizzes}
                disabled={!canLoadMoreLastQuizzes}
              >
                {t("discoverShowMore")}
              </Button>
            </Col>
            <Col span={7}/>
          </Row>
          }
        </div>
      </div>
    );
  }
}

Discover.propTypes = {
  canLoadMoreLastQuizzes: PropTypes.any,
  canLoadMoreQuizzes: PropTypes.any,
  categories:  PropTypes.array,
  currentQuiz: PropTypes.shape({
    questions: PropTypes.array
  }),
  fetchCategories: PropTypes.func,
  fetchLastQuizzes: PropTypes.func,
  fetchQuizById: PropTypes.func,
  fetchQuizzes: PropTypes.func,
  lastQuizzes: PropTypes.array,
  quizzes: PropTypes.array,
  t: PropTypes.func,
  deleteQuiz: PropTypes.func,
  quizzesCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};