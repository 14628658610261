import { notification } from "antd";
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { MOBILE_VIEW, ROUTES } from '../../constants';
import history from '../../history';
import { QuizSessionService } from '../../services';
import * as types from '../types';
import { v4 as uuid } from 'uuid';
import { waitFor } from "./general";

function* initMobileQuiz(action) {
  // console.log("init quiz", action.payload);
  try {
    yield call(updateQuizSession, action.payload);
    yield call(initParticipant);
  } catch (error) {
    console.error('initMobileQuiz error', error);
    notification.error({ message: error.message });
    history.push(ROUTES.QUIZ.MOBILE);
  }
}

function* updateQuizSession(code) {
  try {
    const quizSessionService = yield call(() => new QuizSessionService());
    const quizSession = yield call([
      quizSessionService,
      quizSessionService.getQuizSessionByCode,
    ], code);
    yield put({ type: types.SET_QUIZ_SESSION, payload: quizSession.result });
  } catch (error) {
    console.error('updateQuizSession error', error);
  }
}

function* initParticipant() {
  const { quizSession } = yield select((state) => state);

  try {
    let storedQuizSessions = JSON.parse(localStorage.getItem('quizSessions') || '{}');
    if (!storedQuizSessions[quizSession.code]) {
      yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.NAME });
    } else {
      let uid = storedQuizSessions[quizSession.code];
      let participant = quizSession.participants.find(participant => uid === participant.id);
      if (participant) {
        yield put({ type: types.SET_PARTICIPANT, payload: participant });
        yield call(initQuizState);
      } else {
        yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.NAME });
      }
    }
  } catch (error) {
    console.error('initParticipant error', error);
  }
}


function* addParticipant(action) {
  try {
    // const { cb, ...rest } = action.payload;
    const { quizSession } = yield select((state) => state);

    const quizSessionService = yield call(() => new QuizSessionService());

    let participant = {
      name: action.payload,
      id: uuid(),
    };

    // console.log(quizSession);
    yield call([
      quizSessionService,
      quizSessionService.addParticipant,
    ], quizSession._id, participant);

    yield put({ type: types.SET_PARTICIPANT, payload: participant });
    yield call(updateQuizSession, quizSession.code);

    let storedQuizSessions = JSON.parse(localStorage.getItem('quizSessions') || '{}');
    storedQuizSessions[quizSession.code] = participant.id;
    localStorage.setItem('quizSessions', JSON.stringify(storedQuizSessions));

    yield call(initQuizState);

  } catch (error) {
    console.error('addParticipant error', error);
  }
}

function* initQuizState() {
  const { quizSession, participant } = yield select((state) => state);
  // console.log("initQuizState", quizSession.code);

  yield put({ type: types.INIT_MOBILE_WEB_SOCKETS_CHANNEL, payload: quizSession.code });

  let currentView = MOBILE_VIEW.WAITING;

  if ([MOBILE_VIEW.QUESTION, MOBILE_VIEW.QUESTION_RESULTS].includes(quizSession.view)) {
    yield call(waitFor, state => state.socketConnected === true);
    yield put({
      type: types.MOBILE_RESTART_PARTICIPANT_SESSION,
      payload: {
        code: quizSession.code,
        participantId: participant.id,
      }

    });
  } else if ([MOBILE_VIEW.QUIZ_FINISHED, MOBILE_VIEW.FINAL_RESULTS].includes(quizSession.view)) {
    yield call(waitFor, state => state.socketConnected === true);

    yield put({
      type: types.MOBILE_FETCH_PARTICIPANT_RESULT,
      payload: {
        code: quizSession.code,
        participantId: participant.id,
      }
    });
  }
  yield put({ type: types.SET_MOBILE_VIEW, payload: currentView });
}

function* commitAnswer(action) {
  yield put({ type: types.MOBILE_SEND_ANSWER, payload: action.payload });
  yield put({ type: types.MOBILE_SET_WAIT_FOR_QUESTION_RESULT, payload: true });
}

function* fetchQuestion(action) {
  let question = action.payload;
  yield put({ type: types.SET_CURRENT_QUESTION, payload: question });
  yield put({ type: types.MOBILE_SET_CURRENT_ANSWER_RESULT, payload: { points: 0, status: 'not_answered', isAnswerGiven: false } });
  yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.QUESTION });
}

function* finishQuestion(action) {
  yield put({ type: types.MOBILE_SET_WAIT_FOR_QUESTION_RESULT, payload: false });
  yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.QUESTION_RESULTS });
}

function* fetchAnswer(action) {
  let answerResult = action.payload;
  yield put({ type: types.MOBILE_SET_CURRENT_ANSWER_RESULT, payload: answerResult });
}

function* fetchResult(action) {
  const { participant } = yield select((state) => state);
  let { totalResult } = action.payload; // participants and answerStats not needed in mobile view

  let totalParticipantResult = totalResult.find(item => {
    return item.participantId === participant.id;
  });

  yield put({ type: types.SET_TOTAL_PARTICIPANT_RESULT, payload: totalParticipantResult });
}

function* finishQuiz(action) {
  yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.QUIZ_FINISHED });
}

function* finalResult(action) {
  yield put({ type: types.SET_MOBILE_VIEW, payload: MOBILE_VIEW.FINAL_RESULTS });
}


export function* quizSessionSagas() {
  yield takeEvery(types.INIT_MOBILE_QUIZ, initMobileQuiz);
  yield takeEvery(types.ADD_PARTICIPANT, addParticipant);
  yield takeEvery(types.MOBILE_COMMIT_ANSWER, commitAnswer);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FETCH_QUESTION, fetchQuestion);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FINISH_QUESTION, finishQuestion);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FETCH_RESULT, fetchResult);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FETCH_ANSWER, fetchAnswer);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FINISH_QUIZ, finishQuiz);
  yield takeEvery(types.MOBILE_WEB_SOCKET_HANDLE_FINAL_RESULT, finalResult);
}

export default quizSessionSagas;
