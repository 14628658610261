import { Button, Row, Col } from 'antd';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import { BoldOutlined, ItalicOutlined, UnderlineOutlined, OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons';


export default withTranslation()(function Info(props) {
	
	const onBoldClick = () => {
		onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
	};
	
	const onItalicClick = () => {
		onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
	};
	
	const onUnderlineClick = () => {
		onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
	};
	
	const onOrderedListClick = () => {
		onChange(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
	};
	
	const onUnorderedListClick = () => {
		onChange(RichUtils.toggleBlockType(editorState, "unordered-list-item"));
		
	};
	
	const onChange = (newState) => {
		const currentContentState = editorState.getCurrentContent();
		const newContentState = newState.getCurrentContent();
		
		if (currentContentState !== newContentState) {
			props.onUpdateSlide({ ...props.slide, text: convertToHTML(newContentState)});
		}
		
		setEditorState(newState);
		setPlaceholderHidden(shouldHidePlaceholder(newState));
	};

	const shouldHidePlaceholder = (editorState) => {
		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== 'unstyled') {
				return true;
			}
		}
		return false;
	};
	
	const [editorState, setEditorState] = useState(
		() => props.slide?.text ? EditorState.createWithContent( convertFromHTML(props.slide?.text)) : EditorState.createEmpty(),
	);

	const [placeholderHidden, setPlaceholderHidden] = useState(() => shouldHidePlaceholder(editorState));
  
  return (
	  <div className="editor-container">
		  <Row className="textarea-container">
			  <Col span={24} className={`${placeholderHidden && 'placeholder-hidden'}`}>
					<Row>
						<Button className="editor-btn" onClick={onBoldClick}><BoldOutlined /></Button>
						<Button className="editor-btn" onClick={onItalicClick}><ItalicOutlined /></Button>
						<Button className="editor-btn" onClick={onUnderlineClick}><UnderlineOutlined /></Button>
						<Button className="editor-btn" onClick={onOrderedListClick}><OrderedListOutlined /></Button>
						<Button className="editor-btn" onClick={onUnorderedListClick}><UnorderedListOutlined /></Button>
					</Row>
				  <Editor
					  placeholder={props.t("createQuizInfoPlaceholder")}
					  editorState={editorState}
					  onChange={onChange}
					  stripPastedStyles={true}
				  />
			  </Col>
		  </Row>
	  </div>
  );
});
