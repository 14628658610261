import { ROUTES } from '../../constants';
import * as types from '../types';

const moduleReducers = {
  quizSession: setQuizSession,
  quizSessionRedirect: setQuizzSessionRedirect,
  currentQuestion: setCurrentQuestion,
  totalResult: setTotalResult
};

function setQuizSession(state = {}, action) {
  switch (action.type) {
    case types.SET_QUIZ_SESSION:
      return action.payload;
    default:
      return state;
  }
}

function setQuizzSessionRedirect(state=ROUTES.EDITOR.MY_QUIZZES, action ){  
  switch (action.type) {
    case types.SET_QUIZ_SESSION_REDIRECT:
      return action.payload;
    default:
      return state;
  }
}

function setCurrentQuestion(state=null, action ){
  switch (action.type) {
    case types.SET_CURRENT_QUESTION:
      return action.payload;
    default:
      return state;
  }
}
function setTotalResult(state=[], action ){
  switch (action.type) {
    case types.SET_TOTAL_RESULT:
      return action.payload;
    default:
      return state;
  }
}


export default moduleReducers;
