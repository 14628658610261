import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

class ErrorPage extends Component {
  render () {
    return (
      <h1>{this.props.t("pageNotFound")}</h1>
    );
  }
}

export default withRouter(withTranslation()(ErrorPage));
