import { bindActionCreators, compose } from 'redux';
import DeletedUser from './DeletedUser';
import { recoverUser, logout } from '../../../store/actions/user';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        recoverUser,
        logout,
      },
      dispatch
    );
  }


function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
  
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(DeletedUser));
  

