import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import MobileQuiz from './MobileQuiz';
import { initMobileQuiz, addParticipant, commitAnswer } from "../../../store/actions/quiz_mobile";
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addParticipant,
      initMobileQuiz,
      commitAnswer
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    quizSession: state.quizSession,
    participant: state.participant,
    mobileView: state.mobileView,
    mobileStep: state.mobileStep,
    currentQuestion: state.currentQuestion,
    waitForQuestionResult: state.waitForQuestionResult,
    currentQuestionResult: state.currentQuestionResult,
    totalParticipantResult: state.totalParticipantResult,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(MobileQuiz));
