import ApiService from './api';

export default class QuizSessionService extends ApiService {
  
  async createQuizSession(data) {
    return this.post(`/quiz_session/`, data);
  }

  async getQuizSessionByQuizId(quizId) {
    return this.get(`/quiz_session/quiz/${quizId}/`);
  }

  async getQuizSessionByCode(code) {
    return this.get(`/quiz_session/code/${code}/`);
  }

  async addParticipant(sessionId, data) {
    return this.post(`/quiz_session/${sessionId}/participant`, data);
  }

  async startQuizSession(sessionId) {
    return this.post(`/quiz_session/${sessionId}/start`);
  }

  async finishQuestion(sessionId, questionId) {
    return this.post(`/quiz_session/${sessionId}/finish/${questionId}`);
  }

}
