import {
  FETCH_TAGS,
  DELETE_TAG,
  CREATE_TAG
} from '../types';

export function fetchTags(payload) {
  return {
    type: FETCH_TAGS,
    payload,
  };
}

export function deleteTag(payload) {
  return {
    type: DELETE_TAG,
    payload,
  };
}

export function createTag(payload) {
  return {
    type: CREATE_TAG,
    payload,
  };
}

