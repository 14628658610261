import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import { Header } from '../../../components';
import './PublicRoute.scss';
import PropTypes from 'prop-types';

const { Content } = Layout;

function PublicRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='wrapper'>
          <Header />
          <Content className='c-publicContent'>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
}


PublicRoute.propTypes ={
  component:PropTypes.func||PropTypes.object
};
export default PublicRoute;