import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import './SpinnerOverlay.scss';

export const SpinnerOverlay = () => {
    const showingSpinner = useSelector(state => state.showingSpinner);

    if (!showingSpinner) {
        return null;
    }

    return (
        <div className='overlay-container'>
            <Spin size='large' />
        </div>
    );
};
