import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from '../../../components';
import { withTranslation } from "react-i18next";

function MobileName(props) {
    let { t, onHandleAddParticipant } = props;
    let [name, setName] = React.useState('');
    const handleAddParticipant = () => {
        onHandleAddParticipant(name);
    };
    return (
        <div className='mobLogin'>
            <div className='mobLogin__content'>
                <div className='mobLogin__input'>
                    <Input
                        placeholder={t("mobileQuizEnterName")}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className='mobLogin__button'>
                    <Button
                        block
                        onClick={handleAddParticipant}
                        disabled={!name}
                    >
                        {t("mobileQuizRegister")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

MobileName.propTypes = { 
    t: PropTypes.func.isRequired, 
    onHandleAddParticipant: PropTypes.func.isRequired 
};

export default withTranslation()(MobileName);
