import React from 'react';
import { Modal } from 'antd';
import './Modal.scss';
import PropTypes from 'prop-types';

function ModalComponent (props) {
  const { children } = props;

  return (
    <Modal className='c-modal' {...props}>
      { children }
    </Modal>
  );
}

ModalComponent.propTypes = {
  children:PropTypes.object,
};
export default ModalComponent;