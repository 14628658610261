import cn from "classnames";
import React from "react";
import Image from "../../../../components/Image/index";
import { IMAGE_AVAILABLE_SIZES } from "../../../../constants";

export default function Info ({ question, showTitle = true }) {
  return (
    <div className={cn({
      'quiz': true,
      'quiz_without-answers': true,
    })}>

      <div className={cn({
        'quiz__content': true,
        'quiz__content_withoutImage': !question.image
      })}>
        {showTitle &&
        <div className={cn({
          'quiz__title': true,
          'quiz__title_halfWidth': !question.image
        })}>{question.title}</div>
        }

        {question.text &&
        <div className='quiz__description'><div dangerouslySetInnerHTML={{ __html: question.text }}/></div>
        }

        {question.image &&
        <div className='quiz__image'>
          <Image image={question.image} size={IMAGE_AVAILABLE_SIZES.PRENSENTATION} noFallback></Image>
        </div>}
      </div>
    </div>
  );
}
