import MobileRoute from './MobileRoute';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router-dom";

// export default MobileRoute
  function mapStateToProps(state) {
    return {
        participant: state.participant
    };
  }
  
  export default compose(
    withRouter,
    connect(mapStateToProps, null)
  )(MobileRoute);
  