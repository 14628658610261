import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import { fetchQuizzes, fetchLastQuizzes, fetchQuizById, deleteQuiz } from '../../../store/actions/quiz';
import { fetchCategories } from '../../../store/actions/category';
import Discover from './Discover';
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchQuizzes,
      fetchLastQuizzes,
      fetchCategories,
      fetchQuizById,
      deleteQuiz,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    quizzes: state.quizzes,
    quizzesCount: state.quizzesCount,
    lastQuizzes: state.lastQuizzes,
    currentQuiz: state.currentQuiz,
    canLoadMoreQuizzes: state.canLoadMoreQuizzes,
    canLoadMoreLastQuizzes: state.canLoadMoreLastQuizzes,
    categories: state.categories,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Discover));
