import {
  FETCH_FOLDERS,
  CREATE_FOLDER
} from '../types';

export function fetchFolders(payload) {
  return {
    type: FETCH_FOLDERS,
    payload,
  };
}

export function createFolder(payload) {
  return {
    type: CREATE_FOLDER,
    payload,
  };
}

