import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import EditorRoute from './EditorRoute';

import { setBuilderType, setBuilderStep } from '../../store/actions/quiz_builder';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBuilderType,
      setBuilderStep,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    builderType: state.builderType,
    builderStep: state.builderStep,
    slides: state.slides,
    saving: state.saving,
    user: state.user,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditorRoute);
