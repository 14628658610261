import { combineReducers } from 'redux';

import configReducer from './config';
import authReducer from './auth';
import quizReducer from './quiz';
import quizBuilderReducer from './quiz_builder';
import folderReducer from './folder';
import tagReducer from './tag';
import categoryReducer from './category';
import userReducer from './user';
import quizPresentationReducer from './quiz_presentation';
import quizSessionReducer from './quiz_session';
import quizMobileReducer from './quiz_mobile';

const rootReducer = combineReducers({
  ...configReducer,
  ...authReducer,
  ...quizReducer,
  ...quizBuilderReducer,
  ...folderReducer,
  ...tagReducer,
  ...categoryReducer,
  ...userReducer,
  ...quizPresentationReducer,
  ...quizSessionReducer,
  ...quizMobileReducer,
});

export default rootReducer;
