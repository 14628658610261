import { v4 as uuid } from 'uuid';
import i18n from '../../i18n';

import * as types from '../types';


const moduleReducers = {
  slides: setSlides,
  quiz: setQuiz,
  builderType: setType,
  builderStep: setStep,
  saving: setSaving,
  showingSpinner: setShowingSpinner,
};

function setSlides(state = [], action) {
  switch (action.type) {
    case types.ADD_SLIDE:
      const { cb, ...rest } = action.payload;
      const id = uuid();
      cb(id);
      return [...state, { id, ...rest }];
    case types.UPDATE_SLIDE:
      const index = state.findIndex(slide => slide.id === action.payload.id);
      return [
        ...state.slice(0, index),
        action.payload,
        ...state.slice(index + 1)
      ];
    case types.DELETE_SLIDE:
      return state.filter(slide => slide.id !== action.payload.id);
    case types.SET_SLIDES:
      return action.payload;
    case types.CLEAN_BUILDER:
      return [];
    default:
      return state;
  }
}

function setQuiz(state = { folderIds: [], tags: [] }, action) {
  switch (action.type) {
    case types.UPDATE_QUIZ:
      return { ...state, ...action.payload };
    case types.CLEAN_BUILDER:
      return { folderIds: [], tags: [], title: i18n.t("createQuizUntitled") };
    case types.SET_QUIZ:
      return state;
    default:
      return state;
  }
}

function setType(state = 'builder', action) {
  switch (action.type) {
    case types.SET_BUILDER_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function setStep(state = 1, action) {
  switch (action.type) {
    case types.SET_BUILDER_STEP:
      return action.payload;
    default:
      return state;
  }
}

function setSaving(state = false, action) {
  switch (action.type) {
    case types.SET_SAVING:
      return action.payload;
    default:
      return state;
  }
}

function setShowingSpinner(state = false, action) {
  switch (action.type) {
    case types.SET_SHOWING_SPINNER:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
