import ApiService from './api';

export default class UserService extends ApiService {

  async getUser(userId) {
    return this.get(`/user/${userId}`);
  }

  async getUsers(params) {
    return this.get('/user/',params);
  }

  async updateUser(user) {
    return this.put(`/user/${user._id}`, user);
  }
  async deleteUser(userId) {
    return this.delete(`/user/${userId}`);
  }

  async createInviteCodes(createInviteCodesParams){
    return this.post(`/invite-code/`, createInviteCodesParams);
  }
  async checkInviteCode(inviteCode){
    return this.get(`/invite-code/${inviteCode}`);
  }

  async recoverUser(userId) {
    return this.post(`/user/recovery/${userId}`);
  }
}

