import React from 'react';
import { StarOutlined, StarFilled, EllipsisOutlined } from "@ant-design/icons";
import { Tag, Button } from '../';
import history from '../../history';

import './Card.scss';
import { IMAGE_AVAILABLE_SIZES, ROUTES } from '../../constants';
import Image from "../Image/index";
import { PlaceholderImage } from '../../assets/svg';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { Menu, Dropdown, Popconfirm } from 'antd';


function Card(props) {
  let { quiz, type, favoriteQuiz, unfavoriteQuiz, user } = props;
  if (!quiz) {
    return <div />;
  }

  const isListItem = type === 'listItem';

  const handleStarClick = () => {
    if (quiz.isFavorite) {
      unfavoriteQuiz(quiz._id);
    } else {
      favoriteQuiz(quiz._id);
    }
  };

  const isOwner = user._id === quiz.creatorId;

  return isListItem ? <CardList {...props} handleStarClick={handleStarClick} isOwner={isOwner}/> : <CardSingle {...props}  handleStarClick={handleStarClick} isOwner={isOwner}/>;
  
}

Card.propTypes = {
  quiz: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  type: PropTypes.string,
  openModal: PropTypes.func,
  favoriteQuiz: PropTypes.func,
  unfavoriteQuiz: PropTypes.func,
  copyQuiz: PropTypes.func,
  createQuizSession: PropTypes.func,
  deleteQuiz: PropTypes.func,
};

function CardList(props) {
  let { quiz,  openModal,  copyQuiz, createQuizSession, user, location, handleStarClick, isOwner, t, onDeleteQuiz } = props;

  const menu = (
    <Menu className='c-card-dropdown-container'>
      <Menu.Item onClick={() => createQuizSession({ quizId: quiz._id, redirect: location.pathname })}>
        <div className='c-card-menu-item'>{t("cardPlay")}</div>
      </Menu.Item>
      <Menu.Item>
        {isOwner
        ?
        <div className='c-card-menu-item' onClick={() => { copyQuiz(quiz._id); }}>{t("cardCopyQuiz")}</div>
        :
        <Popconfirm
          title={t("confirmCopyQuiz")}
          onConfirm={() => { copyQuiz(quiz._id); }}
          okText={t("generalYes")}
          cancelText={t("generalNo")}
        >
          <div className='c-card-menu-item'>{t("cardCopyQuiz")}</div>
        </Popconfirm>
        }
      </Menu.Item>
      {quiz.creatorId === user._id &&
      <Menu.Item onClick={() => history.push(ROUTES.EDITOR.EDIT_QUIZ.replace(':id', quiz._id))}>
        <div className='c-card-menu-item'>{t("cardEdit")}</div>
      </Menu.Item>
      }
      {quiz.creatorId === user._id &&
      <Menu.Item>
        <Popconfirm
          title={t("quizDeleteConfirmation")}
          onConfirm={() => { onDeleteQuiz(quiz._id); }}
          okText={t("generalYes")}
          cancelText={t("generalNo")}
        >
          <div className='c-card-menu-item'>{t("cardDelete")}</div>
        </Popconfirm>
      </Menu.Item>
      }
    </Menu>
  );

  return (
    <div className='c-card'>
      <div className='c-card-image' onClick={() => openModal(quiz)}>
        {quiz.image && Object.keys(quiz.image).length > 0 ?
          <Image image={quiz.image} size={IMAGE_AVAILABLE_SIZES.THUMBNAIL} /> :
          <div className="image-container landscape">
            <PlaceholderImage number={parseInt(quiz._id, 16)} />
          </div>
        }
        <div className='c-card-image-tags'>
          {quiz.level && <Tag>{quiz.level}</Tag>}
          <Tag>{t("cardQuestions", {
            value: quiz.questions?.length,
          })}</Tag>
        </div>
      </div>
      <div className='c-card-body'>
        <div className='c-card-category'>{quiz?.category?.title||"&nbsp"}</div>
        <div className='c-card-title'>{quiz.title}</div>
        <div className='c-card-date'>
          {(new Intl.DateTimeFormat('de-DE', {})).format(new Date(quiz.createdAt))}
        </div>
        <Dropdown overlay={menu} placement='bottomRight' trigger='click' className='c-card-menu-dots'>
          <EllipsisOutlined/>
        </Dropdown>
        <div className='c-card-author'>{quiz.creator?.firstName?.length && quiz?.creator.lastName?.length ? `${quiz.creator?.firstName} ${quiz.creator.lastName}` : quiz.creator?.username}</div>

        <div className='c-card-more'>
          {quiz.isFavorite
            ? <StarFilled onClick={handleStarClick} />
            : <StarOutlined onClick={handleStarClick} />
          }
        </div>
      </div>

      <div className='c-card-line' />
    </div>
  );
}
CardList.propTypes = {
  quiz: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  type: PropTypes.string,
  openModal: PropTypes.func,
  favoriteQuiz: PropTypes.func,
  unfavoriteQuiz: PropTypes.func,
  copyQuiz: PropTypes.func,
  createQuizSession: PropTypes.func,
  onDeleteQuiz: PropTypes.func,
  handleStarClick: PropTypes.func, 
  isOwner: PropTypes.bool,
  t: PropTypes.func,
};
function CardSingle(props) {
  let { quiz,  openModal,copyQuiz, createQuizSession,  location, handleStarClick, isOwner, t, onDeleteQuiz } = props;
  return <div className='c-card'>
  <div className='c-card-image' onClick={() => openModal(quiz)}>
    {quiz.image && Object.keys(quiz.image).length > 0 ?
      <Image image={quiz.image} size={IMAGE_AVAILABLE_SIZES.THUMBNAIL} /> :
      <div className="image-container landscape">
        <PlaceholderImage number={parseInt(quiz._id, 16)} />
      </div>
    }
  </div>
  <div className='c-card-body'>
    {Array.isArray(quiz.categories) &&
    quiz.categories.map((category) => (
	    <div key={category.title} className='c-card-category'>{category.title}</div>
    ))
    }
    <div className='c-card-title'>{quiz.title}</div>
    <div className='c-card-description'>{t("cardQuestions", {
      value: quiz.level === undefined ? quiz.questions?.length : `${quiz.level} - ${quiz.questions?.length}`,
    })}</div>
    <div className='c-card-star'>
      {quiz.isFavorite
        ? <StarFilled onClick={handleStarClick} />
        : <StarOutlined onClick={handleStarClick} />
      }
    </div>
    <div className='c-card-author'>{t("cardOn")} {(new Intl.DateTimeFormat('de-DE', {})).format(new Date(quiz.createdAt))} {t("cardCreatedBy")} {quiz.creator?.firstName?.length && quiz.creator?.lastName?.length ? `${quiz.creator.firstName} ${quiz.creator.lastName}` : quiz.creator?.username}</div>
    <div className='c-card-buttons'>
      <Button onClick={() => createQuizSession({ quizId: quiz._id, redirect: location.pathname })}>{t("cardPlay")}</Button>
      {isOwner
      ?
      <Button onClick={() => { copyQuiz(quiz._id); }}>{t("cardCopyQuiz")}</Button>
      :
      <Popconfirm
        title={t("confirmCopyQuiz")}
        onConfirm={() => { copyQuiz(quiz._id); }}
        okText={t("generalYes")}
        cancelText={t("generalNo")}
      >
        <Button>{t("cardCopyQuiz")}</Button>
      </Popconfirm>
      }
      {isOwner && <Button onClick={() => history.push(ROUTES.EDITOR.EDIT_QUIZ.replace(':id', quiz._id))}>{t("cardEdit")}</Button>}
      {isOwner && 
      <Popconfirm
          title={t("quizDeleteConfirmation")}
          onConfirm={() => { onDeleteQuiz(quiz._id); }}
          okText={t("generalYes")}
          cancelText={t("generalNo")}
        >
        <Button>{t("cardDelete")}</Button>
      </Popconfirm>
      }
    </div>
    

   <div className='c-card-tags'>
      {quiz.tags.map(tag => <span key={tag}>#{tag}</span>)}
    </div>
    
  </div>

  
</div>;
}
CardSingle.propTypes = {
  quiz: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  type: PropTypes.string,
  openModal: PropTypes.func,
  favoriteQuiz: PropTypes.func,
  unfavoriteQuiz: PropTypes.func,
  copyQuiz: PropTypes.func,
  createQuizSession: PropTypes.func,
  onDeleteQuiz: PropTypes.func,
  handleStarClick: PropTypes.func, 
  isOwner: PropTypes.bool,
  t: PropTypes.func,

};

export default withTranslation()(Card);
