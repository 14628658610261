import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { colors, QUESTION_TYPE } from '../../../constants';
import cn from 'classnames';

function MobileQuestionMultiple({ question, onAnswerQuestion, t }) {
  let [answerIds, setAnswerIds] = useState([]);
  let rightAnswers = 0;
  if (question?.answers?.length > 0) {
    rightAnswers = question.answers.reduce((acc, cur) => cur?.right ? acc + 1 : acc, 0);
  }

  const onCheckAnswer = (answer) => {
    let commitAnswer = true;
    let newAnswerIds = [];
    if (answerIds.includes(answer.id)) {
      newAnswerIds = answerIds.filter(i => i !== answer.id);
    } else {
      newAnswerIds = [...answerIds, answer.id];
    }
    setAnswerIds(newAnswerIds);
    if (question.type === QUESTION_TYPE.MULTIPLE && question?.answers?.length > 0) {
    }
    if (newAnswerIds.length < rightAnswers) {
      commitAnswer = false;
    }
    if (commitAnswer) onAnswerQuestion(newAnswerIds);
  };

  return (
    <>
      {question.type === QUESTION_TYPE.MULTIPLE && <>
        {t("mobileQuestionViewChoose")} {rightAnswers} {rightAnswers > 1 || rightAnswers === 0 ? t("mobileQuestionViewAnswers") : t("mobileQuestionViewAnswer")}
      </>}
      <ul className='quizMobile__answers'>
        {question.answers.map((answer, idx) => (
          <li
            key={idx}
            className={cn({
              'quizMobile__answersItem': true,
              'quizMobile__answersItem_selected': answerIds.includes(answer.id)
            })}

            style={{ backgroundColor: colors[idx] }}
            onClick={() => onCheckAnswer(answer)}
          >
            <span>{idx + 1}</span>
          </li>
        ))}
      </ul>

    </>
  );
}

MobileQuestionMultiple.propTypes = {
  question: PropTypes.object,
  onAnswerQuestion: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation()(MobileQuestionMultiple);
