import { notification } from "antd";
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ROUTES } from '../../constants';
import history from '../../history';
import { QuizSessionService } from '../../services';
import * as types from '../types';
import { waitFor } from './general';


function* createQuizSession(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService(token));

    const quizSession = yield call([
      quizSessionService,
      quizSessionService.createQuizSession,
    ], action.payload);
    // console.log(quizSession);
    yield put({
      type: types.SET_QUIZ_SESSION_REDIRECT,
      payload: action.payload.redirect||ROUTES.EDITOR.MY_QUIZZES
    });
    history.push(ROUTES.QUIZ.WELCOME.replace(':code', quizSession.code));
  } catch (error) {
    console.error('createQuizSession error', error);
  }
}

function* fetchQuizSession(action) {
  try {
    const { id, cb } = action.payload;
    // yield call(waitFor, state => state.token != null);
    // const { token } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService());

    const quizSession = yield call([
      quizSessionService,
      quizSessionService.getQuizSessionByQuizId,
    ], id);


    yield put({
      type: types.SET_QUIZ_SESSION,
      payload: quizSession.result
    });

    cb(quizSession.result);
  } catch (error) {
    console.error('fetchQuizSession error', error);
  }
}

function* fetchQuizSessionByCode(action) {
  const { payload: { code, cb } } = action;
  try {
    // yield call(waitFor, state => state.token != null);
    // const { token } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService());

    const quizSession = yield call([
      quizSessionService,
      quizSessionService.getQuizSessionByCode,
    ], code);

    yield put({
      type: types.SET_QUIZ_SESSION,
      payload: quizSession.result
    });
    yield put({
      type: types.SET_PRESENTATION_TYPE,
      payload: quizSession.result.view
    });
    yield put({
      type: types.SET_PRESENTATION_STEP,
      payload: quizSession.result.step
    });
    yield put({
      type: types.PRESENTATION_SET_PARTICIPANTS,
      payload: quizSession.result.participants,
    });

    if(cb) cb(quizSession.result);
    
  } catch (error) {
    notification.error({message:error.message});
    console.error('fetchQuizSessionByCode error', error, error.message);
  }
}

function* startQuizSession(action) {
  const { payload: { cb, _id } } = action;
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const quizSessionService = yield call(() => new QuizSessionService(token));

    const quizSession = yield call([
      quizSessionService,
      quizSessionService.startQuizSession,
    ], _id);

    yield put({
      type: types.SET_QUIZ_SESSION,
      payload: quizSession
    });

    cb();
  } catch (error) {
    console.error('startQuizSession error', error);
  }
}


export function* quizSessionSagas(){
  yield takeEvery(types.CREATE_QUIZ_SESSION, createQuizSession);
  yield takeEvery(types.FETCH_QUIZ_SESSION, fetchQuizSession);
  yield takeEvery(types.FETCH_QUIZ_SESSION_BY_CODE, fetchQuizSessionByCode);
  yield takeEvery(types.START_QUIZ_SESSION, startQuizSession);
 
}

export default quizSessionSagas;
