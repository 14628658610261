import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import * as types from '../types';
import { FolderService } from '../../services';
import { waitFor } from './general';

function* fetchFolders() {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const folderService = yield call(() => new FolderService(token));

    const folders = yield call([
      folderService,
      folderService.getFolders,
    ]);

    yield put({
      type: types.SET_FOLDERS,
      payload: folders.result,
    });

  } catch (error) {
    console.error('fetchFolders error', error);
  }
}

function* createFolder(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const folderService = yield call(() => new FolderService(token));

    yield call([
      folderService,
      folderService.createFolder,
    ], action.payload);

    yield call(fetchFolders);

  } catch (error) {
    console.error('createFolder error', error);
  }
}

export function* foldersSagas() {
  yield takeEvery(types.FETCH_FOLDERS, fetchFolders);
  yield takeEvery(types.CREATE_FOLDER, createFolder);
}

export default foldersSagas;
