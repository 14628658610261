import ApiService from './api';

export default class FolderService extends ApiService {
  async getFolders() {
    return this.get('/folder/');
  }

  async createFolder(data) {
    return this.post('/folder/', data);
  }
}
