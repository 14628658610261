
import * as XLSX from 'xlsx';
import i18n from '../i18n';
import { QUESTION_TYPE } from '../constants';

export default class ExportService {

    exportQuizResults(presentationQuiz, quizSession) {
        try {
            const firstColumnIndex = 0;

            const addEmptyRow = (data) => {
                data.push({[firstColumnIndex]: ''});
            };

            var data = [
                { [firstColumnIndex]: i18n.t('reportQuizResults', { name: presentationQuiz.title }) },
                { [firstColumnIndex]: i18n.t('reportParticipants'), [firstColumnIndex + 1]: quizSession?.participants?.length },
            ];

            addEmptyRow(data);

            const questionsAmount = presentationQuiz?.questions?.length || 0;
            for(let i = 0; i < questionsAmount; i++) {
                const question = presentationQuiz.questions[i];
                data.push({
                    [firstColumnIndex]: i18n.t('reportQuestion', { number: i + 1 }),
                    [firstColumnIndex + 1]: question.title || '',
                });

                const answerTitles = {};
                const answerCounts = {};
                let totalAnswers = 0;

                if (question?.type === QUESTION_TYPE.OPEN_QUESTION) {
                    addEmptyRow(data);
                
                    if (quizSession?.participants?.length > 0) {
                        for(const participant of quizSession.participants) {
                            const currentAnswerColumnIndex = firstColumnIndex + Object.keys(answerTitles).length + 1;

                            if (participant?.answers?.length > 0) {
                                const participantAnswer = participant.answers.find((answer) => answer.questionId === question.id);

                                if (answerCounts[currentAnswerColumnIndex] === undefined) {
                                    answerCounts[currentAnswerColumnIndex] = 0;
                                    answerCounts[firstColumnIndex] = i18n.t('reportAnswers');
                                }

                                const currentAnswerIndex = Object.values(answerTitles).indexOf(participantAnswer?.answer);
                                if (currentAnswerIndex === -1) {
                                    answerTitles[currentAnswerColumnIndex] = participantAnswer?.answer ?? i18n.t('reportNoAnswer');
                                    answerCounts[currentAnswerColumnIndex]++;
                                } else {
                                    answerCounts[currentAnswerIndex + 1]++;
                                }
                                totalAnswers++;
                            }
                        }
                    }
                } else if (question?.answers?.length > 0) {
                    addEmptyRow(data);

                    for(const answer of question?.answers) {
                        const currentAnswerColumnIndex = firstColumnIndex + Object.keys(answerTitles).length + 1;

                        answerTitles[currentAnswerColumnIndex] = answer.title;

                        if (quizSession?.participants?.length > 0) {
                            for(const participant of quizSession.participants) {
                                if (participant?.answers?.length > 0) {
                                    const participantAnswer = participant.answers.find((answer) => answer.questionId === question.id);

                                    if (answerCounts[currentAnswerColumnIndex] === undefined) {
                                        answerCounts[currentAnswerColumnIndex] = 0;
                                        answerCounts[firstColumnIndex] = i18n.t('reportAnswers');
                                    }

                                    if (participantAnswer?.answerIds?.indexOf(answer.id) > -1) {
                                        answerCounts[currentAnswerColumnIndex]++;
                                        totalAnswers++;
                                    }
                                }
                            }
                        }
                    }
                }

                const lastAnswerColumnIndex = firstColumnIndex + Object.keys(answerTitles).length;

                if (Object.keys(answerTitles).length > 0) {
                    answerTitles[lastAnswerColumnIndex + 1] = i18n.t('reportTotal');
                    data.push(answerTitles);
                }
                if (Object.keys(answerCounts).length > 0) {
                    answerCounts[lastAnswerColumnIndex + 1] = totalAnswers;
                    data.push(answerCounts);
                }

                addEmptyRow(data);
                addEmptyRow(data);
            }

            const ws = XLSX.utils.book_new();

            XLSX.utils.sheet_add_json(ws, data, { origin: 'A1', skipHeader: true });
            const wb = { Sheets: { [i18n.t('reportReport')]: ws }, SheetNames: [i18n.t('reportReport')] };

            XLSX.writeFile(wb, `${presentationQuiz.title}.xlsx`);
        } catch (error) {
            console.error('downloadQuizResults service error', error);
        }
    }
}