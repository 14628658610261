import i18n from '../i18n';
import {QUESTION_TYPE} from '../constants';

export function isQuizValid(quiz){
    let result = true;
    if (!isQuizMetaValid(quiz))  result = false;
    if(!areQuestionsValid(quiz))  result = false;
    return result;
}

export function isQuizMetaValid(quiz){
    if (!quiz?.title?.length) return false;
    if (quiz?.title === i18n.t("createQuizUntitled")) return false;
    if (!quiz?.categoryIds?.length ) return false;
    return true;
}

export function areQuestionsValid(quiz){
    return quiz.questions.reduce((acc, question)=> isQuestionValid(question)?acc:false, true);
}

export function isQuestionValid(question){
    if (!question?.title?.length) return false;
    if (!isAnswerGiven(question)) return false;
    if (!isAnswerValid(question)) return false;
    if (!isCorrectAnswerGiven(question)) return false;
    return true;
}

export function isAnswerGiven(question){
    if([QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.QUESTIONAIRE].includes(question.type)){
        return question?.answers?.length > 1;
    }
    return true;
}
export function isAnswerValid(question){
    if([QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.QUESTIONAIRE, QUESTION_TYPE.TRUE_FALSE].includes(question.type)){
        return question.answers.find(answer => answer?.title === '')?false:true;
    }
    return true;
}

export function isCorrectAnswerGiven(question){
    if([QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.TRUE_FALSE].includes(question.type)){
        return question.answers.find(answer => answer?.right === true)?true:false;
    }
    return true;
}
