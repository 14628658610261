import cn from 'classnames';
import React from 'react';
import PropTypes from "prop-types";

import { withTranslation } from 'react-i18next';
import { FailureIcon, SuccessIcon } from '../../../assets/svg';
import { QUESTION_TYPE } from '../../../constants';
import './MobileQuestionResult.scss';

function MobileQuestionResult ( {questionResult, question, totalParticipantResult, t}) {
  const { type } = question;
  const { status, points } = questionResult;

  return (
    <div className={cn({
      'mq-result': true,
      'mq-result_success': status === 'success' || type === QUESTION_TYPE.INFO,
      'mq-result_failure': (status === 'failure' || status === 'not_answered') && type !== QUESTION_TYPE.INFO
    })} style={{
    	minHeight: window.innerHeight - 85,
    }}>
      <div className='mq-result__content'>
        {[QUESTION_TYPE.QUESTIONAIRE, QUESTION_TYPE.OPEN_QUESTION].includes(type)
          ?
          <div className='mq-result__title thanks-for-answer'>
            {status === 'not_answered'?t("mobileQuestionResultNotAnswered"):t("mobileThanksForAnswer")}
          </div>
          :
          <div className='mq-result__icon'>
              {(status === 'success' || type === QUESTION_TYPE.INFO) ? <SuccessIcon /> : <FailureIcon />}
          </div>
        }

        {[QUESTION_TYPE.TRUE_FALSE, QUESTION_TYPE.MULTIPLE].includes(type) && <div className='mq-result__title'>
          {status === 'success' && t("mobileQuestionResultRight") }
          {status === 'failure' && t("mobileQuestionResultIncorrect")}
          {status === 'not_answered' && t("mobileQuestionResultNotAnswered")}
        </div>}

        {[QUESTION_TYPE.TRUE_FALSE, QUESTION_TYPE.MULTIPLE].includes(type) && points>0 && <div className='mq-result__subTitle'>
          {status === 'success' ? t("mobileQuestionResultPlusPoints", {
            value: points,
          }) : t("mobileQuestionResultNoPoints")}
        </div>}

        {totalParticipantResult && !!totalParticipantResult?.diff && <div className='mq-result__text'>
          {t("mobileQuestionResultBehind", {
            value: totalParticipantResult.diff,
          })}
        </div>}
      </div>

      {totalParticipantResult && <div className='mq-result__footer'>
        <div className='mq-result__footerItem'>{t("mobileQuestionResultPlace", {
          value: totalParticipantResult.place || 0,
        })}</div>
        <div className='mq-result__footerItem'>{t("mobileQuestionResultPoints", {
          value: totalParticipantResult.totalPoints,
        })}</div>
      </div>}
    </div>
  );
};

MobileQuestionResult.propTypes = {
  questionResult: PropTypes.object,
  question: PropTypes.object,
  totalParticipantResult: PropTypes.object,
  t: PropTypes.func
};


export default withTranslation()(MobileQuestionResult);
