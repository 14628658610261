import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import Tags from './Tags';
import { fetchTags, deleteTag, createTag } from '../../../store/actions/tag';
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchTags,
      deleteTag,
      createTag
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    tags: state.tags,
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Tags));
