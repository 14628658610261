import React from 'react';
import { Input } from 'antd';

import './Input.scss';

const AntdInput = (props) => {
  return (
    <Input className='c-input' {...props} />
  );
};

AntdInput.TextArea = props => <Input.TextArea className='c-input__textarea' {...props} />;

export default AntdInput;
