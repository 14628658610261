import { Progress } from 'antd';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { MOBILE_VIEW, QUESTION_TYPE, QUIZ_SESSION_STATES } from "../../../constants";
import { MobileFinalResult } from '../MobileFinalResult';
import { MobileName, MobileWaitingToStart } from '../MobileLogin';
import MobileQuestionResult from '../MobileQuestionResult';
import MobileQuestionView from '../MobileQuestionView';
import './MobileQuiz.scss';

const VIEW = {
  CODE: 'CODE',
  NAME: 'NAME',
  WAITING: 'WAITING',
  QUESTION: QUIZ_SESSION_STATES.QUESTION,
  QUESTION_RESULTS: QUIZ_SESSION_STATES.QUESTION_RESULTS,
  QUIZ_FINISHED: QUIZ_SESSION_STATES.QUIZ_FINISHED,
  FINAL_RESULTS: QUIZ_SESSION_STATES.FINAL_RESULTS
};

export default class MobileQuiz extends Component {
  static propTypes = {
    match: PropTypes.object,
    initMobileQuiz: PropTypes.func,
    commitAnswer: PropTypes.func,
    addParticipant: PropTypes.func,
    quizSession: PropTypes.object,
    participant: PropTypes.object,
    mobileStep: PropTypes.number,
    mobileView: PropTypes.string,
    currentQuestion: PropTypes.object,
    waitForQuestionResult: PropTypes.bool,
    currentQuestionResult: PropTypes.object,
    totalParticipantResult: PropTypes.object,
    t: PropTypes.func,
  }

  componentDidMount() {
    let { match, initMobileQuiz } = this.props;
    initMobileQuiz(match.params?.code);
  }

  answerQuestion = result => {
    const { participant, currentQuestion, commitAnswer } = this.props;

    const body = {
      name: participant.name,
      questionId: currentQuestion.id,
      participantId: participant.id,
    };
    if (currentQuestion.type === QUESTION_TYPE.OPEN_QUESTION) {
      body.answer = result;
    } else {
      body.answerIds = result;
    }
    commitAnswer(body);
  }

  getCurrentQuestionIndex = () => {
    const { quizSession, currentQuestion } = this.props;
    return (quizSession && quizSession.quiz &&
      Array.isArray(quizSession.quiz.questions) && currentQuestion
      && quizSession.quiz.questions.findIndex((question) => question.id === currentQuestion.id) + 1) || 0;
  };

  getTotalAnswersAmount = () => {
    const { quizSession } = this.props;
    return (quizSession && quizSession.quiz && Array.isArray(quizSession.quiz.questions) && quizSession.quiz.questions.length) || 0;
  };

  render() {
    const { t, mobileView, addParticipant, currentQuestion, totalParticipantResult, currentQuestionResult, waitForQuestionResult } = this.props;
    return (
      <>
        {[VIEW.QUESTION_RESULTS, VIEW.QUESTION].includes(mobileView) &&
          <div className="progress-container">
            <Progress className="progress-inner-container" percent={Math.ceil((this.getCurrentQuestionIndex() / this.getTotalAnswersAmount()) * 100)} showInfo={false} strokeColor="#a0c814"
              size="small" strokeWidth={5} />
            <div className="progress-text">{t("presentationQuestionNumber", {
              current: this.getCurrentQuestionIndex(),
              total: this.getTotalAnswersAmount(),
            })}
            </div>
          </div>
        }
        <>
          {mobileView === MOBILE_VIEW.NAME && <MobileName onHandleAddParticipant={addParticipant} />}
          {mobileView === MOBILE_VIEW.WAITING && <MobileWaitingToStart />}
          {currentQuestion && mobileView === MOBILE_VIEW.QUESTION && !waitForQuestionResult && <MobileQuestionView
            question={currentQuestion}
            onAnswerQuestion={this.answerQuestion.bind(this)}
            totalParticipantResult={totalParticipantResult}
          />
          }
          {(mobileView === MOBILE_VIEW.QUESTION && waitForQuestionResult) && <div className="quizMobile">
            <div className="quizMobile__text">
              {t("mobileQuizPleaseWait")}
            </div>
          </div>}
          {currentQuestion && mobileView === MOBILE_VIEW.QUESTION_RESULTS && <MobileQuestionResult
            questionResult={currentQuestionResult}
            question={currentQuestion}
            totalParticipantResult={totalParticipantResult} />
          }
          {mobileView === MOBILE_VIEW.QUIZ_FINISHED &&
            <MobileFinalResult
              totalParticipantResult={totalParticipantResult}
              showStats={false} />
          }
          {mobileView === MOBILE_VIEW.FINAL_RESULTS &&
            <MobileFinalResult
              totalParticipantResult={totalParticipantResult}
              showStats={true} />
          }
        </>
      </>
    );
  }
}

