import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

function MobileWaitingToStart({t}) {
    
    return (
        <div className='mobLogin'>
            <div className='mobLogin__content'>
                <div className='mobLogin__input'>
                    {t("mobileQuizOneMoment")}<br />
                    <span style={{ fontSize: '0.65em' }}>{t("mobileQuizAboutToStart")}</span>
                </div>
            </div>
        </div>
    );
}

MobileWaitingToStart.propTypes = {t: PropTypes.func.isRequired};

export default withTranslation()(MobileWaitingToStart) ;
