import React from 'react';
import cn from 'classnames';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './MobileRoute.scss';
import logo from '../../resources/images/logo_gray.png';
import HamburgerMenu from '../../components/Header/HamburgerMenu';

const { Content } = Layout;

const MobileRoute = ({ component: Component, isHeaderGreen = false, participant, t, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout className='wrapper'>
	        <header className={cn({
            'c-mobileHead': true,
            'c-mobileHead_green': isHeaderGreen
          })}>
            <div className="header-row">
              <img src={logo} alt="Logo" className="logo"/>
              <div className="right-container">
                <div className="player">{participant?.name||''}</div>
	              <HamburgerMenu />
              </div>
            </div>
          </header>
          <Content className='c-mobileContent'>
            <Component {...props} />
          </Content>
        </Layout>
      )}
    />
  );
};

MobileRoute.propTypes = {
  component:PropTypes.func,
  isHeaderGreen:PropTypes.bool,
  participant:PropTypes.object,
};
export default withTranslation()(MobileRoute);
