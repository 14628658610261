import { PointCupWhite } from '../../../assets/svg';
import party from 'party-js';
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

export const Confetti = ({ points = null }) => {
  
  const [confettiEmitterRef, setConfettiEmitter] = useState(null);
  
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (confettiEmitterRef) {
        party.confetti(confettiEmitterRef, {
          count: party.variation.range(90, 100),
          size: party.variation.range(1, 1.5),
        });
      }
    }, 1000);
    
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [confettiEmitterRef]);
  
  return (
    <div className="point-cup-container">
      <div className="confetti-emitter" ref={(ref) => setConfettiEmitter(ref)}/>
      <PointCupWhite className="point-cup-image"/>
      {points !== null &&
      <div className="score">
        <div>{points}</div>
      </div>
      }
    </div>
  );
};

Confetti.propTypes = {
  points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
