import React, { memo } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import  PropTypes from "prop-types";
import { ROUTES } from '../../constants';

const ProtectedRoute = ({
  wrapper: Comp,
  ...rest
}) => {
  const user = useSelector(state => state.user);
  const loggingIn = useSelector(state => state.loggingIn);

  if (user) {
    if (user.isDeleted) {
      return <Redirect to={ROUTES.DELETED_USER} />;

    } else if (user.isActivated || user.isAdmin) {
      return <Comp {...rest} />;

    } else {
      return <Redirect to="/invitecode" />;
    }

  } else if (loggingIn) {
    return <React.Fragment />;

  } else {
    return <Redirect to="/login" />;
  }
};

ProtectedRoute.propTypes = {
  wrapper: PropTypes.func||PropTypes.object,
};

export default memo(ProtectedRoute);