import cn from 'classnames';
import PropTypes from "prop-types";
import React, { useState } from 'react';

import { withTranslation } from "react-i18next";
import {  QUESTION_TYPE } from '../../../constants';
import MobileQuestionMultiple from './MobileQuestionMultiple';
import MobileQuestionOpenQuestion from './MobileQuestionOpenQuestion';
import './MobileQuestionView.scss';

function MobileQuestionView({ question, onAnswerQuestion, answerIds, totalParticipantResult, t }) {


  return (
    <div className={cn({
      'quizMobile': true,
      'quizMobile_manyAnswers': [QUESTION_TYPE.QUESTIONAIRE, QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.INFO].includes(question.type),
      'quizMobile_pureAnswers': question.type === QUESTION_TYPE.TRUE_FALSE,
      'quizMobile_form': question.type === QUESTION_TYPE.OPEN_QUESTION
    })}>
      <div className='quizMobile__title'>{question.title}</div>
      {[QUESTION_TYPE.MULTIPLE, QUESTION_TYPE.TRUE_FALSE, QUESTION_TYPE.QUESTIONAIRE].includes(question.type) &&
        <MobileQuestionMultiple
          question={question}
          answerIds={answerIds}
          onAnswerQuestion={onAnswerQuestion}
        />
      }
      {question.type === QUESTION_TYPE.OPEN_QUESTION &&
        <MobileQuestionOpenQuestion
          onAnswerQuestion={onAnswerQuestion}
        />
      }
      {question.type === QUESTION_TYPE.INFO &&
        <div className='quizMobile__text'>
          {t("mobileQuestionViewPleaseLook")}
        </div>
      }
      {totalParticipantResult && <div className={cn({
        'quizMobile__settings': true,
        'quizMobile__settings_white': question.type === 'open_question',
      })}>
        {totalParticipantResult.place &&
          <div className='quizMobile__settingsItem'>
            {t("mobileQuestionViewPlace", {
              value: totalParticipantResult.place,
            })}
          </div>}

        {!!totalParticipantResult.totalPoints &&
          <div className='quizMobile__settingsItem'>
            {t("mobileQuestionViewPoints", {
              value: totalParticipantResult.totalPoints || 0,
            })}
          </div>}
      </div>}
    </div>
  );
}

MobileQuestionView.propTypes = {
  answerIds: PropTypes.array,
  onAnswerQuestion: PropTypes.func,
  question: PropTypes.object,
  totalParticipantResult: PropTypes.object
};

export default withTranslation()(MobileQuestionView);
